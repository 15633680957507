import { fetcher } from "../utils/fetcher";

class InvestmentService {
  queryKeys = {
    postInvestment: 'postInvestment',
    investmentStock: 'investmentStock',
    mutualFundsInvestment: 'mutualFundsInvestment',
    alternateInvestment: 'alternateInvestment',
    fixedIncomeInvestment: 'fixedIncomeInvestment',
    getMutualFunds: 'getMutualFunds',
    investmentLinkGoal: 'investmentLinkGoal',
    editAlternateInvestment: 'editAlternateInvestment',
    getInvestmentAggregates: 'getInvestmentAggregates',
    editInvestment: "editInvestment",
    editInsurance: "editInsurance",
    getInvestmentScore:'getInvestmentScore'
  };

  postInvestment = async (data) => {

    return fetcher({
      url: `/investments`,
      method: 'POST',
      data
    });

  }

  investmentStock = async (data) => {

    const { from, to, page } = data
    // console.log(from,to,'kkk');
    return fetcher({
      url: `investments/stocks?from=${from}&to=${to}&goals=true`,
      method: 'GET',
      data
    });
  }

  mutualFundsInvestment = async (data) => {
    return fetcher({
      url: `/investments/mutual-funds?goals=true`,
      method: 'GET',
    });
  }


  alternateInvestment = async (data) => {

    return fetcher({
      url: `investments/alternate-sources?goals=true`,
      method: 'GET',

    });
  }

  fixedIncomeInvestment = async (data) => {

    return fetcher({
      url: `investments/bonds?goals=true`,
      method: 'GET',
    });
  }

  getMutualFunds = async (data) => {

    return fetcher({
      url: `/mutual-funds`,
      method: 'GET',
    });
  }

  investmentLinkGoal = async (data) => {
    const { payload, goalId } = data

    return fetcher({
      url: `/planning/goals/${goalId}/link`,
      method: 'POST',
      data: payload
    });
  }

  editAlternateInvestment = async (data) => {
    const { payload, investId } = data

    return fetcher({
      url: `/investments/${investId}`,
      method: 'PUT',
      data: payload
    });
  }

  editInvestment = async (data) => {
    const { payload, investmentId } = data

    return fetcher({
      url: `/investments/${investmentId}`,
      method: 'PUT',
      data: payload
    });
  }




  getInvestmentScore = async (data) => {

    return fetcher({
      url: `/investments/score`,
      method: 'GET',
    });
  }

  getInvestmentAggregates = async (data) => {

    return fetcher({
      url: `/investments/aggregates`,
      method: 'GET',
    });
  }



}

const investmentServiceInstance = new InvestmentService();
export default investmentServiceInstance;
