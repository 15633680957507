import React from "react";
import {FinIcon} from './Common';

const PeronalInformation = ({...props}) => {
    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Peronal Information</h3>
            </div>
            <div className="col-auto">
                <button type="button" className="btn btn-normal btn-light" onClick={()=> props.passingMethod({account_settings: true})}>
                    <FinIcon name="arrowleft2" className="ts-1p4"/> Back
                </button>
            </div>
            <div className="col-12 mt-1">
                <p className="text-b200 small">
                    Edit your and your spouse's basic details.
                </p>
            </div>
             {/* --- */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="d-flex align-items-center gap-3 mb-3">
                        <FinIcon name="users" className="ts-1p4 text-cyan"/>
                        <span className="inter-600 fs-18px">How would you like to plan?</span>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Individual/Family</label>
                            <select id="individualSelect" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option>As an Individual</option>
                                <option>As a Family</option>
                            </select>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-3 mb-3 mt-4 mt-xl-5">
                        <FinIcon name="face" className="ts-1p4 text-primary"/>
                        <span className="inter-600 fs-18px">About You</span>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Name</label>
                            <input type="text" id="individualSelect" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="You"/>
                        </div>
                        <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Birth Month</label>
                            <select id="individualSelect" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option>January</option>
                                <option>February</option>
                                <option>March</option>
                                <option>April</option>
                                <option>May</option>
                                <option>June</option>
                                <option>July</option>
                                <option>August</option>
                                <option>September</option>
                                <option>October</option>
                                <option>November</option>
                                <option>December</option>
                            </select>
                        </div>
                        <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Birth Year</label>
                            <select id="individualSelect" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option>1980</option>
                                <option>1985</option>
                                <option>1990</option>
                                <option>1995</option>
                                <option>2000</option>
                                <option>2005</option>
                                <option>2010</option>
                            </select>
                        </div>
                    </div>
                    {/* Your Partner */}
                    <div className="d-flex align-items-center gap-3 mb-3 mt-4 mt-xl-5">
                        <FinIcon name="partner" className="ts-1p3 text-purple"/>
                        <span className="inter-600 fs-18px">About Your Partner</span>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Name</label>
                            <input type="text" id="individualSelect" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Your Spouse"/>
                        </div>
                        <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Birth Month</label>
                            <select id="individualSelect" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option>January</option>
                                <option>February</option>
                                <option>March</option>
                                <option>April</option>
                                <option>May</option>
                                <option>June</option>
                                <option>July</option>
                                <option>August</option>
                                <option>September</option>
                                <option>October</option>
                                <option>November</option>
                                <option>December</option>
                            </select>
                        </div>
                        <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Birth Year</label>
                            <select id="individualSelect" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option>1980</option>
                                <option>1985</option>
                                <option>1990</option>
                                <option>1995</option>
                                <option>2000</option>
                                <option>2005</option>
                                <option>2010</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <button type="button" className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
                    <FinIcon name="save" className="ts-1p5 me-3"/>
                    Save Changes
                </button>
            </div>
        </div>
    )
}
export default PeronalInformation;