import { fetcher } from "../utils/fetcher";

class InsuranceService {
  queryKeys = {
    getInsurance: 'getInsurance',
    getInsuranceScore: 'getInsuranceScore',
    editInsurance: "editInsurance",
    addInsurance : 'addInsurance'
  };


  
  editInsurance = async (data) => {
    const { payload, insuranceId } = data;
    console.log("data",data)
    console.log("payload",payload)
    console.log(insuranceId)
    return fetcher({
      url: `/insurance/${insuranceId}`, 
      method: 'PUT', 
      data: payload 
    });
  }

  getInsuranceScore = async () => {

    return fetcher({
      url: `/insurance/score`,
      method: 'GET',
     });
  }
  getInsurance = async (data) => {

    return fetcher({
      url: `/insurance?nextPageToken=${data}`,
      method: 'GET',
      data,
    });
  }

  addInsurance = async (data) => {

    return fetcher({
      url: `/insurance`,
      method: 'POST',
      data
    });
  }
}

const insuranceServiceInstance = new InsuranceService();
export default insuranceServiceInstance;
