import React from "react";
import { Link } from "react-router-dom";
import { FinIcon, AddBankingBack } from '../components/Common';
import useAddCreditCard from "../hooks/bank-services/add-credit-card";
import { useFormik } from "formik";
import { addCreditCardSchema } from "../utils/validationSchema";
import { useNavigate } from "react-router-dom";
const CreditCardAdd = ({ ...props }) => {
     
    const navigate = useNavigate()
    //api call
    const useAddCreditCardMutation = useAddCreditCard()

    // Formik Inputs
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { issuerName: "", cardNumber: '', cardType: '', creditLimit: '', usedCreditLimit: '', interestRate: 0, dueDate: '', minimumDue: '', lastAmountDue: '' },
        validationSchema: addCreditCardSchema,
        onSubmit: values => {

            let payload = {
                issuerName: formik.values.issuerName,
                cardNumber: formik.values.cardNumber,
                cardType: formik?.values.cardType.toUpperCase(),
                creditLimit: Number(formik?.values.creditLimit),
                usedCreditLimit: Number(formik?.values?.usedCreditLimit),
                interestRate: Number(formik?.values?.interestRate),
                dueDate: formik?.values?.dueDate,
                minimumDue: Number(formik?.values?.minimumDue),
                lastAmountDue: Number(formik?.values?.lastAmountDue),


            }
            // console.log(payload);
            useAddCreditCardMutation.mutate(payload,{
                onSuccess:  (data) => {
                    formik.resetForm()
                    navigate("/banking")
                  },
            })
        }
    })
    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Add Credit Card</h3>
                <p className="text-b200 small">
                    Credit Card add basic details.
                </p>
            </div>
            <div className="col-auto">
                <AddBankingBack value="CREDIT_CARD" />
            </div>

            {/* --- */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="ccBankName" className="small inter-500 text-b200 mb-2">Institution</label>
                            <input type="text"
                                value={formik?.values?.issuerName}
                                onBlur={formik.handleBlur('issuerName')}
                                onInput={formik.handleChange('issuerName')}
                                id="ccBankName" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Bank Name" />
                            {(formik.errors.issuerName && formik.touched.issuerName) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.issuerName}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="ccBankName" className="small inter-500 text-b200 mb-2">Credit Card Number</label>
                            <input type="text"
                                value={formik?.values?.cardNumber}
                                onBlur={formik.handleBlur('cardNumber')}
                                onInput={formik.handleChange('cardNumber')}
                                id="ccNumber" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Credit Card Numbe" />
                            {(formik.errors.cardNumber && formik.touched.cardNumber) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.cardNumber}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="ccName" className="small inter-500 text-b200 mb-2">Type of Card</label>
                            <select id="ccName" onChange={(e) => { formik.setFieldValue('cardType', e.target.value) }} className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option>Select Card Type</option>
                                <option>Rupay</option>
                                <option>Visa</option>
                                <option>Mater</option>
                            </select>
                            {(formik.errors.cardType && formik.touched.cardType) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.cardType}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="creditLimit" className="small inter-500 text-b200 mb-2">Credit Limit</label>
                            <input type="text"
                                value={formik?.values?.creditLimit}
                                onBlur={formik.handleBlur('creditLimit')}
                                onInput={formik.handleChange('creditLimit')}
                                id="creditLimit" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.creditLimit && formik.touched.creditLimit) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.creditLimit}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="usedcLimit" className="small inter-500 text-b200 mb-2">Used Credit Limit</label>
                            <input type="text"
                                value={formik?.values?.usedCreditLimit}
                                onBlur={formik.handleBlur('usedCreditLimit')}
                                onInput={formik.handleChange('usedCreditLimit')}
                                id="usedcLimit" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.usedCreditLimit && formik.touched.usedCreditLimit) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.usedCreditLimit}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="usedcLimit" className="small inter-500 text-b200 mb-2">Interest Rate <span>({formik?.values?.interestRate}%)</span></label>
                            <input type="text"
                                value={formik?.values?.interestRate}
                                onBlur={formik.handleBlur('interestRate')}
                                onInput={formik.handleChange('interestRate')}
                                id="usedcLimit" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.interestRate && formik.touched.interestRate) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.interestRate}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="dueDate" className="small inter-500 text-b200 mb-2">Due Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00"
                                min="0"
                                id="dueDate"
                                name="dueDate"
                                onInput={formik.handleChange}
                                value={formik.values.dueDate}
                            />
                            {(formik.errors.dueDate && formik.touched.dueDate) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.dueDate}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="ccminimumDue" className="small inter-500 text-b200 mb-2">Minimum Due</label>
                            <input type="text"
                                value={formik?.values?.minimumDue}
                                onBlur={formik.handleBlur('minimumDue')}
                                onInput={formik.handleChange('minimumDue')}
                                id="ccminimumDue" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.minimumDue && formik.touched.minimumDue) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.minimumDue}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="cclastAmountDue" className="small inter-500 text-b200 mb-2">Last Amount Due</label>
                            <input type="text"
                                value={formik?.values?.lastAmountDue}
                                onBlur={formik.handleBlur('lastAmountDue')}
                                onInput={formik.handleChange('lastAmountDue')}
                                id="cclastAmountDue" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.lastAmountDue && formik.touched.lastAmountDue) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.lastAmountDue}
                            </p> : null}
                        </div>
                    </div>
                </div>

                <button type="button" disabled={useAddCreditCardMutation?.isPending} onClick={formik.handleSubmit} className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
                    <FinIcon name="save" className="ts-1p5 me-3" />
                    Save Credit Card  {useAddCreditCardMutation?.isPending && <span className="spinner-border spinner-border-sm mx-lg-1" role="status" aria-hidden="true"></span>}
                </button>
            </div>
        </div>
    )
}
export default CreditCardAdd;