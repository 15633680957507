import React from "react";
import { FinIcon } from '../components/Common';
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const MutualFundsEdit = () => {
    const location = useLocation();
    const item = location.state?.item;
    
    const navigate =useNavigate()

    const handleBackToInveshment=()=>{
        navigate('/investment')
        setTimeout(() => {
            window.scrollTo({
              top: document.documentElement.scrollHeight,
              behavior: 'smooth',
            });
          }, 100);
    }

    // Validation Schema
    const editMutualFundsSchema = yup.object().shape({
        nav: yup.number().typeError('Nav must be a number').required("Nav is required"),
        mode: yup.string().required("Mode is required"),
        buyingPrice: yup.number().typeError('Buying Price must be a number').required("Buying Price is required"),
        purchaseDate: yup.date().required("Purchase Date is required"),
        fundName: yup.string().required("Fund Name is required"),
        fundCategory: yup.string().required("Fund Category is required"),
    });

    // Formik Inputs
    const formik = useFormik({
        enableReinitialize: !!item,
        initialValues: {
            nav: item?.netAssetValue || '',
            mode: item?.mode || '',
            investmentType: item?.investmentType || 'MUTUAL_FUNDS',
            buyingPrice: item?.buyingPrice || '',
            purchaseDate: item?.purchaseDate || '',
            fundCategory: item?.fund.category || '',
            fundName: item?.fund.name || '', // Fund Name (disabled input)
            quantity: item?.quantity || '',
            linkedGoals: item?.linkedGoals || [], // Updated UI for linked goals
        },
        validationSchema: editMutualFundsSchema,
        onSubmit: values => {
            const payload = {
                nav: Number(values.nav),
                mode: values.mode.toUpperCase(),
                investmentType: values.investmentType,
                buyingPrice: Number(values.buyingPrice),
                purchaseDate: values.purchaseDate,
                fundCategory: values.fundCategory,
                fundName: values.fundName,
                quantity: Number(values.quantity),
                linkedGoals: values.linkedGoals,
            };

            // API call (uncomment when using postInvestmentMutation)
            // postInvestmentMutation.mutate(payload, {
            //     onSuccess: () => {
            //         formik.resetForm();
            //     },
            //     onError: (error) => {
            //         console.error("Error during submission:", error);
            //     }
            // });
        }
    });

    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Update Mutual Fund</h3>
                <p className="text-b200 small">Update Mutual Fund basic details.</p>
            </div>
            <div className="col-auto">
                <button onClick={handleBackToInveshment} className="btn btn-normal btn-light">
                    <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                </button>
            </div>

            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        {/* NAV */}
                        <div className="col">
                            <label htmlFor="nav" className="small inter-500 text-b200 mb-2">Nav</label>
                            <input type="number" id="nav"
                                value={formik.values.nav}
                                onBlur={formik.handleBlur('nav')}
                                onChange={formik.handleChange('nav')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00" />
                            {formik.errors.nav && formik.touched.nav && (
                                <p className="fs-14px text-danger inter-500 my-1">{formik.errors.nav}</p>
                            )}
                        </div>

                        {/* Mode */}
                        <div className="col">
                            <label htmlFor="mode" className="small inter-500 text-b200 mb-2">Mode</label>
                            <select id="mode"
                                value={formik.values.mode}
                                onBlur={formik.handleBlur('mode')}
                                onChange={formik.handleChange('mode')}
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option value="">--- Select ---</option>
                                <option value="SIP">SIP</option>
                                <option value="LUMSUP">LUMSUP</option>
                                <option value="OTHER">OTHER</option>
                            </select>
                            {formik.errors.mode && formik.touched.mode && (
                                <p className="fs-14px text-danger inter-500 my-1">{formik.errors.mode}</p>
                            )}
                        </div>

                        {/* Fund Category */}
                        <div className="col">
                            <label htmlFor="fundCategory" className="small inter-500 text-b200 mb-2">Fund Category</label>
                            <input type="text" id="fundCategory"
                                value={formik.values.fundCategory}
                                onBlur={formik.handleBlur('fundCategory')}
                                onChange={formik.handleChange('fundCategory')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Fund Category" />
                            {formik.errors.fundCategory && formik.touched.fundCategory && (
                                <p className="fs-14px text-danger inter-500 my-1">{formik.errors.fundCategory}</p>
                            )}
                        </div>

                        {/* Fund Name (Non-editable) */}
                        <div className="col">
                            <label htmlFor="fundName" className="small inter-500 text-b200 mb-2">Fund Name</label>
                            <input type="text" id="fundName"
                                value={formik.values.fundName}
                                disabled
                                className="form-control form-control-fin inter-500 bg-light px-3 shadow-sm" placeholder="Fund Name" />
                        </div>



                        {/* Quantity */}
                        <div className="col">
                            <label htmlFor="quantity" className="small inter-500 text-b200 mb-2">Quantity</label>
                            <input type="number" id="quantity"
                                value={formik.values.quantity}
                                onBlur={formik.handleBlur('quantity')}
                                onChange={formik.handleChange('quantity')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00" />
                            {formik.errors.quantity && formik.touched.quantity && (
                                <p className="fs-14px text-danger inter-500 my-1">{formik.errors.quantity}</p>
                            )}
                        </div>

                        {/* Buying Price */}
                        <div className="col">
                            <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Buying Price</label>
                            <input type="number" id="buyingPrice"
                                value={formik.values.buyingPrice}
                                onBlur={formik.handleBlur('buyingPrice')}
                                onChange={formik.handleChange('buyingPrice')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00" />
                            {formik.errors.buyingPrice && formik.touched.buyingPrice && (
                                <p className="fs-14px text-danger inter-500 my-1">{formik.errors.buyingPrice}</p>
                            )}
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="text-start">
                        <button className="btn btn-primary mt-4" type="button" onClick={() => formik.handleSubmit()}>
                            Update Mutual Fund
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MutualFundsEdit;
