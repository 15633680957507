import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { fetcher } from "../utils/fetcher";
import usePostinvestment from "../hooks/investment/post-investment";
import { FinIcon, AddInvestmentBack } from '../components/Common';
import Select2 from '../components/Select2'; // Adjust the path as needed
import { useNavigate } from "react-router-dom";
const stockSchema = Yup.object().shape({
    companyId: Yup.string().required('Please enter Company Id.'),
    quantity: Yup.number().required('Please enter integer value.'),
    buyingPrice: Yup.number().required('Please enter Buying Price.'),
   
});

const StockAdd = (props) => {
    const usePostInvestmentMutation = usePostinvestment();
     const navigate= useNavigate()
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyId: '',
            quantity: '',
            investmentType: 'STOCKS',
            buyingPrice: '',
            purchaseDate: new Date().toISOString().split('T')[0],
        },
        validationSchema: stockSchema,
        onSubmit: values => {
            usePostInvestmentMutation.mutate(values, {
                onSuccess: (data) => {
                    formik.resetForm();
                    navigate('/investment')      
                },
            });
        },
    });

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await fetcher({
                    url: `/companies/nse-listed`,
                    method: 'GET',
                });
                setData(response.result || []); // Ensure response.result is set to an array
            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="row row-inner">
                <div className="col align-self-center">
                    <h3 className="inter-700">Add New Stock</h3>
                    <p className="text-b200 small">
                        Add New Stock basic details.
                    </p>
                </div>
                <div className="col-auto">
                    <AddInvestmentBack value="STOCKS" />
                </div>

                <div className="col-12 mt-1 mt-xl-2 mb-4">
                    <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">
                            <div className="col">
                                <label htmlFor="companyId" className="small inter-500 text-b200 mb-2">Company Id</label>
                                <div className="form-control-select bg-white shadow-sm">
                                    <Select2
                                        data={data}
                                        onChange={value => formik.setFieldValue('companyId', value)}
                                        defaultValue={formik.values.companyId}
                                    />
                                </div>
                                {(formik.errors.companyId && formik.touched.companyId) ? (
                                    <p className="fs-14px text-danger inter-500 my-1">
                                        {formik.errors.companyId}
                                    </p>
                                ) : null}
                            </div>
                            <div className="col">
                                <label htmlFor="quantity" className="small inter-500 text-b200 mb-2">Quantity</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="00"
                                    min="0"
                                    id="quantity"
                                    name="quantity"
                                    onInput={formik.handleChange}
                                    value={formik.values.quantity}
                                />
                                {(formik.errors.quantity && formik.touched.quantity) ? (
                                    <p className="fs-14px text-danger inter-500 my-1">
                                        {formik.errors.quantity}
                                    </p>
                                ) : null}
                            </div>
                            <div className="col-12 w-100 mt-0"></div>
                            <div className="col">
                                <label htmlFor="investmentType" className="small inter-500 text-b200 mb-2">Investment Type<strong className="text-danger">*</strong></label>
                                <input
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    id="investmentType"
                                    name="investmentType"
                                    value={formik.values.investmentType}
                                    readOnly
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Buying Price<strong className="text-danger">*</strong></label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="00.00"
                                    min="0"
                                    id="buyingPrice"
                                    name="buyingPrice"
                                    onInput={formik.handleChange}
                                    value={formik.values.buyingPrice}
                                />
                                {(formik.errors.buyingPrice && formik.touched.buyingPrice) ? (
                                    <p className="fs-14px text-danger inter-500 my-1">
                                        {formik.errors.buyingPrice}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    
                    <button
                        type="button"
                        className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4"
                        disabled={usePostInvestmentMutation?.isPending}
                        onClick={formik.handleSubmit}
                    >
                        <FinIcon name="save" className="ts-1p5 me-3" />
                        Save New Stock
                        {usePostInvestmentMutation?.isPending && (
                            <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};

export default StockAdd;
