import React from "react";
import { Link } from "react-router-dom";
import { FinIcon} from '../components/Common';
import useUpdateCreditCard from "../hooks/bank-services/put-credit-cards";
import { useFormik } from "formik";
import { CreditCardSchemaEdit } from "../utils/validationSchema";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const CreditCardEdit = () => {
   const navigate = useNavigate()
   const location = useLocation()
   const item = location.state?.item;
//    console.log(item)
    //api call
    const useUpdateCreditCardMutation = useUpdateCreditCard()
    
    
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    // Formik Inputs
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { 
            id : item?.id,
            issuerName:item?.issueName ||"", 
            cardNumber: item?.cardNumber ||'', 
          cardType: item?.cardType||'', 
          creditLimit:item?.creditLimit ||'', 
          usedCreditLimit: item?.usedCreditLimit||'',
           interestRate: item?.interestRate || 0, 
           dueDate:formatDate(item?.dueDate) ||'',
           minimumDue: item?.minimumDue || '',
           lastAmountDue: item?.lastAmountDue ||'' },
        validationSchema: CreditCardSchemaEdit,
        onSubmit: values => {
            
          

            const payload = {
                issuerName: values.issuerName,
                cardNumber: values.cardNumber,
                cardType: values.cardType.toUpperCase(),
                creditLimit: Number(values.creditLimit),
                usedCreditLimit: Number(values?.usedCreditLimit),
                interestRate: Number(values?.interestRate),
                dueDate: values?.dueDate,
                minimumDue: Number(values?.minimumDue),
                lastAmountDue: Number(values?.lastAmountDue),
            }
               
           const saveDate = {
            payload:payload,id:values.id
           }


           useUpdateCreditCardMutation.mutate(saveDate, {
            onSuccess: (data) => {
                console.log('updated successfully', data);
                formik.resetForm();
                navigate("/banking")
            },
            onError: (error) => {
                console.error("error",error);
                },
        }
        )
        }
    })
    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Update Credit Card</h3>
                <p className="text-b200 small">
                    Credit Card Update basic details.
                </p>
            </div>
            <div className="col-auto">
            <Link to={'/banking'} className="btn btn-normal btn-light">
                <FinIcon name="arrowleft2" className="ts-1p4"/> Back
            </Link>
            </div>

            {/* --- */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="ccBankName" className="small inter-500 text-b200 mb-2">Institution</label>
                            <input type="text"
                                value={formik?.values?.issuerName}
                                onBlur={formik.handleBlur('issuerName')}
                                onInput={formik.handleChange('issuerName')}
                                id="ccBankName" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Bank Name" />
                            {(formik.errors.issuerName && formik.touched.issuerName) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.issuerName}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="ccBankName" className="small inter-500 text-b200 mb-2">Credit Card Number</label>
                            <input type="text"
                                value={formik?.values?.cardNumber}
                                onBlur={formik.handleBlur('cardNumber')}
                                onInput={formik.handleChange('cardNumber')}
                                id="ccNumber" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Credit Card Numbe" />
                            {(formik.errors.cardNumber && formik.touched.cardNumber) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.cardNumber}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="ccName" className="small inter-500 text-b200 mb-2">Type of Card</label>
                            <select id="ccName"  value={formik.values.cardType}   onChange={(e) => { formik.setFieldValue('cardType', e.target.value) }} className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option>Select Card Type</option>
                                <option value={"RUPAY"} >Rupay</option>
                                <option value={"VISA"}>Visa</option>
                                <option value={"MATER"} >Mater</option>
                            </select>
                            {(formik.errors.cardType && formik.touched.cardType) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.cardType}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="creditLimit" className="small inter-500 text-b200 mb-2">Credit Limit</label>
                            <input type="text"
                                value={formik?.values?.creditLimit}
                                onBlur={formik.handleBlur('creditLimit')}
                                onInput={formik.handleChange('creditLimit')}
                                id="creditLimit" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.creditLimit && formik.touched.creditLimit) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.creditLimit}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="usedcLimit" className="small inter-500 text-b200 mb-2">Used Credit Limit</label>
                            <input type="text"
                                value={formik?.values?.usedCreditLimit}
                                onBlur={formik.handleBlur('usedCreditLimit')}
                                onInput={formik.handleChange('usedCreditLimit')}
                                id="usedcLimit" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.usedCreditLimit && formik.touched.usedCreditLimit) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.usedCreditLimit}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="usedcLimit" className="small inter-500 text-b200 mb-2">Interest Rate <span>({formik?.values?.interestRate}%)</span></label>
                            <input type="text"
                                value={formik?.values?.interestRate}
                                onBlur={formik.handleBlur('interestRate')}
                                onInput={formik.handleChange('interestRate')}
                                id="usedcLimit" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.interestRate && formik.touched.interestRate) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.interestRate}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="dueDate" className="small inter-500 text-b200 mb-2">Due Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00"
                                min="0"
                                id="dueDate"
                                name="dueDate"
                                onInput={formik.handleChange}
                                value={formik.values.dueDate}
                            />
                            {(formik.errors.dueDate && formik.touched.dueDate) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.dueDate}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="ccminimumDue" className="small inter-500 text-b200 mb-2">Minimum Due</label>
                            <input type="text"
                                value={formik?.values?.minimumDue}
                                onBlur={formik.handleBlur('minimumDue')}
                                onInput={formik.handleChange('minimumDue')}
                                id="ccminimumDue" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.minimumDue && formik.touched.minimumDue) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.minimumDue}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="cclastAmountDue" className="small inter-500 text-b200 mb-2">Last Amount Due</label>
                            <input type="text"
                                value={formik?.values?.lastAmountDue}
                                onBlur={formik.handleBlur('lastAmountDue')}
                                onInput={formik.handleChange('lastAmountDue')}
                                id="cclastAmountDue" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.lastAmountDue && formik.touched.lastAmountDue) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.lastAmountDue}
                            </p> : null}
                        </div>
                    </div>
                </div>

                <button type="button"
                //  disabled={useAddCreditCardMutation?.isPending} 
                onClick={formik.handleSubmit} className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
                    <FinIcon name="save" className="ts-1p5 me-3" />
                    Update Credit Card 
                     {/* {useAddCreditCardMutation?.isPending && <span className="spinner-border spinner-border-sm mx-lg-1" role="status" aria-hidden="true"></span>} */}
                </button>
            </div>
        </div>
    )
}
export default CreditCardEdit;