import React from "react";
import { Link } from "react-router-dom";
import { FinIcon } from '../components/Common';
import useAddInsurance from "../hooks/insurance/add-insurance";
import { useFormik } from "formik";
import { addInsuranceSchema } from "../utils/validationSchema";
import { useNavigate } from "react-router-dom";
const PeronalInformation = ({ ...props }) => {
    const navigate = useNavigate()
    //api call
    const useAddInsuranceMutation = useAddInsurance();


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            policyName: "",
            sumAssured: "",
            premiumAmount: "",
            premiumFrequency: "",
            premiumDate: "",
            maturityDate: "",
            ownership: "",
            insuranceFiType: "",
            riderType: "",

            // remainingTenure: "0",
            // insights: ""
        },
        validationSchema: addInsuranceSchema,
        onSubmit: values => {
            // usePostDebtMutation.mutate(values);
            const payload = {
                policyName: formik?.values?.policyName,
                sumAssured: Number(formik?.values?.sumAssured),
                premiumAmount: Number(formik?.values?.premiumAmount),
                premiumFrequency: formik?.values?.premiumFrequency,
                premiumDate: formik?.values?.premiumDate,
                maturityDate: formik?.values?.maturityDate,
                ownership: formik?.values?.ownership,
                insuranceFiType: formik?.values?.insuranceFiType === 'Yes' ? true : false,
                riderType: formik?.values?.riderType,
            }

            useAddInsuranceMutation?.mutate(payload,{
                onSuccess:  (data) => {
                    formik.resetForm()
                    navigate('/insurance')
                  },
            })
        },
    });
    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Add New Insurance</h3>
            </div>
            <div className="col-auto">
                <Link to={'/insurance'} type="button" className="btn btn-normal btn-light">
                    <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                </Link>
            </div>
            <div className="col-12 mt-1">
                <p className="text-b200 small">
                    New insurance add basic details.
                </p>
            </div>
            {/* --- */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="policyName" className="small inter-500 text-b200 mb-2">Policy Name</label>
                            <input type="text" id="policyName"
                                onInput={formik.handleChange}
                                value={formik.values.policyName}
                                onBlur={formik.handleBlur('policyName')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Policy Name" />
                            {(formik.errors.policyName && formik.touched.policyName) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.policyName}
                            </p> : null}
                        </div>
                        {/* <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Type</label>
                            <select id="individualSelect" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option>Lefe Insurance</option>
                                <option>Bike Insurance</option>
                                <option>Car Insurance</option>
                            </select>
                        </div> */}
                        <div className="col">
                            <label htmlFor="sumAssured" className="small inter-500 text-b200 mb-2">Sum Assured</label>
                            <input type="text" id="sumAssured"
                                onInput={formik.handleChange}
                                value={formik.values.sumAssured}
                                onBlur={formik.handleBlur('sumAssured')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.sumAssured && formik.touched.sumAssured) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.sumAssured}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="premiumAmount" className="small inter-500 text-b200 mb-2">Premium Amount</label>
                            <input type="text" id="premiumAmount"
                                onInput={formik.handleChange}
                                value={formik.values.premiumAmount}
                                onBlur={formik.handleBlur('premiumAmount')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.premiumAmount && formik.touched.premiumAmount) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.premiumAmount}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="premiumFrequency" className="small inter-500 text-b200 mb-2">Premium Frequency</label>
                            <select id="premiumFrequency"
                                onInput={formik.handleChange}
                                value={formik.values.premiumFrequency}
                                onBlur={formik.handleBlur('premiumFrequency')}
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option value="" label="Select frequency" />
                                <option value="Monthly" label="Monthly" />
                                <option value="Quarterly" label="Quarterly" />
                                <option value="Semi-Annually" label="Semi-Annually" />
                                <option value="Annually" label="Annually" />
                            </select>
                            {(formik.errors.premiumFrequency && formik.touched.premiumFrequency) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.premiumFrequency}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="premiumDate" className="small inter-500 text-b200 mb-2">Premium Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00"
                                min="0"
                                id="premiumDate"
                                name="premiumDate"
                                onInput={formik.handleChange}
                                value={formik.values.premiumDate}
                                onBlur={formik.handleBlur('premiumDate')}
                            />
                            {(formik.errors.premiumDate && formik.touched.premiumDate) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.premiumDate}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="maturityDate" className="small inter-500 text-b200 mb-2">Maturity Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00"
                                min="0"
                                id="maturityDate"
                                name="maturityDate"
                                onInput={formik.handleChange}
                                value={formik.values.maturityDate}
                                onBlur={formik.handleBlur('maturityDate')}
                            />
                            {(formik.errors.maturityDate && formik.touched.maturityDate) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.maturityDate}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="ownership" className="small inter-500 text-b200 mb-2">Ownership / Nominee</label>
                            <input type="text" id="ownership"
                                onInput={formik.handleChange}
                                value={formik.values.ownership}
                                onBlur={formik.handleBlur('ownership')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Ownership" />
                            {(formik.errors.ownership && formik.touched.ownership) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.ownership}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="insuranceFiType" className="small inter-500 text-b200 mb-2">Investment Link</label>
                            <select id="insuranceFiType"
                                onInput={formik.handleChange}
                                value={formik.values.insuranceFiType}
                                onBlur={formik.handleBlur('insuranceFiType')}
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option value="" label="Select Investment Link" />
                                <option  value="YES">Yes</option>
                                <option value="NO">No</option>
                            </select>
                            {(formik.errors.insuranceFiType && formik.touched.insuranceFiType) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.insuranceFiType}
                            </p> : null}
                        </div>
                        {/* <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Nominee</label>
                            <input type="text" id="individualSelect" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Nominee" />
                        </div>
                        <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Investment Linked</label>
                            <select id="individualSelect" className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div> */}
                        <div className="col">
                            <label htmlFor="riderType" className="small inter-500 text-b200 mb-2">Policy Rider</label>
                            <input type="text" id="riderType"
                                onInput={formik.handleChange}
                                value={formik.values.riderType}
                                onBlur={formik.handleBlur('riderType')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Policy Rider" />
                            {(formik.errors.riderType && formik.touched.riderType) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.riderType}
                            </p> : null}
                        </div>
                        {/* <div className="col">
                            <label htmlFor="individualSelect" className="small inter-500 text-b200 mb-2">Insights</label>
                            <input type="text" id="individualSelect" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" defaultValue="200" placeholder="₹10000" />
                        </div> */}
                    </div>
                </div>

                <button type="button" className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4" disabled={useAddInsuranceMutation?.isPending} onClick={formik.handleSubmit}>
                    <FinIcon name="save" className="ts-1p5 me-3" />
                    Save New Insurance   {useAddInsuranceMutation?.isPending && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                </button>
            </div>
        </div>
    )
}
export default PeronalInformation;