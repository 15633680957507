import React from "react";
import { useFormik } from "formik";
import { useLocation, Link } from "react-router-dom";
import { FinIcon } from '../components/Common';
import { FixedDepositSchemaEdit } from "../utils/validationSchema";
import useUpdateFixedDepositBank from "../hooks/bank-services/put-fixed-deposits";
import { useNavigate } from "react-router-dom";
const FixedDepositEdit = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const item = location.state?.item
  const useUpdateFixedDepositBankMutation = useUpdateFixedDepositBank()


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: item?.id,
      bankname: item?.institution || "",
      amount: item?.amount || '',
      rateofinterest: item?.interestRate || 0,
      tenure: item?.tenureInMonths || '',
      category: item?.category || '',
      insights: item?.insights || ''
    },
    validationSchema: FixedDepositSchemaEdit,
    onSubmit: values => {


      const payload = {
        institution: values.bankname,
        amount: Number(values.amount),
        interestRate: Number(values.rateofinterest),
        tenureInMonths: values.tenure,
        category: values?.category.toLowerCase(),
        insights: values?.insights
      }
      const saveDate = {
        payload: payload, id: values.id
      }

      useUpdateFixedDepositBankMutation.mutate(saveDate, {
        onSuccess: () => {
          formik.resetForm()
          navigate('/banking')
        },
      })
    }
  })
  return (
    <div className="row row-inner">
      <div className="col align-self-center">
        <h3 className="inter-700">Update Fixed Deposit</h3>
        <p className="text-b200 small">
          Fixed Deposit Update basic details.
        </p>
      </div>
      <div className="col-auto">
        <Link to={'/banking'} className="btn btn-normal btn-light">
          <FinIcon name="arrowleft2" className="ts-1p4" /> Back
        </Link>
      </div>


      <div className="col-12 mt-1 mt-xl-2 mb-4">
        <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
            <div className="col">
              <label htmlFor="fdBankName" className="small inter-500 text-b200 mb-2">Bank Name</label>
              <input type="text" id="fdBankName"
                value={formik?.values?.bankname}
                onBlur={formik.handleBlur('bankname')}
                onInput={formik.handleChange('bankname')} className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Bank Name" />
              {(formik.errors.bankname && formik.touched.bankname) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                {formik.errors.bankname}
              </p> : null}
            </div>
            <div className="col">
              <label htmlFor="fdAmount" className="small inter-500 text-b200 mb-2">Amount</label>
              <input type="text" id="fdAmount" value={formik.values?.amount}
                onBlur={formik.handleBlur('amount')}
                onInput={formik.handleChange('amount')} className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
              {(formik.errors.amount && formik.touched.amount) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                {formik.errors.amount}
              </p> : null}
            </div>
            <div className="col">
              <label htmlFor="fdAmount" className="small inter-500 text-b200 mb-2">Interest Rate <span>({formik?.values?.rateofinterest}%)</span></label>
              <input type="text" id="fdAmount" value={formik.values?.rateofinterest}
                onBlur={formik.handleBlur('rateofinterest')}
                onInput={formik.handleChange('rateofinterest')} className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="" />
              {(formik.errors.rateofinterest && formik.touched.rateofinterest) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                {formik.errors.rateofinterest}
              </p> : null}
            </div>
            <div className="col">
              <label htmlFor="fdTenure" className="small d-block inter-500 text-b200 mb-2">Tenure <span>({formik?.values?.tenure} Months)</span></label>
              <input type="range" id="fdTenure" value={formik.values?.tenure}
                onBlur={formik.handleBlur('tenure')}
                onInput={formik.handleChange('tenure')} className="form-range form-control-fin inter-500 bg-white px-3 shadow-sm" name="tenure" min="0" max="800" step="6" />
              {(formik.errors.tenure && formik.touched.tenure) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                {formik.errors.tenure}
              </p> : null}
            </div>
            <div className="col">
              <label htmlFor="fdCategory" className="small inter-500 text-b200 mb-2">Category</label>
              <select id="fdCategory" value={formik.values.category} onChange={(e) => { formik.setFieldValue('category', e.target.value) }} className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm">
                <option>Select Category</option>
                <option value={"cumulative"}>Cumulative</option>
                <option value={"non-cumulative"} >Non-Cumulative</option>
              </select>
              {(formik.errors.category && formik.touched.category) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                {formik.errors.category}
              </p> : null}
            </div>
            <div className="col">
              <label htmlFor="fdInsights" className="small inter-500 text-b200 mb-2">Insights</label>
              <input type="text" id="fdInsights" value={formik.values?.insights}
                onBlur={formik.handleBlur('insights')}
                onInput={formik.handleChange('insights')} className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Insight" />
              {(formik.errors.insights && formik.touched.insights) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                {formik.errors.insights}
              </p> : null}
            </div>
          </div>
        </div>

        <button type="button" onClick={formik.handleSubmit}
          className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
          <FinIcon name="save" className="ts-1p5 me-3" />
          Update Fixed Deposit
        </button>
      </div>
    </div>
  )
}
export default FixedDepositEdit;