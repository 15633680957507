import React, { useEffect,useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
// import Chart from 'react-apexcharts'
import exportFromJSON from "export-from-json";
import SMBADonutChart from '../charts/SMBADonutChart';
import TotalInvestmentsChart from '../charts/TotalInvestmentsChart';
import MyPortfolioChart from '../charts/MyPortfolioChart';
import { FinIcon } from "../components/Common";
import { FaEye } from "react-icons/fa6";
import useGetInvestmentStock from "../hooks/investment/stock-investment";
import useGetMutualFundsInvestment from "../hooks/investment/mutual-funds-investment";
import useGetAlternateInvestment from "../hooks/investment/alternate-investment";
import { differenceInDays, format, parseISO } from "date-fns";
import useGetFixedIncomeInvestment from "../hooks/investment/fixed-Income-investment";
import useGetPlanningGoals from "../hooks/planning/get-planning-goals";
import useInvestmentLinkGoal from "../hooks/investment/linvestment-link-goal";
import { FaRegEdit } from "react-icons/fa";
import { editAlternateInvestmentsSchema } from "../utils/validationSchema";
import { useFormik } from "formik";
import useEditAlternateInvestment from "../hooks/investment/edit-alternate-investment";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Fill } from "react-icons/ri"
import { GoGoal } from "react-icons/go";
import Item from "antd/es/list/Item";


const Investment = () => {
   
    const navigate = useNavigate()
  

    // usestates
    const [fromDate, setfromDate] = useState('');
    const [toDate, settoDate] = useState('');
    const [sharePercentage, setSharePercentage] = useState();
    const [goalId, setGoalId] = useState('');
    const [investmentId, setInvestmentId] = useState('');
    const [linkedGoals, setLinkedGoals] = useState('');


    // api call
    const { data: stockData, isLoading: stockIsLoading, isFetching: stockIsFetching } = useGetInvestmentStock({ from: fromDate, to: toDate })
    const { data: mutualFundsData, isLoading: mutualFundsIsLoading, isFetching: mutualFundsIsFetching } = useGetMutualFundsInvestment()
    const { data: alternatesData, isLoading: alternateIsLoading, isFetching: alternateIsFetching } = useGetAlternateInvestment()
    const { data: fixedIncomeData, isLoading: fixedIncomeIsLoading, isFetching: fixedIncomeIsFetching } = useGetFixedIncomeInvestment()
    const { data: planningGoalsData, isLoading: planningGoalsIsLoading, isFetching: planningGoalsIsFetching } = useGetPlanningGoals()
    const useInvestmentLinkGoalMutation = useInvestmentLinkGoal()
    const useEditAlternateInvestmentMutation = useEditAlternateInvestment()
    const [addurl, setAddurl] = useState('/stock-add');
    const [dataStock, setDataStock] = useState(stockData);
    const [dataAlernate, setdataAlernate] = useState(alternatesData);

    const [dataMutualFundsData, setMutualFundsData] = useState(mutualFundsData);

    useEffect(() => {
        if (alternatesData) {
            setdataAlernate(alternatesData);
        }
    }, [alternatesData]);

    useEffect(() => {
        if (stockData) {
            setDataStock(stockData);
        }
    }, [stockData]);

    useEffect(() => {
        if (mutualFundsData) {
            setMutualFundsData(mutualFundsData);
        }
    }, [mutualFundsData]);

    useEffect(() => {
        const triggerTabList = document.querySelectorAll('#investmentTabs button[data-bs-toggle]');
        triggerTabList.forEach(triggerEl => {
            triggerEl.addEventListener('show.bs.tab', function (event) {
                setAddurl(event.target.dataset.addurl);
            });

        });

        const myModal = document.getElementById('modalOne');
        myModal.addEventListener('hidden.bs.modal', () => {
            console.log('modal had been hidden');
        });
    }, []);

    // Function to format numbers with commas
    function formatNumberWithCommas(number) {
        return new Intl.NumberFormat('en-IN')?.format(number)?.toString();
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Formik Input
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
             investmentName :investmentId.investmentName || "",
             currentPrice: investmentId.currentPrice ||'',
              buyingPrice: investmentId.buyingPrice ||'',
               purchaseDate: formatDate(investmentId.purchasedDate ||'' )},
        validationSchema: editAlternateInvestmentsSchema,
        onSubmit: values => {
            let payload = {
                investmentName: formik.values.investmentName,
                buyingPrice: Number(formik?.values?.buyingPrice),
                currentPrice: Number(formik.values.currentPrice),
                purchaseDate: formik?.values?.purchaseDate,
            }
            useEditAlternateInvestmentMutation.mutate({ payload: payload, investId: investmentId.investmentId })

        }
    })


    const tabsContent = (tab) => {
        // console.log(tab)
    }

    // function abbreviateString(str) {
    //     return str
    //         .split(' ')
    //         .map(word => word[0])
    //         .join('');
    // }

    const onExportCSVFileStocks = () => {
        const fileName = "StocksCSV";
        const data = !!stockData?.data?.result?.listOfItems && stockData?.data?.result?.listOfItems?.map((item, index) => {
            return {
                Holdings: item?.issuer?.name,
                Quantity: item?.quantity,
                ButPrice: item?.buyingPrice,
                LatestPrice: item?.lastTradedPrice,
                TotalPL: ` ${((item?.lastTradedPrice - item?.buyingPrice) * item?.quantity)?.toFixed(2)}`,
                LastDays: ` ${Math.abs(((item?.issuer?.nseHistory?.from?.tradePrice - item?.issuer?.nseHistory?.to?.tradePrice) * item?.quantity)?.toFixed(2))}`,
                Sector: item?.issuer?.sector,
                Category: item?.issuer?.category,
                SectorPerformance: '',
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSVFileMutualFunds = () => {
        const fileName = "MutualFundsCSV";
        const data = !!mutualFundsData?.data?.result?.listOfItems && mutualFundsData?.data?.result?.listOfItems?.map((item, index) => {

            return {
                Name: item?.fund?.name,
                Category: item?.fund?.category,
                BuyPrice: item?.buyingPrice,
                NAV: item?.netAssetValue,
                CurrentValue: '',
                Change: '',
                Mode: item?.mode,
                TotalPL: item?.lastAmountDue,
                ExpertRatio: '',
                MorningStarRatings: item?.fund?.rating,
                TopPerformer: '',
                LowestExpenseRatonFund: ''
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSVFileFixedIncome = () => {
        const fileName = "FixedIncomeCSV";
        const data = !!fixedIncomeData?.data?.result?.listOfItems && fixedIncomeData?.data?.result?.listOfItems?.map((item, index) => {

            return {
                InstrumentName: item?.instrumentName,
                Category: item?.category,
                Rating: item?.rating,
                InvestedAmount: item?.investedAmount,
                CouponRate: item?.couponRate,
                PaymentFreq: item?.paymentFreq,
                NextIntrestPaymentDate: format(new Date(item?.nextInterestPaymentDate), 'dd/MM/yyyy'),
                MaturityDate: format(new Date(item?.maturityDate), 'dd/MM/yyyy'),
                TaxableExempt: item?.taxableType
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSVFileAlternate = () => {
        const fileName = "AlternateCSV";
        const data = !!alternatesData?.data?.result?.listOfItems && alternatesData?.data?.result?.listOfItems?.map((item, index) => {
            return {
                Name: item?.investmentName,
                EntryPrice: item?.buyingPrice,
                LastTradedPrice: item?.currentPrice,
                ProfitLoss: ` ${(item?.buyingPrice - item?.currentPrice)?.toFixed(2)}`,
                Fees: item?.currentPrice,
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSV = () => {
        if (addurl === '/stock-add') {
            onExportCSVFileStocks()
        } else if (addurl === '/mutual-fund-add') {
            onExportCSVFileMutualFunds()
        } else if (addurl === '/fixed-income-add') {
            onExportCSVFileFixedIncome()
        } else if (addurl === '/alternate-add') {
            onExportCSVFileAlternate()
        }
    }

    const onLinkGoal = () => {
        const payload = {
            investmentId: investmentId,
            sharePercentage: Number(sharePercentage),

        }

        useInvestmentLinkGoalMutation.mutate({ payload: payload, goalId: goalId })
    }


    const editStockHandler = (item) => {
        navigate('/stock-edit', { state: { item } })
    }
    const editMutualFundsHandler = (item) => {
        navigate('/mutual-fund-edit', { state: { item } })
    }
    const editFixedIncomesHandler = (item) => {
        navigate('/fixed-income-edit', { state: { item } })
    }

    useEffect(() => {
        if (stockData) {
            calculateStockSum();
        }
    }, [stockData]);
    function filterHistoryByDateRange(history, fromDate, toDate) {
        // Convert fromDate and toDate to Date objects for comparison
        const from = new Date(fromDate);
        const to = new Date(toDate);

        // Iterate over each stock in the history object
        for (const stock in history) {
            // Filter the trade entries that fall within the date range
            history[stock] = history[stock].filter(entry => {
                const entryDate = new Date(entry.date);
                return entryDate >= from && entryDate <= to;
            });
        }

        return history;
    }


    const calculateStockSum = () => {

        const investments = stockData?.data?.result?.listOfItems;

        console.log('investments', investments, 'fromto', fromDate, toDate);


        const issuerQuantities = investments.reduce((acc, investment) => {
            const issuerId = investment.issuer.id;

            if (acc[issuerId]) {
                acc[issuerId] += investment.quantity;
            } else {
                acc[issuerId] = investment.quantity;
            }

            var his = filterHistoryByDateRange(stockData?.data?.result?.stockHistoryData, fromDate, toDate)

            return acc;
        }, {});

        // If you want to get the result as an array of objects:
        const result = Object.keys(issuerQuantities).map(issuerId => ({
            issuerId,
            totalQuantity: issuerQuantities[issuerId]
        }));
        console.log(result);


    }
    const handleDeleteAlerate = (goalId, investmentId) => {
        const updatedAlternatesData = dataAlernate?.data?.result?.listOfItems.map(item => {
            if (item.investmentId === investmentId) {
                return {
                    ...item,
                    linkedGoals: item.linkedGoals.filter(goal => goal.goalId !== goalId)
                };
            }
            return item;
        });

        setdataAlernate(prevData => ({
            ...prevData,
            data: {
                ...prevData.data,
                result: {
                    ...prevData.data.result,
                    listOfItems: updatedAlternatesData
                }
            }
        }));
    };

    const handleEditGoal = (goal) => {
        navigate('/planning-goal-edit', { state: { goal } })
    }

    const handleDeleteGoal = (goalId, investmentId) => {
        const updatedAlternatesData = dataStock?.data?.result?.listOfItems.map(item => {
            if (item.investmentId === investmentId) {
                return {
                    ...item,
                    linkedGoals: item.linkedGoals.filter(goal => goal.goalId !== goalId)
                };
            }
            return item;
        });

        setDataStock(prevData => ({
            ...prevData,
            data: {
                ...prevData.data,
                result: {
                    ...prevData.data.result,
                    listOfItems: updatedAlternatesData
                }
            }
        }));
    };

    const handleDeleteMutual = (goalId, investmentId) => {
        const updatedAlternatesData = dataMutualFundsData?.data?.result?.listOfItems.map(item => {
            if (item.investmentId === investmentId) {
                return {
                    ...item,
                    linkedGoals: item.linkedGoals.filter(goal => goal.goalId !== goalId)
                };
            }
            return item;
        });

        setMutualFundsData(prevData => ({
            ...prevData,
            data: {
                ...prevData.data,
                result: {
                    ...prevData.data.result,
                    listOfItems: updatedAlternatesData
                }
            }
        }));
    };


    return (<>
        <div className="row row-inner">
            <div className="col">
                <h3 className="inter-700">Investment</h3>
            </div>
            <div className="col-auto">
                <div className="d-flex gap-2 gap-xxl-3">
                    <button onClick={onExportCSV} type="button" className="btn btn-normal btn-light">
                        <FinIcon name="export" className="ts-1p4" />
                        Export
                    </button>
                    <Link to={addurl} className="btn btn-normal btn-primary">
                        <FinIcon name="addcirlce" className="ts-1p4" />
                        Add Investment
                    </Link>
                </div>
            </div>
            <div className="col-12 py-2">
                <SMBADonutChart height="200" />
            </div>
        </div>


        <div className="row row-inner">
            <div className="col py-3">
                <div className="row mx-0 bg-gray-50 p-3 rounded-3 border border-common">
                    <div className="col py-2">
                        <div className="d-flex gap-2 gap-lg-3">
                            <div className="block">
                                <h4 className="inter-700">Total Investments</h4>
                                <p className="inter-500 text-b100 mb-0">Graph & Chart</p>
                            </div>
                            <div className="vr bg-secondary"></div>
                            <div className="block align-self-center">
                                <p className="inter-500 text-b100 mb-2">Graph & Chart</p>
                                <select className="form-select inter-700" defaultValue="all">
                                    <option value="all">All</option>
                                    <option value="stocks">Stocks</option>
                                    <option value="mutual-funds">Mutual Funds</option>
                                    <option value="fixed-income">Fixed Income</option>
                                    <option value="alternate">Alternate</option>
                                </select>

                            </div>
                        </div>
                    </div>
                    <div className="col-auto py-2">
                        <div className="input-group input-group-datepicker">
                            {/* <input type="text" className="form-control rounded-3" defaultValue="28 Nov 2023 - 28 Feb 2024" placeholder="dd mm yyyy - dd mm yyyy" aria-label="Text input with dropdown button" />
                            <FinIcon name="calendar" /> */}

                            <div className="m-1">
                                <label htmlFor="fromDate" className="form-label  ms-2">From</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    className="form-control rounded-3"
                                    value={fromDate}
                                    onChange={(e) => { setfromDate(format(parseISO(e.target.value), 'yyyy-MM-dd')) }}
                                    placeholder="dd-mm-yyyy"
                                    aria-label="From date input"
                                />
                            </div>

                            <div className="m-1">
                                <label htmlFor="toDate" className="form-label  ms-2">To</label>
                                <input
                                    type="date"
                                    id="toDate"
                                    className="form-control rounded-3"
                                    value={toDate}
                                    onChange={(e) => { settoDate(format(parseISO(e.target.value), 'yyyy-MM-dd')) }}
                                    placeholder="dd-mm-yyyy"
                                    aria-label="To date input"
                                />
                            </div>


                            {/* <button className="btn bg-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Last 90 days</button>
                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-3">
                                <li><Link to={'/'} className="dropdown-item">Last 30 days</Link></li>
                                <li><Link to={'/'} className="dropdown-item">Last 45 days</Link></li>
                                <li><Link to={'/'} className="dropdown-item">Last 90 days</Link></li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="col-12">
                        <TotalInvestmentsChart height="380" />
                    </div>
                </div>
            </div>
        </div>


        <div className="row row-inner">

            <div className="col py-4">
                <MyPortfolioChart height="380" fromDate={fromDate} toDate={toDate} />
            </div>
        </div>


        <div 
         className="row row-inner">
            <div  className="col pt-1">
                <h3  className="inter-700">Portfolio</h3>
            </div>
            {addurl === '/stock-add' && (<div className="col-auto mb-3">
                <div className="input-group input-group-datepicker">
                    {/* <input type="date" className="form-control" value={fromDate} onChange={(e) => { setfromDate(format(parseISO(e.target.value), 'yyyy-MM-dd')) }} placeholder="dd mm yyyy" aria-label="Text input with dropdown button" />

                    <input type="date" className="form-control" value={toDate} onChange={(e) => { settoDate(format(parseISO(e.target.value), 'yyyy-MM-dd')) }} placeholder="dd mm yyyy" aria-label="Text input with dropdown button" />
                    <FinIcon name="calendar" /> */}
                    {/* <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Last 90 days</button>
                    <ul className="dropdown-menu dropdown-menu-end border-0 shadow rounded-3">
                        <li><Link to={'/'} className="dropdown-item">Last 30 days</Link></li>
                        <li><Link to={'/'} className="dropdown-item">Last 45 days</Link></li>
                        <li><Link to={'/'} className="dropdown-item">Last 90 days</Link></li>
                    </ul> */}
                </div>
            </div>)}
            <div className="col-auto ml-20 ms-md-auto mb-3">
                <ul className="nav nav-pills nav-pills-common" id="investmentTabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="holdings-tab" data-bs-toggle="pill" data-bs-target="#holdingsBox" type="button" role="tab" aria-controls="holdingsBox" aria-selected="true" onClick={() => tabsContent('holdings')} data-addurl="/stock-add">Stocks</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="mutualfunds-tab" data-bs-toggle="pill" data-bs-target="#mutualfundsBox" type="button" role="tab" aria-controls="mutualfundsBox" aria-selected="false" onClick={() => tabsContent('mutualfunds')} data-addurl="/mutual-fund-add">Mutual Funds</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="fixedincome-tab" data-bs-toggle="pill" data-bs-target="#fixedIncomeBox" type="button" role="tab" aria-controls="fixedIncomeBox" aria-selected="false" onClick={() => tabsContent('fixedincome')} data-addurl="/fixed-income-add">Fixed Income</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="alternate-tab" data-bs-toggle="pill" data-bs-target="#alternateBox" type="button" role="tab" aria-controls="alternateBox" aria-selected="false" onClick={() => tabsContent('alternate')} data-addurl="/alternate-add">Alternate</button>
                    </li>
                </ul>
            </div>
            <div className="col-12 mb-4">
                <div className="tab-content">
                    <div className="tab-pane show active" id="holdingsBox" role="tabpanel" aria-labelledby="holdings-tab" tabIndex="0">
                        <div className="table-responsive">
                            <table className="table-common">
                                <thead>
                                    <tr>
                                        <th>Holdings</th>
                                        <th>Quantity</th>
                                        <th>Buy <br />Price</th>
                                        <th>Latest <br />Price</th>
                                        <th width="120">Total P&L</th>
                                        <th> %  Charge</th>
                                        <th>Sector</th>
                                        <th>Category</th>
                                        <th width="100">Sector <br />Performance</th>
                                        <th>Linked Goal</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {stockIsLoading || stockIsFetching ?
                                        <tr key="loading">
                                            <td colSpan="11" className="text-center">
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                        : <>
                                            {
                                                !!dataStock?.data?.result?.listOfItems && dataStock?.data?.result?.listOfItems?.map((item, index) => {
                                                    return (
                                                        <tr key={index.toString()}>
                                                            <td>{item?.issuer?.name}</td>
                                                            <td>{item?.quantity}</td>
                                                            <td>₹{formatNumberWithCommas(item?.buyingPrice)}</td>
                                                            <td>₹{formatNumberWithCommas(item?.lastTradedPrice)}</td>
                                                            <td>
                                                                <span className="text-success300">+₹{formatNumberWithCommas(((Math.abs(item?.lastTradedPrice - item?.buyingPrice)) * item?.quantity)?.toFixed(2))}</span>
                                                            </td>
                                                            <td>{Math?.abs(((item?.issuer?.nseHistory?.from?.tradePrice - item?.issuer?.nseHistory?.to?.tradePrice) * item?.quantity)?.toFixed(2))}</td>
                                                            <td>{item?.issuer?.sector}</td>
                                                            <td>{item?.issuer?.category}</td>
                                                            <td>{item?.sectorPerformance}</td>


                                                            <td>
                                                                <div className="d-flex d-gap gap-2">
                                                                    <button type="button" onClick={() => { setInvestmentId(item?.investmentId) }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalOne">
                                                                        <GoGoal />

                                                                    </button>
                                                                    <button type="button" onClick={() => { setLinkedGoals(item) }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalTwo">
                                                                        <FaEye />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => editStockHandler(item)}
                                                                    className="btn btn-primary">
                                                                    <FaRegEdit />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane" id="mutualfundsBox" role="tabpanel" aria-labelledby="mutualfunds-tab" tabIndex="0">
                        <div className="table-responsive">
                            <table className="table-common">
                                <thead>
                                    <tr>
                                        <th>Scheme Name</th>
                                        <th>Category</th>
                                        <th>Buy <br />Now</th>
                                        <th>NAV</th>
                                        <th>Current <br />Now</th>
                                        <th>Expense <br />Ratio</th>
                                        <th>% Charge</th>
                                        <th>Mode</th>
                                        <th>Total P&L</th>
                                        <th>MorningStar <br />Ratings</th>
                                        <th>Top <br />Performer (3yr)</th>
                                        <th width="200">Lowest Expense<br /> Ratio Fund</th>
                                        <th>Linked Goal</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {mutualFundsIsLoading || mutualFundsIsFetching ?
                                        <tr key="loading">
                                            <td colSpan="13" className="text-center">
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                        : <>
                                            {
                                                !!dataMutualFundsData?.data?.result?.listOfItems && dataMutualFundsData?.data?.result?.listOfItems?.map((item, index) => {
                                                    return (
                                                        <tr key={index.toString()}>
                                                            <td>{item?.fund?.name}</td>
                                                            <td>{item?.fund?.category}</td>
                                                            <td>₹{formatNumberWithCommas(item?.buyingPrice)}</td>
                                                            <td>₹{formatNumberWithCommas(item?.netAssetValue)}</td>
                                                            <td>₹{formatNumberWithCommas(item?.currentValue)}</td>
                                                            <td>{item?.perChange}</td>
                                                            <td>{item?.mode}</td>
                                                            <td>
                                                                <span className="text-success300">+₹{item?.profitLoss}</span>
                                                            </td>
                                                            <td>₹{item?.expertRatio}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center gap-1">
                                                                    <FinIcon name="starfill" className="text-warning300 ts-1p2" />
                                                                    {item?.fund?.rating}
                                                                </div>
                                                            </td>
                                                            <td>{item?.topPerformer}</td>
                                                            <td>{item?.lerFund}</td>
                                                            <td>
                                                                <div className="d-flex d-gap gap-2">
                                                                    <button type="button" onClick={() => { setInvestmentId(item?.investmentId) }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalOne">
                                                                        <GoGoal />
                                                                    </button>
                                                                    <button type="button" onClick={() => { setLinkedGoals(item) }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalTwo">
                                                                        <FaEye />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => editMutualFundsHandler(item)}
                                                                    className="btn btn-primary">
                                                                    <FaRegEdit />
                                                                </button>
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="tab-pane" id="fixedIncomeBox" role="tabpanel" aria-labelledby="fixedincome-tab" tabIndex="0">
                        <div className="table-responsive">
                            <table className="table-common">
                                <thead>
                                    <tr>
                                        <th>Instrument Name</th>
                                        <th>Category</th>
                                        <th>Rating</th>
                                        <th>Invested <br />Amount</th>
                                        <th>Coupon <br />Rate</th>
                                        <th>Payment Freq</th>
                                        <th width="120">Next Intrest <br />Payment Date</th>
                                        <th width="120">Maturity Date</th>
                                        <th width="135">Taxable/Exempt</th>
                                        <th>Linked Goal</th>

                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {fixedIncomeIsLoading || fixedIncomeIsFetching ?
                                        <tr key="loading">
                                            <td colSpan="11" className="text-center">
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                        : <>
                                            {
                                                !!fixedIncomeData?.data?.result?.listOfItems && fixedIncomeData?.data?.result?.listOfItems?.map((item, index) => {
                                                    return (
                                                        <tr key={index?.toString()}>
                                                            <td>
                                                                <abbr title={item?.instrumentName}>

                                                                    {(item?.instrumentName)}
                                                                </abbr>
                                                            </td>
                                                            <td>{item?.category}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center gap-1">
                                                                    <FinIcon name="starfill" className="text-warning300 ts-1p2" />
                                                                    {item?.rating}
                                                                </div>
                                                            </td>
                                                            <td>₹{formatNumberWithCommas(item?.investedAmount)}</td>
                                                            <td>{item?.couponRate}</td>
                                                            <td>{item?.paymentFreq}</td>
                                                            <td>{format(new Date(item?.nextInterestPaymentDate), 'dd MMM yyyy')}</td>
                                                            <td>{format(new Date(item?.maturityDate), 'dd MMM yyyy')}</td>
                                                            <td>{item?.taxableType}</td>

                                                            <td>
                                                                <div className="d-flex d-gap gap-2">
                                                                    <button type="button" onClick={() => { setInvestmentId(item?.investmentId) }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalOne">
                                                                        <GoGoal />

                                                                    </button>
                                                                    <button type="button" onClick={() => { setLinkedGoals(item) }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalTwo">
                                                                        <FaEye />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <td>
                                                                    <button type="button" onClick={() => editFixedIncomesHandler(item)}
                                                                        className="btn btn-primary d-flex">
                                                                        <FaRegEdit />
                                                                    </button>
                                                                </td>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane" id="alternateBox" role="tabpanel" aria-labelledby="alternate-tab" tabIndex="0">
                        <div className="table-responsive">
                            <table className="table-common">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Entry <br />Price</th>
                                        <th>Last <br />Traded Price</th>
                                        <th>Profit & <br />Loss</th>
                                        <th>Fees</th>
                                        <th>Linked Goal</th>

                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {alternateIsLoading || alternateIsFetching ?
                                        <tr key="loading">
                                            <td colSpan="7" className="text-center">
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                        : <>
                                            {
                                                !!dataAlernate?.data?.result?.listOfItems && dataAlernate?.data?.result?.listOfItems?.map((item, index) => {
                                                    return (
                                                        <tr key={index.toString()}>
                                                            <td>{item?.investmentName}</td>
                                                            <td>₹{formatNumberWithCommas(item?.buyingPrice)}</td>
                                                            <td>₹{formatNumberWithCommas(item?.currentPrice)}</td>
                                                            <td>
                                                                <span className="text-success300">+₹{formatNumberWithCommas((Math.abs(item?.buyingPrice - item?.currentPrice))?.toFixed(2))}</span>
                                                            </td>
                                                            <td>₹{formatNumberWithCommas(item?.currentPrice)}</td>
                                                            <td>
                                                                <div className="d-flex d-gap gap-2">
                                                                    <button type="button" onClick={() => { setInvestmentId(item?.investmentId) }} className="btn btn-primary d-gap d-flex align-items-center gap-1 " data-bs-toggle="modal" data-bs-target="#modalOne">
                                                                        <GoGoal />

                                                                    </button>
                                                                    <button type="button" onClick={() => { setLinkedGoals(item) }} className="btn btn-primary d-gap d-flex align-items-center gap-1" data-bs-toggle="modal" data-bs-target="#modalTwo">
                                                                        <FaEye />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => {
                                                                    setInvestmentId(item)
                                                                }} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalEditAlternate">
                                                                    <FaRegEdit />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="modalOne" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Link Goal</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col">
                            <label htmlFor="shareP  ercentage" className="small inter-500 text-b200 mb-2">Share Percentage</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Share Percentage"
                                min="0"
                                id="sharePercentage"
                                name="sharePercentage"

                                onChange={(e) => { setSharePercentage(e.target.value) }}
                                value={sharePercentage}

                            />

                        </div>
                        <select className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm h-40 mt-3"
                            id="linkgoal"
                            name="linkgoal"
                            value={goalId}
                            onChange={(e) => { setGoalId(e.target.value) }}
                        >
                            <option>-- Select Goal --</option>
                            {
                                !!planningGoalsData?.data?.result && planningGoalsData?.data?.result?.map((item, index) => {
                                    return (
                                        <option value={item?.goalId}>{item?.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={onLinkGoal}>
                            Link Goal
                            {useInvestmentLinkGoalMutation?.isPending && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="modalTwo" tabIndex="-1" aria-labelledby="modalTwoLabel" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="modalTwoLabel">Link and Manage Goals</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                {linkedGoals?.linkedGoals?.length > 0 ? (
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Goal Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {linkedGoals.linkedGoals.map((goal, index) => (
                                <tr key={goal.goalId}>
                                    <td>{index + 1}</td>
                                    <td>{goal.name}</td>
                                    <td>
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-sm"
                                            onClick={() => handleDeleteGoal(goal.goalId)}
                                        >
                                            <RiDeleteBin6Fill />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm ms-2"
                                            data-bs-dismiss="modal"
                                            onClick={() => handleEditGoal(goal)}
                                        >
                                            <FaRegEdit />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No linked goals available.</p>
                )}
            </div>
        </div>
    </div>
        </div>




        <div className="modal fade" id="modalEditAlternate" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Update Alternate</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col">
                            <label htmlFor="investmentName" className="small inter-500 text-b200 mb-2">Investment Name</label>
                            <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Investment Name"
                                min="0"
                                id="investmentName"
                                name="investmentName"
                                value={formik?.values?.investmentName}
                                onBlur={formik.handleBlur('investmentName')}
                                onInput={formik.handleChange('investmentName')}
                            />
                            {(formik.errors.investmentName && formik.touched.investmentName) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.investmentName}
                            </p> : null}
                        </div>

                        <div className="col">
                            <label htmlFor="buyprice" className="small inter-500 text-b200 mb-2">Buying Price</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Buy Price"
                                min="0"
                                id="buyingprice"
                                name="buyingprice"
                                value={formik?.values?.buyingPrice}
                                onBlur={formik.handleBlur('buyingPrice')}
                                onInput={formik.handleChange('buyingPrice')}
                            />
                            {(formik.errors.buyingPrice && formik.touched.buyingPrice) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.buyingPrice}
                            </p> : null}
                        </div>

                        <div className="col">
                            <label htmlFor="currentprice" className="small inter-500 text-b200 mb-2">Current Price</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Current Price"
                                min="0"
                                id="currentprice"
                                name="currentprice"
                                value={formik?.values?.currentPrice}
                                onBlur={formik.handleBlur('currentPrice')}
                                onInput={formik.handleChange('currentPrice')}
                            />
                            {(formik.errors.currentPrice && formik.touched.currentPrice) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.currentPrice}
                            </p> : null}
                        </div>

                        <div className="col">
                            <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">purchase Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00"
                                min="0"
                                id="purchaseDate"
                                name="purchaseDate"
                                value={formik?.values?.purchaseDate}
                                onBlur={formik.handleBlur('purchaseDate')}
                                onInput={formik.handleChange('purchaseDate')}
                            />
                            {(formik.errors.purchaseDate && formik.touched.purchaseDate) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.purchaseDate}
                            </p> : null}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={formik.handleSubmit}>
                            Edit
                            {useEditAlternateInvestmentMutation?.isPending && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Investment;

