import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FinIcon } from "../components/Common";
import RetirementPlannerChart from "../charts/RetirementPlannerChart";
import { differenceInYears, parseISO } from "date-fns";
import useGetUserProfile from "../hooks/auth-service/get-user-profile";

const Planning = () => {
 
  


    const expenses = [{
        id: 1,
        date: '29/02/2024',
        account: 'XXX 5463',
        description: 'Paid march house loan EMI',
        category: 'Installment',
        amount: 500
    }, {
        id: 2,
        date: '01/03/2024',
        account: 'XXX 5463',
        description: 'Paid march house loan EMI',
        category: 'Installment',
        amount: 500
    }, {
        id: 3,
        date: '02/03/2024',
        account: 'XXX 5463',
        description: 'Paid march house loan EMI',
        category: 'Installment',
        amount: 500
    }, {
        id: 4,
        date: '03/03/2024',
        account: 'XXX 5463',
        description: 'Paid march house loan EMI',
        category: 'Installment',
        amount: 500
    }, {
        id: 5,
        date: '04/03/2024',
        account: 'XXX 5463',
        description: 'Paid march house loan EMI',
        category: 'Installment',
        amount: 500
    }, {
        id: 6,
        date: '05/03/2024',
        account: 'XXX 5463',
        description: 'Paid march house loan EMI',
        category: 'Installment',
        amount: 500
    }];

    const income = [{
        id: 1,
        date: '29/02/2024',
        account: 'XXX 5463',
        description: 'Quarterly Shares Dividend',
        category: 'Shares Dividend',
        amount: 1000
    }, {
        id: 2,
        date: '01/03/2024',
        account: 'XXX 5463',
        description: 'Quarterly Shares Dividend',
        category: 'Shares Dividend',
        amount: 1000
    }, {
        id: 3,
        date: '02/03/2024',
        account: 'XXX 5463',
        description: 'Quarterly Shares Dividend',
        category: 'Shares Dividend',
        amount: 1000
    }, {
        id: 4,
        date: '03/03/2024',
        account: 'XXX 5463',
        description: 'Quarterly Shares Dividend',
        category: 'Shares Dividend',
        amount: 1000
    }, {
        id: 5,
        date: '04/03/2024',
        account: 'XXX 5463',
        description: 'Quarterly Shares Dividend',
        category: 'Shares Dividend',
        amount: 1000
    }, {
        id: 6,
        date: '05/03/2024',
        account: 'XXX 5463',
        description: 'Quarterly Shares Dividend',
        category: 'Shares Dividend',
        amount: 1000
    }];

    // api 
    const { data: userProfileData, isLoading: userProfileIsLoading, isFetching: userProfileIsFetching } = useGetUserProfile()
console.log(userProfileData)
    // state
    const [userAge, setUserAge] = useState(23);

    useEffect(() => {
        if (userProfileData?.data && !userProfileIsLoading) {
            setUserAge(calculateAge(userProfileData?.data?.result?.dob))
        }

    }, [userProfileIsLoading, userProfileData?.data]);

    function calculateAge(dateString) {
        const birthDate = parseISO(!!dateString ? dateString : '1990-05-06T00:00:00.000Z');
        const currentDate = new Date();
        const age = differenceInYears(currentDate, birthDate);
        return age;
    }

    return (<>
        <div className="row row-inner">
            <div className="col">
                <div className="d-flex gap-3">
                    <h3 className="inter-700">Retirement Planner</h3>
                </div>
            </div>
            <div className="col-auto">
                {/* <button type="button" className="btn btn-normal btn-link text-decoration-none px-0">
                    Age {userAge}
                </button> */}
                {/* <div className="col"> */}
                {/* <label htmlFor="quantity" className="small inter-500 text-b200 mb-2">Quantity</label> */}
                {/* <FinIcon name="pencil" className="ts-1p4"/> */}
                <input type="number" className="form-control inter-500 bg-white shadow-sm" placeholder="Age"
                    min="0"
                    id="quantity"
                    name="quantity"
                    onInput={(e) => { setUserAge(e.target.value) }}
                    value={userAge}
                />
                {/* {(formik.errors.quantity && formik.touched.quantity) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.quantity}
                            </p> : null}
                        </div> */}
            </div>
        </div>
        <div className="row row-inner">
            <div className="col-md-12 mt-3">
                <RetirementPlannerChart ageuser={userAge} height="460" />
            </div>
        </div>
    </>)
}
export default Planning;
