import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useLocation } from "react-router-dom";
import { fetcher } from "../utils/fetcher";
import usePostinvestment from "../hooks/investment/post-investment";
import { FinIcon } from '../components/Common';
import Select2 from '../components/Select2'; // Adjust the path as needed
import { Link } from "react-router-dom";
import useEditInvestment from "../hooks/investment/edit-investment";
import { useNavigate } from "react-router-dom";
const stockSchema = Yup.object().shape({
    name: Yup.string().required('Please enter Name.'),
    quantity: Yup.number().required('Please enter integer value.'),
    buyingPrice: Yup.number().required('Please enter Buying Price.'),
});

const StockEdit = () => {
    const navigate = useNavigate()
    const handleBackToInveshment=()=>{
        navigate('/investment')
        setTimeout(() => {
            window.scrollTo({
              top: document.documentElement.scrollHeight,
              behavior: 'smooth',
            });
          }, 100);
    }
    
    const location = useLocation();
    const item = location.state?.item;
    console.log(item);

    const { mutate } = useEditInvestment();  // Ensure you're extracting the mutate function properly

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            investmentId: item?.investmentId || '',
            quantity: item?.quantity || '',
            buyingPrice: item?.buyingPrice || '',
            purchaseDate: item?.purchasedDate?.split('T')[0] || new Date().toISOString().split('T')[0],
        },
        validationSchema: stockSchema,

        onSubmit: (values) => {
            try {
                const payload = {
                    investmentName: values.name,
                    buyingPrice: Number(values.buyingPrice),
                    purchaseDate: values.purchaseDate,
                };

                const dataToSend = {
                    payload: payload,
                    investmentId: values.investmentId,
                };

                mutate(dataToSend, {
                    onSuccess: (data) => {
                        formik.resetForm();
                        console.log('Successfully submitted:', data);
                        navigate('/investment')
                    },
                    onError: (error) => {
                        console.log('Submission error:', error.response?.data || error.message);
                        console.error('Full error object:', error);
                    },
                });

            } catch (error) {
                console.error('An error occurred during submission:', error);
            }
        },
    });

    return (
        <>
            <div className="row row-inner">
                <div className="col align-self-center">
                    <h3 className="inter-700">Update Stock</h3>
                    <p className="text-b200 small">Update Stock basic details.</p>
                </div>
                <div className="col-auto">
                    <button onClick={handleBackToInveshment} className="btn btn-normal btn-light">
                        <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                    </button>
                </div>

                <div className="col-12 mt-1 mt-xl-2 mb-4">
                    <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">
                            
                            {/* Stock Name (Read-Only) */}
                            <div className="col">
                                <label className="small inter-500 text-b200 mb-2">Stock Name</label>
                                <input
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    value={item?.investmentName || ''}
                                    readOnly
                                />
                            </div>

                            {/* Quantity */}
                            <div className="col">
                                <label htmlFor="quantity" className="small inter-500 text-b200 mb-2">Quantity</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="00"
                                    min="0"
                                    id="quantity"
                                    name="quantity"
                                    onChange={formik.handleChange}
                                    value={formik.values.quantity}
                                />
                                {formik.errors.quantity && formik.touched.quantity ? (
                                    <p className="fs-14px text-danger inter-500 my-1">
                                        {formik.errors.quantity}
                                    </p>
                                ) : null}
                            </div>

                            {/* Buying Price */}
                            <div className="col">
                                <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Buying Price</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="00.00"
                                    min="0"
                                    id="buyingPrice"
                                    name="buyingPrice"
                                    onChange={formik.handleChange}
                                    value={formik.values.buyingPrice}
                                />
                                {formik.errors.buyingPrice && formik.touched.buyingPrice ? (
                                    <p className="fs-14px text-danger inter-500 my-1">
                                        {formik.errors.buyingPrice}
                                    </p>
                                ) : null}
                            </div>

                            {/* Purchase Date */}
                            <div className="col">
                                <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">Purchase Date</label>
                                <input
                                    type="date"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    id="purchaseDate"
                                    name="purchaseDate"
                                    value={formik.values.purchaseDate}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="button"
                        className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4"
                        onClick={formik.handleSubmit}
                    >
                        <FinIcon name="save" className="ts-1p5 me-3" />
                        Update Stock
                    </button>
                </div>
            </div>
        </>
    );
};

export default StockEdit;
