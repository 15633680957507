import React from "react";
import { FinIcon, AddInvestmentBack } from '../components/Common';
import usePostinvestment from "../hooks/investment/post-investment";
import { useFormik } from "formik";
import { addAlternateInvestmentsSchema } from "../utils/validationSchema";
import { useNavigate } from "react-router-dom";
const AlternateAdd = ({ ...props }) => {

    // api calls
    const usePostinvestmentMutation = usePostinvestment()
    const navigate =useNavigate()
    // Formik Inputs
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { investmentName: "", currentPrice: '', investmentType: 'ALTERNATIVE', buyingPrice: '', purchaseDate: '' },
        validationSchema: addAlternateInvestmentsSchema,
        onSubmit: values => {

            let payload = {
                investmentName: formik.values.investmentName,
                currentPrice: Number(formik.values.currentPrice),
                investmentType: formik?.values.investmentType,
                buyingPrice: Number(formik?.values?.buyingPrice),
                purchaseDate: formik?.values?.purchaseDate,

            }
            console.log(payload);
            usePostinvestmentMutation.mutate(payload, {
                onSuccess: (data) => {
                    formik.resetForm()
                    navigate('/investment')
                },
            })
        }
    })


    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Add Alternate Investment</h3>
                <p className="text-b200 small">
                    Add Alternate Investment basic details.
                </p>
            </div>
            <div className="col-auto">
                <AddInvestmentBack value="ALTERNATIVE" />
            </div>

            {/* --- */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="investName" className="small inter-500 text-b200 mb-2">Investment Name</label>
                            <input type="text" id="investName"
                                value={formik?.values?.investmentName}
                                onBlur={formik.handleBlur('investmentName')}
                                onInput={formik.handleChange('investmentName')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" defaultValue="" placeholder="Investment Name" />
                            {(formik.errors.investmentName && formik.touched.investmentName) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.investmentName}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="currentPrice" className="small inter-500 text-b200 mb-2">Purchase Value</label>
                            <input type="text" id="currentPrice"
                                value={formik?.values?.currentPrice}
                                onBlur={formik.handleBlur('currentPrice')}
                                onInput={formik.handleChange('currentPrice')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" defaultValue="" placeholder="00.00" />
                            {(formik.errors.currentPrice && formik.touched.currentPrice) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.currentPrice}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Current Value<strong className="text-danger">*</strong></label>
                            <input type="text" id="buyingPrice"
                                value={formik?.values?.buyingPrice}
                                onBlur={formik.handleBlur('buyingPrice')}
                                onInput={formik.handleChange('buyingPrice')}
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" defaultValue="" placeholder="00.00" />
                            {(formik.errors.buyingPrice && formik.touched.buyingPrice) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.buyingPrice}
                            </p> : null}
                        </div>

                        <div className="col-12 w-100 mt-0"></div>


                        <div className="col">
                            <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">purchase Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00.00"
                                min="0"
                                id="purchaseDate"
                                name="purchaseDate"
                                value={formik?.values?.purchaseDate}
                                onBlur={formik.handleBlur('purchaseDate')}
                                onInput={formik.handleChange('purchaseDate')}
                            />
                            {(formik.errors.purchaseDate && formik.touched.purchaseDate) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.purchaseDate}
                            </p> : null}
                        </div>
                    </div>
                </div>

                <button type="button" disabled={usePostinvestmentMutation?.isPending} onClick={formik.handleSubmit} className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
                    <FinIcon name="save" className="ts-1p5 me-3" />
                    Save Alternate  {usePostinvestmentMutation?.isPending && <span className="spinner-border spinner-border-sm mx-lg-1" role="status" aria-hidden="true"></span>}
                </button>
            </div>
        </div>
    )
}
export default AlternateAdd;