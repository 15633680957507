import React, { useState } from "react";

import { FinIcon, MeterChart } from "../components/Common";
import useGetPlanningGoals from "../hooks/planning/get-planning-goals";
import { format } from "date-fns";
import useGetPlanningEstimates from "../hooks/planning/get-planning-estimate";

import TimelineChart from '../charts/TimelineChart';
import ProgressOfGoalsBarChart from '../charts/ProgressOfGoalsBarChart';
import DashboardAllTimeChart from '../charts/DashboardAllTimeChart';
import useGetPlanningExpenses from "../hooks/planning/get-planning-expenses";
import useGetPlanningSavings from "../hooks/planning/get-planning-savings";
import useGetLoans from "../hooks/loan/get-loans";
import useGetBankAggregates from "../hooks/bank-services/get-bank-aggregates";
import useGetInvestmentAggregates from "../hooks/investment/get-investment-aggregates";
import { useEffect } from "react";
import useGetLoanScore from "../hooks/loan/get-loan-score";
import useGetPlanningScore from "../hooks/planning/get-planning-score";
import useGetInsuranceScore from "../hooks/insurance/get-insurance-score";
import useGetEmergencyFund from "../hooks/planning/get-emergency-fund";

const Dashboard = () => {
	function formatNumberWithCommas(number) {
		return new Intl.NumberFormat('en-IN')?.format(number)?.toString();
	}

	// init 
	const date = new Date();
	const cyear = format(date, 'yyyy');
	const colors = ['red', 'green', 'yellow'];

	// state
	const [assetsList, setAssetsList] = useState([])

	// api
	const { data: planningGoalsData, isLoading: planningGoalsIsLoading, isFetching: planningGoalsIsFetching } = useGetPlanningGoals()
	const { data: emergencyFundData, isLoading: emergencyFundIsLoading, isFetching: emergencyFundIsFetching } = useGetEmergencyFund()

	const { data: loanScoreData, isLoading: loanScoreIsLoading, isFetching: loanScoreIsFetching } = useGetLoanScore();
	const { data: planningScoreData, isLoading: planningScoreIsLoading, isFetching: planningScoreIsFetching } = useGetPlanningScore()
	const { data: insuranceScoreData, isLoading: insuranceScoreIsLoading, isFetching: insuranceScoreIsFetching } = useGetInsuranceScore()
	const { data: investmentScoreData, isLoading: investmentScoreIsLoading, isFetching: investmentScoreIsFetching } = useGetInsuranceScore()

	const { data: planningEstimatesData, isLoading: planningEstimatesIsLoading, isFetching: planningEstimatesIsFetching } = useGetPlanningEstimates(cyear)
	const { data: planningExpensesData, isLoading: planningExpensesIsLoading, isFetching: planningExpensesIsFetching } = useGetPlanningExpenses(cyear)
	const { data: planningSavingsData, isLoading: planningSavingsIsLoading, isFetching: planningSavingsIsFetching } = useGetPlanningSavings(cyear)
	const { data: getLoansData, isLoading: getLoansIsLoading, isFetching: getLoansIsFetching } = useGetLoans()
	const { data: getBankAggregatesData, isLoading: getBankAggregatesIsLoading, isFetching: getBankAggregatesFetching } = useGetBankAggregates()
	const { data: getInvestmentAggregatesData, isLoading: getInvestmentAggregatesIsLoading, isFetching: getInvestmentAggregatesFetching } = useGetInvestmentAggregates()

	const totalAmountLiabilities = getLoansData?.data?.result?.listOfItems?.reduce((sum, loan) => sum + parseFloat(loan?.principalOutstanding), 0)

	const totalBanking = getBankAggregatesData?.data?.result?.banking?.total || 0;
	const emergencyFundAmount = emergencyFundData?.data?.result?.amount || 0;

	const difference = emergencyFundAmount - totalBanking;

	// Calculate the score between 0 and 100
	let score = 0;

	if (emergencyFundAmount > 0) {
		score = Math.min((totalBanking / emergencyFundAmount) * 100, 100);
	}

	const B = [investmentScoreData?.data?.result.score, planningScoreData?.data?.result.score, insuranceScoreData?.data?.result.score, loanScoreData?.data?.result.score]; // Replace with actual values
	const C = [16.7, 50, 16.7, 16.7]; // Replace with actual values

	// Calculate SUMPRODUCT and divide by 100
	const sumProduct = Math.round((B.reduce((acc, bValue, index) => acc + (bValue * C[index]), 0) / 100), 2);



	useEffect(() => {
		if (!getBankAggregatesIsLoading && !getInvestmentAggregatesIsLoading && getBankAggregatesData?.data && getInvestmentAggregatesData?.data) {
			const investmentArray = Object?.keys(getInvestmentAggregatesData?.data?.result)?.map(key => {
				const item = getInvestmentAggregatesData?.data?.result[key];
				// Check if the current item has a totalInvestment property
				if (item && typeof item === 'object' && 'totalInvestment' in item) {
					return {
						bankName: key,
						amount: item?.totalInvestment
					};
				}
				// If the key is not an object or does not have totalInvestment, return null or skip
				return null;
			})?.filter(item => item !== null);

//			setAssetsList([...investmentArray, ...getBankAggregatesData?.data?.result?.banking?.breakup  ])
setAssetsList([...investmentArray,  ])

		}
	}, [getBankAggregatesIsLoading, getInvestmentAggregatesIsLoading, getBankAggregatesData?.data, getInvestmentAggregatesData?.data])


	const totalAmountAsset = assetsList?.reduce((sum, item) => sum + parseFloat(item?.amount), 0)

	function formatPriceNumber(number) {
		if (number >= 10000000) { // Check for crore
			return (number / 10000000)?.toFixed(1)?.replace(/\.0$/, '') + ' Cr';
		}
		if (number >= 100000) { // Check for lakh
			return (number / 100000)?.toFixed(1)?.replace(/\.0$/, '') + ' Lac';
		}
		if (number >= 1000) { // Check for thousand
			return (number / 1000)?.toFixed(1)?.replace(/\.0$/, '') + 'k';
		}
		return number?.toString(); // Return the original number as string
	}

	return (<>
		<div className="row row-inner mb-3">
			<div className="col-md-12">
				<h1 className="h3 inter-700">Dashboard</h1>
			</div>
		</div>
		<div className="row row-inner g-4 g-xxxl-5 mb-4 pb-3">
			<div className="col-md-6 col-lg-4">
				<div class="card card-listing">
					<div class="card-header py-3 px-sm-4">
						<small>Assets</small>
						<h5 className="mt-2 mb-0 inter-700">₹ {formatNumberWithCommas(totalAmountAsset)}</h5>
					</div>
					<div class="card-body py-0 px-sm-4">
						<ul class="list-group list-group-flush">
							{/* <li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-orange-10">
										<FinIcon name="car" className="text-orange" />
									</span>
									<span>My Car</span>
								</div>
								<strong>₹5L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-blue-10">
										<FinIcon name="linechart" className="text-blue" />
									</span>
									<span>Bank Bal. 4342</span>
								</div>
								<strong>₹10L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-purple-10">
										<FinIcon name="planning" className="text-purple" />
									</span>
									<span>Savings</span>
								</div>
								<strong>₹12L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-blue-10">
										<FinIcon name="linechart" className="text-blue" />
									</span>
									<span>Bank Bal. 5463</span>
								</div>
								<strong>₹15L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-orange-10">
										<FinIcon name="car" className="text-orange" />
									</span>
									<span>Spouse's Car</span>
								</div>
								<strong>₹12L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-purple-10">
										<FinIcon name="planning" className="text-purple" />
									</span>
									<span>Savings</span>
								</div>
								<strong>₹11L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-blue-10">
										<FinIcon name="linechart" className="text-blue" />
									</span>
									<span>Bank Bal. 3455</span>
								</div>
								<strong>₹15L</strong>
							</li> */}

							{getBankAggregatesIsLoading || getBankAggregatesFetching ?
								<div className="d-block py-1 align-self-center" >
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								</div>

								: <>
									{
										assetsList && assetsList.length > 0 ? (
											assetsList?.map((item, index) => {
												// let iconString = item?.loanType.replace(/loan/i, '').trim()?.toLowerCase()
												const regex = /Bank/i;

												const hasBank = regex.test(item?.bankName);

												return <li key={index?.toString()} class="list-group-item">
													<div class="d-flex align-items-center gap-3">
														<span class="list-group-icon bg-orange-10">
															<FinIcon name={hasBank ? "planning" : "linechart"} className="text-purple" />
														</span>
														<span>{item?.bankName}</span>
													</div>
													<strong>₹ {formatPriceNumber(item?.amount)}</strong>
												</li>
											})) : (
											<li className="list-group-item text-center">No data found</li>
										)
									}

									{
										totalBanking && 
											<>
											<li key={totalBanking?.toString()} class="list-group-item">
												<div class="d-flex align-items-center gap-3">
													<span class="list-group-icon bg-orange-10">
														<FinIcon name="planning" className="text-purple" />
													</span>
													<span>Bank</span>
												</div>
												<strong>₹ {formatPriceNumber(totalBanking)}</strong>
											</li>
											</>
										
									}
								</>
							}
						</ul>
					</div>
				</div>
			</div>
			<div className="col-md-6 col-lg-4">
				<div class="card card-listing">
					<div class="card-header py-3 px-sm-4">
						<small>Liabilities</small>
						<h5 className="mt-2 mb-0 inter-700">₹ {formatNumberWithCommas(totalAmountLiabilities)}</h5>
					</div>
					<div class="card-body py-0 px-sm-4">
						<ul class="list-group list-group-flush">
							{/* <li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-pink-10">
										<FinIcon name="personaloan" className="text-pink" />
									</span>
									<span>Personal Loan</span>
								</div>
								<strong>₹10L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-orange-10">
										<FinIcon name="car" className="text-orange" />
									</span>
									<span>Car Loan</span>
								</div>
								<strong>₹11L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-green-10">
										<FinIcon name="education" className="text-green ts-1p1" />
									</span>
									<span>Education Loan</span>
								</div>
								<strong>₹10L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-orange-10">
										<FinIcon name="gold" className="text-orange" />
									</span>
									<span>Gold Loan</span>
								</div>
								<strong>₹12L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-red-10">
										<FinIcon name="bankcard" className="text-red" />
									</span>
									<span>Credit Card Debt</span>
								</div>
								<strong>₹13L</strong>
							</li>
							<li class="list-group-item">
								<div class="d-flex align-items-center gap-3">
									<span class="list-group-icon bg-blue-10">
										<FinIcon name="personaloan" className="text-blue" />
									</span>
									<span>Personal Loan</span>
								</div>
								<strong>₹15L</strong>
							</li> */}
							{getLoansIsLoading || getLoansIsFetching ?
								<div className="d-block py-1 align-self-center" >
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								</div>

								: <>
									{
										getLoansData?.data?.result?.listOfItems && getLoansData?.data?.result?.listOfItems.length > 0 ? (
											!!getLoansData?.data?.result?.listOfItems && getLoansData?.data?.result?.listOfItems?.map((item, index) => {
												let iconString = item?.loanType.replace(/loan/i, '').trim()?.toLowerCase()

												return <li key={index?.toString()} class="list-group-item">
													<div class="d-flex align-items-center gap-3">
														<span class="list-group-icon bg-blue-10">
															<FinIcon name={iconString === 'personal' ? `${iconString}oan` : iconString} className="text-blue" />
														</span>
														<span>{item?.loanType}</span>
													</div>
													<strong>₹ {formatPriceNumber(item?.principalOutstanding)}</strong>
												</li>
											})) : (
											<li className="list-group-item text-center">No data found</li>

										)

									}
								</>
							}
						</ul>
					</div>
				</div>
			</div>
			<div className="col-md-12 col-lg-4">
				<div className="d-flex pb-2">
					<div className="d-block">
						<select className="form-select py-0 ps-0 border-0 rounded-0 text-b100 fs-13px">
							<option>Net Worth</option>
							<option>1 Year</option>
							<option>2 Year</option>
							<option>3 Year</option>
							<option>5 Year</option>
						</select>
						<h5 className="mt-2 mb-0 inter-700">₹25L</h5>
					</div>
					<div className="ms-auto">
						<small className="text-b100 inter-500 text-uppercase fs-13px">All Time</small>
						<h5 className="text-success300 inter-600 text-nowrap mt-1">₹5.4L <small className="fs-14px">(20.55%)</small></h5>
					</div>
				</div>
				<DashboardAllTimeChart height="390" />
			</div>
		</div>




		<div className="row row-inner row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-5 g-4 mb-4 pb-3">
			<div className="col">
				<div className="card card-common h-100 shadow-sm rounded-4 p-3 py-md-4">
					<div className="d-flex justify-content-center align-items-center gap-2 mb-3">
						<h6 className="inter-600 m-0">Score Overall</h6>
					</div>
					<MeterChart score={sumProduct} color="#6DA1EF"></MeterChart>
				</div>
			</div>
			<div className="col">
				<div className="card card-common h-100 shadow-sm rounded-4 p-3 py-md-4">
					<div className="d-flex justify-content-center align-items-center gap-2 mb-3">
						<FinIcon name="investment" className="text-cyan ts-1p2" />
						<h6 className="inter-600 m-0">Investment</h6>
					</div>
					<MeterChart score={investmentScoreData?.data?.result.score} color="#8EEDE2"></MeterChart>
				</div>
			</div>


			{
				planningScoreIsLoading || planningScoreIsFetching ? <div className="justify-content-center align-content-center">
					<div className="spinner-border spinner-border-sm text-center" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div> :
					<div className="col">
						<div className="card card-common h-100 shadow-sm rounded-4 p-3 py-md-4">
							<div className="d-flex justify-content-center align-items-center gap-2 mb-3">
								<FinIcon name="planning" className="text-purple ts-1p2" />
								<h6 className="inter-600 m-0">Planning</h6>
							</div>
							<MeterChart score={planningScoreData?.data?.result?.score} color="#B0B2F6"></MeterChart>
						</div>
					</div>

			}



			{
				insuranceScoreIsLoading || insuranceScoreIsFetching ? <div className="justify-content-center align-content-center">
					<div className="spinner-border spinner-border-sm text-center" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div> :
					<div className="col">
						<div className="card card-common h-100 shadow-sm rounded-4 p-3 py-md-4">
							<div className="d-flex justify-content-center align-items-center gap-2 mb-3">
								<FinIcon name="insurance" className="text-pink ts-1p2" />
								<h6 className="inter-600 m-0">Insurance</h6>
							</div>
							<MeterChart score={(insuranceScoreData?.data?.result?.score).toFixed(0)} color="#FF9BCD"></MeterChart>
						</div>
					</div>
			}
			{
				loanScoreIsLoading || loanScoreIsFetching ? <div className="justify-content-center align-content-center">
					<div className="spinner-border spinner-border-sm text-center" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div> :


					<div className="col">
						<div className="card card-common h-100 shadow-sm rounded-4 p-3 py-md-4">
							<div className="d-flex justify-content-center align-items-center gap-2 mb-3">
								<FinIcon name="currency" className="text-yellow ts-1p2" />
								<h6 className="inter-600 m-0">Debt</h6>
							</div>
							<MeterChart score={loanScoreData?.data?.result.score} color="#F4C678"></MeterChart>
						</div>
					</div>
			}


		</div>

		<div className="row row-inner mb-4">
			<div className="col-md-6 mb-3">
				<div className="card card-common h-100">
					<div className="card-header bg-white">
						<h4 className="card-title m-0">Progress of Goals</h4>
					</div>
					<div className="card-body py-4">
						<div className="goals-progressbar h-100">
							{planningGoalsIsLoading || planningGoalsIsFetching ?

								<div className="d-block py-1 align-self-center" >
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								</div>

								: <>
									{planningGoalsData?.data?.result && planningGoalsData?.data?.result.length > 0 ? (
										!!planningGoalsData?.data?.result && planningGoalsData?.data?.result?.map((item, index) => {
											//  const color = colors[index % colors.length];
											let color;
											const percentage = Math.round((item.achieved / item.amount) * 100);

											if (percentage > 70) {
												color = 'green';
											} else if (percentage < 30) {
												color = 'red';
											} else {
												color = 'yellow';
											}

											return (
												<div key={index?.toString()} className="d-block py-1">
													{color === 'red' ? <div className="d-block goals-progressbar">
														<div className="progress progress-expenses" role="progressbar" aria-label="Success example" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
															<div className="progress-bar" style={{ "width": `${percentage}%` }}>{percentage}%</div>
														</div>
													</div> : color === 'green' ? <div className="d-block goals-progressbar">
														<div className="progress progress-savings" role="progressbar" aria-label="Success example" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
															<div className="progress-bar" style={{ "width": `${percentage}%` }}>{percentage}%</div>
														</div>
													</div> : <div className="d-block goals-progressbar">
														<div className="progress progress-debt" role="progressbar" aria-label="Success example" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
															<div className="progress-bar" style={{ "width": `${percentage}%` }}>{percentage}%</div>
														</div>
													</div>}
													<h6 className="mt-2 small">{item?.name}<small className="text-primary ms-2"> {((item.achieved / item.amount) * 100).toFixed(2)} % of {item.amount}</small></h6>
												</div>
											)
										})) : (
										<div>No data available</div>
									)

									}
								</>
							}
							{/* <div className="d-block py-1">
								<div className="progress progress-savings" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
									<div className="progress-bar" style={{ "width": "60%" }}>60%</div>
								</div>
								<h6 className="mt-2 small">Marriage <small className="text-primary ms-2">60% of 5L</small></h6>
							</div>
							<div className="d-block py-1">
								<div className="progress progress-expenses" role="progressbar" aria-label="Info example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
									<div className="progress-bar" style={{ "width": "33%" }}>33%</div>
								</div>
								<h6 className="mt-2 small">Education <small className="text-primary ms-2">33% of 3L</small></h6>
							</div>
							<div className="d-block py-1">
								<div className="progress progress-debt" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
									<div className="progress-bar" style={{ "width": "50%" }}>50%</div>
								</div>
								<h6 className="mt-2 mb-0 small">Travel <small className="text-primary ms-2">50% of 1L</small></h6>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-6 mb-3">
				<div className="card card-common h-100">
					<div className="card-header bg-white d-flex align-items-center">
						<h4 className="card-title m-0">Available  Funds</h4>
						<span className="h5 m-0 ms-auto text-b200">₹{formatNumberWithCommas(getBankAggregatesData?.data?.result?.banking?.total)}</span>
					</div>
					<div className="card-body">
						<span className="bg-blue50 py-2 px-3 rounded-1 text-b100 small inter-500">
							Required Emergency Fund : <span className="text-b200">₹{formatNumberWithCommas(emergencyFundData?.data?.result?.amount)}</span>
						</span>

						<div className="col mt-3">

							<div className="card card-common h-100 shadow-sm rounded-4 p-3 py-md-4">
								<div className="d-flex justify-content-center align-items-center gap-2 mb-3">
									<h6 className="inter-600 m-0">Available Fund</h6>
								</div>
								<MeterChart score={Math.round(score)} color="#6DA1EF"></MeterChart>
							</div>

						</div>


						{getBankAggregatesData?.data?.result?.banking?.total < emergencyFundData?.data?.result?.amount ? (
							<p className="pt-1 mb-0 inter-500 text-center">
								Boost your emergency fund by ₹{formatPriceNumber(getBankAggregatesData?.data?.result?.banking?.total - emergencyFundData?.data?.result?.amount)}, it's currently at ₹{getBankAggregatesData?.data?.result?.banking?.total}
							</p>
						) : (
							<p className="pt-1 mb-0 inter-500 text-center">
								Your emergency fund balance is more than the target.
							</p>
						)}



						{/* <TimelineChart height="240" width="100%"></TimelineChart> */}
						{/* <p className="pt-1 mb-0 inter-500 text-center">Boost your emergency fund by ₹10k, it's currently at ₹20k</p> */}
					</div>
				</div>
			</div>
		</div>

		<div className="row row-inner">
			<div className="col-12">
				<h1 className="h3 inter-700">Annual Budget</h1>
			</div>
			<div className="col-12 mt-1">
				<div className="p-3 p-lg-4 rounded-3 border border-common">
					<div className="annual-budgetbox">
						<div className="annual-incomebox">
							<small>Annual Income</small>
							<h6 className="inter-700">₹{planningExpensesData?.data?.result?.CREDIT?.total}</h6>
						</div>
						<div className="ab-divider"></div>
						<div className="sed-box">
							<div className="savings-box">
								<div className="sed-lines">Savings</div>
								<div className="sed-badge inter-700">₹{formatPriceNumber(planningSavingsData?.data?.result?.total)}</div>
								<div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4">
									{
										planningSavingsIsLoading || planningSavingsIsFetching ? <div className="justify-content-center align-content-center">
											<div className="spinner-border spinner-border-sm text-center" role="status">
												<span className="visually-hidden">Loading...</span>
											</div>
										</div> : planningExpensesData?.data?.result?.aggregates && Object.keys(planningExpensesData?.data?.result?.aggregates).length > 0 ? Object?.keys(planningSavingsData?.data?.result?.aggregates)?.map((key, index) => (

											<div key={index?.toString()} className="col">
												<div className="ab-linebox ab-success">
													<small>{key}</small>
													<h6>₹{planningSavingsData?.data?.result?.aggregates[key]}</h6>
												</div>
											</div>
										)) : (
											<div>No data available</div>

										)

									}
								</div>
							</div>
							<div className="expenses-box">
								<div className="sed-lines">Expenses</div>
								<div className="sed-badge inter-700">₹{formatPriceNumber(planningExpensesData?.data?.result?.DEBIT?.total)}</div>
								<div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4">
									{
										planningExpensesIsLoading || planningExpensesIsFetching ? <div className="justify-content-center align-content-center">
											<div className="spinner-border spinner-border-sm" role="status">
												<span className="visually-hidden">Loading...</span>
											</div>
										</div> : planningExpensesData?.data?.result?.DEBIT?.aggregates && Object.keys(planningExpensesData?.data?.result?.DEBIT?.aggregates).length > 0 ? (Object?.keys(planningExpensesData?.data?.result?.DEBIT?.aggregates)?.map((key, index) => (

											<div key={index?.toString()} className="col">
												<div className="ab-linebox ab-error">
													<small>{key}</small>
													<h6>₹{planningExpensesData?.data?.result?.DEBIT?.aggregates[key]}</h6>
												</div>
											</div>
										))) : (
											<div>No data available</div>
										)
									}
								</div>
							</div>
							<div className="debt-box">
								<div className="sed-lines">Debt Paydowns</div>
								<div className="sed-badge inter-700">₹ {formatPriceNumber(planningExpensesData?.data?.result?.LOAN?.total)} </div>
								<div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4">


									{planningExpensesIsLoading || planningExpensesIsFetching ? <div className="justify-content-center align-content-center">
										<div className="spinner-border spinner-border-sm" role="status">
											<span className="visually-hidden">Loading...</span>
										</div>
									</div> : planningExpensesData?.data?.result?.LOAN?.aggregates && Object.keys(planningExpensesData?.data?.result?.DEBIT?.aggregates).length > 0 ? Object?.keys(planningExpensesData?.data?.result?.LOAN?.aggregates)?.map((key) => (

										<div className="col">
											<div key={key} className="ab-linebox ab-error">
												<small>{key}</small>
												<h6>₹{planningExpensesData?.data?.result?.LOAN?.aggregates[key]}</h6>
											</div>
										</div>
									)) : (
										<div>No data available</div>
									)
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>)
}
export default Dashboard;
