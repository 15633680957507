import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FinIcon } from '../components/Common';
import { useFormik } from "formik";
import * as yup from "yup";
import usePutUpdateInsurance from "../hooks/insurance/put-insurance";

const EditPersonalInformation = () => {
    const location = useLocation();
    const item = location.state?.item; 
    const navigate = useNavigate();
    const useUpdateInsurance = usePutUpdateInsurance();

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const editInsuranceSchema = yup.object().shape({
        policyName: yup.string().required("Policy Name is required"),
        sumAssured: yup.string().required("Sum Assured is required"),
        premiumAmount: yup.string().matches(/^\d+$/, "Premium Amount must be a numeric string").required("Premium is required"),
        premiumFrequency: yup.string().oneOf(['Monthly', 'Quarterly', 'Semi-Annually', 'Annually'], 'Invalid premium frequency').required('Premium frequency is required'),
        premiumDate: yup.date().required("Premium Date is required"),
        maturityDate: yup.date().required("Maturity Date is required"),
        ownership: yup.string().min(3,"Ownership / Nominee should be at least 3 characters").required("Ownership / Nominee is required"),
        investmentLink: yup
        .string()
        .oneOf(['Yes', 'No'], 'Invalid selection for Investment Link')
        .required('Investment Link selection is required'),
        riderType: yup.string().required("Rider Type is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: item?.id || "",
            policyName: item?.policyName || "",
            sumAssured: item?.sumAssured || "",
            premiumAmount: item?.premiumAmnt !== undefined && item?.premiumAmnt !== null ? String(item?.premiumAmnt) : "",
            premiumFrequency: item?.premiumFreq || "",
            premiumDate: formatDate(item?.premiumDate || ""),
            maturityDate: formatDate(item?.maturityDate || ""),
            ownership: item?.ownership || "",
            investmentLink: item?.investmentLink ? "Yes" : "No",
            riderType: item?.riderType || "",
        },
        validationSchema: editInsuranceSchema,
        onSubmit: (values) => {
            const payload = {
                policyName: values.policyName,
                sumAssured: Number(values.sumAssured), 
                premiumAmount: values.premiumAmount !== "" ? Number(values.premiumAmount) : null, 
                premiumDate: values.premiumDate,
                maturityDate: values.maturityDate,
                ownership: values.ownership,
                insuranceFiType: values.investmentLink === "Yes", 
                riderType: values.riderType
            };

            console.log("payloadff", payload);

            const stateDate = {
                payload: payload,
                insuranceId: values.id
            };

            useUpdateInsurance.mutate(stateDate, {
                onSuccess: (data) => {
                    console.log("Updated Successfully", data);
                    formik.resetForm();
                    navigate('/insurance');
                },
                onError: (error) => {
                    console.error('Error updating insurance:', error);
                    
                },
            });
        },
    });
    

    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Edit Insurance</h3>
            </div>
            <div className="col-auto">
                <Link to="/insurance" type="button" className="btn btn-normal btn-light">
                    <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                </Link>
            </div>
            <div className="col-12 mt-1">
                <p className="text-b200 small">Edit insurance basic details.</p>
            </div>

            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                            <div className="col">
                                <label htmlFor="policyName" className="small inter-500 text-b200 mb-2">Policy Name</label>
                                <input
                                    type="text"
                                    id="policyName"
                                    name="policyName"
                                    onChange={formik.handleChange}
                                    value={formik.values.policyName}
                                    onBlur={formik.handleBlur}
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="Enter Policy Name"
                                />
                                {formik.errors.policyName && formik.touched.policyName && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.policyName}</p>
                                )}
                            </div>
                            <div className="col">
                                <label htmlFor="sumAssured" className="small inter-500 text-b200 mb-2">Sum Assured</label>
                                <input
                                    type="text"
                                    id="sumAssured"
                                    name="sumAssured"
                                    onChange={formik.handleChange}
                                    value={formik.values.sumAssured}
                                    onBlur={formik.handleBlur}
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="₹"
                                />
                                {formik.errors.sumAssured && formik.touched.sumAssured && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.sumAssured}</p>
                                )}
                            </div>
                            <div className="col">
                                <label htmlFor="premiumAmount" className="small inter-500 text-b200 mb-2">Premium Amount</label>
                                <input
                                    type="number"
                                    id="premiumAmount"
                                    name="premiumAmount"
                                    onChange={formik.handleChange}
                                    value={formik.values.premiumAmount}
                                    onBlur={formik.handleBlur}
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="₹"
                                />
                                {formik.errors.premiumAmount && formik.touched.premiumAmount && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.premiumAmount}</p>
                                )}
                            </div>
                            <div className="col">
                                <label htmlFor="premiumFrequency" className="small inter-500 text-b200 mb-2">Premium Frequency</label>
                                <select
                                    id="premiumFrequency"
                                    name="premiumFrequency"
                                    onChange={formik.handleChange}
                                    value={formik.values.premiumFrequency}
                                    onBlur={formik.handleBlur}
                                    className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                >
                                    <option value="" label="Select frequency" />
                                    <option value="Monthly" label="Monthly" />
                                    <option value="Quarterly" label="Quarterly" />
                                    <option value="Semi-Annually" label="Semi-Annually" />
                                    <option value="Annually" label="Annually" />
                                </select>
                                {formik.errors.premiumFrequency && formik.touched.premiumFrequency && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.premiumFrequency}</p>
                                )}
                            </div>
                            <div className="col">
                                <label htmlFor="premiumDate" className="small inter-500 text-b200 mb-2">Premium Date</label>
                                <input
                                    type="date"
                                    id="premiumDate"
                                    name="premiumDate"
                                    onChange={formik.handleChange}
                                    value={formik.values.premiumDate}
                                    onBlur={formik.handleBlur}
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                />
                                {formik.errors.premiumDate && formik.touched.premiumDate && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.premiumDate}</p>
                                )}
                            </div>
                            <div className="col">
                                <label htmlFor="maturityDate" className="small inter-500 text-b200 mb-2">Maturity Date</label>
                                <input
                                    type="date"
                                    id="maturityDate"
                                    name="maturityDate"
                                    onChange={formik.handleChange}
                                    value={formik.values.maturityDate}
                                    onBlur={formik.handleBlur}
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                />
                                {formik.errors.maturityDate && formik.touched.maturityDate && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.maturityDate}</p>
                                )}
                            </div>
                            <div className="col">
    <label htmlFor="ownership" className="small inter-500 text-b200 mb-2">Ownership / Nominee</label>
    <input
        type="text"
        id="ownership"
        name="ownership"
        onChange={formik.handleChange}
        value={formik.values.ownership}
        onBlur={formik.handleBlur}
        className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
        placeholder="Enter Ownership or Nominee details"
    />
    {formik.errors.ownership && formik.touched.ownership && (
        <p className="fs-14px text-danger inter-500 my-1">{formik.errors.ownership}</p>
    )}
</div>

<div className="col">
    <label htmlFor="investmentLink" className="small inter-500 text-b200 mb-2">Investment Link</label>
    <select
        id="investmentLink"
        name="investmentLink"
        onChange={formik.handleChange}
        value={formik.values.investmentLink}
        onBlur={formik.handleBlur}
        className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
    >
        <option value="Yes">Yes</option>
        <option value="No">No</option>
    </select>
    {formik.errors.investmentLink && formik.touched.investmentLink && (
        <p className="fs-14px text-danger inter-500 my-1">{formik.errors.investmentLink}</p>
    )}
</div>

                            <div className="col">
                                <label htmlFor="riderType" className="small inter-500 text-b200 mb-2">Rider Type</label>
                                <input
                                    type="text"
                                    id="riderType"
                                    name="riderType"
                                    onChange={formik.handleChange}
                                    value={formik.values.riderType}
                                    onBlur={formik.handleBlur}
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="Enter Rider Type"
                                />
                                {formik.errors.riderType && formik.touched.riderType && (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.riderType}</p>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-start mt-4">
                            <button
                                type="submit"
                                className="btn btn-primary me-2"
                                disabled={!formik.isValid || formik.isSubmitting}
                            >
                                {formik.isSubmitting ? 'Saving...' : 'Save Edit Insurance'}
                            </button>
                            <Link to="/insurance" type="button" className="btn btn-light">Cancel</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditPersonalInformation;
