import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { FinIcon } from '../components/Common';
import UpdatePlanningGoal from "../hooks/planning/put-planning-goals";

const PlanningGoalsEdit = () => {
    const location = useLocation();
    const item = location.state?.item;
    const goal = location.state?.goal;
    const goalId = item?.goalId || goal?.goalId;

    const useUpdatePlanningGoal = UpdatePlanningGoal();
    const navigate = useNavigate();

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };



    const itemValidationSchema = yup.object().shape({
        name: yup.string().required("Name is required"),
        amount: yup.number().required("Amount is required"),
        goalDate: yup.date().required("Goal Date is required"),
        forwhom: yup.string().optional(),
        Projection: yup.date().required("Projection date is required"),
        achieved: yup.number().required("Achieved value is required"),
    });

    const goalValidationSchema = yup.object().shape({
        name: yup.string().required("Name is required"),
        amount: yup.number().required("Amount is required"),
        goalDate: yup.date().required("Goal Date is required"),
    });


    // Choose the appropriate validation schema
    const validationSchema = goal ? goalValidationSchema : itemValidationSchema;

    // Initialize Formik with conditional initial values

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: goal
            ? {
                goalId: goal?.goalId || "",
                name: goal.name || "",
                amount: goal.amount || "",
                goalDate: formatDate(goal.goalDate || ""),
            }
            : {
                goalId: item?.goalId || "",
                name: item.name || "",
                amount: item.amount || "",
                goalDate: formatDate(item.goalDate || ""),
                forwhom: item.forwhom || "",
                Projection: formatDate(item.projectedAchievedBy || ""),
                achieved: item.achieved || 0,
            },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // Construct payload based on type

            const payload = goal
                ? {

                    name: values.name,
                    amount: Number(values.amount),
                    goalDate: values.goalDate,
                }
                : {

                    name: values.name,
                    amount: Number(values.amount),
                    goalDate: values.goalDate,
                    // forwhom: values.forwhom,
                    // Projection: values.Projection,
                    // achieved: Number(values.achieved),
                };

            console.log("Payload being sent:", payload);
            const sendData = {
                payload: payload, goalId: values.goalId
            }
            console.log(sendData)

            // Submit based on type
            useUpdatePlanningGoal.mutate(sendData, {
                onSuccess: (data) => {
                    console.log(`${goal ? "Goal" : "Item"} updated successfully`, data);
                    formik.resetForm();
                    navigate(goal ? '/investment' : '/planning');
                },
                onError: (error) => {
                    console.error(`Error updating ${goal ? "goal" : "item"}:`, error);
                    alert(`Failed to update ${goal ? "goal" : "item"}. Please try again.`);
                },
            }
        );
        },
    });

    return (
        <>
            <div className="row row-inner">
                <div className="col align-self-center">
                    <h3 className="inter-700">Update Planning Goal</h3>
                    <p className="text-b200 small">Update Planning Goal basic details.</p>
                </div>
                <div className="col-auto">
                    <Link to={goal ? '/investment' : '/planning'} type="button" className="btn btn-normal btn-light">
                        <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                    </Link>
                </div>

                <div className="col-12 mt-1 mt-xl-2 mb-4">
                    <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">

                                {/* Common Fields for Both Goal and Item */}
                                <div className="col">
                                    <label htmlFor="name" className="small inter-500 text-b200 mb-2">Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                        placeholder="Enter Name"
                                        id="name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.name && formik.touched.name && (
                                        <p className="fs-14px text-danger inter-500 my-1">{formik.errors.name}</p>
                                    )}
                                </div>

                                <div className="col">
                                    <label htmlFor="amount" className="small inter-500 text-b200 mb-2">Amount (₹)</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                        placeholder="00"
                                        id="amount"
                                        name="amount"
                                        onChange={formik.handleChange}
                                        value={formik.values.amount}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.amount && formik.touched.amount && (
                                        <p className="fs-14px text-danger inter-500 my-1">{formik.errors.amount}</p>
                                    )}
                                </div>

                                <div className="col">
                                    <label htmlFor="goalDate" className="small inter-500 text-b200 mb-2">Goal Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                        id="goalDate"
                                        name="goalDate"
                                        onChange={formik.handleChange}
                                        value={formik.values.goalDate}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.goalDate && formik.touched.goalDate && (
                                        <p className="fs-14px text-danger inter-500 my-1">{formik.errors.goalDate}</p>
                                    )}
                                </div>

                                {/* Additional Fields for Item */}
                                {!goal && (
                                    <>
                                        <div className="col">
                                            <label htmlFor="forwhom" className="small inter-500 text-b200 mb-2">For Whom</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                                placeholder="Enter For Whom"
                                                id="forwhom"
                                                name="forwhom"
                                                onChange={formik.handleChange}
                                                value={formik.values.forwhom}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.errors.forwhom && formik.touched.forwhom && (
                                                <p className="fs-14px text-danger inter-500 my-1">{formik.errors.forwhom}</p>
                                            )}
                                        </div>

                                        <div className="col">
                                            <label htmlFor="Projection" className="small inter-500 text-b200 mb-2">Projection</label>
                                            <input
                                                type="date"
                                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                                id="Projection"
                                                name="Projection"
                                                onChange={formik.handleChange}
                                                value={formik.values.Projection}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.errors.Projection && formik.touched.Projection && (
                                                <p className="fs-14px text-danger inter-500 my-1">{formik.errors.Projection}</p>
                                            )}
                                        </div>

                                        <div className="col">
                                            <label htmlFor="achieved" className="small inter-500 text-b200 mb-2">Achieved</label>
                                            <input
                                                type="number"
                                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                                placeholder="Enter Achieved"
                                                id="achieved"
                                                name="achieved"
                                                onChange={formik.handleChange}
                                                value={formik.values.achieved}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.errors.achieved && formik.touched.achieved && (
                                                <p className="fs-14px text-danger inter-500 my-1">{formik.errors.achieved}</p>
                                            )}
                                        </div>
                                    </>
                                )}


                                <div className="col-12 ">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4"
                                        disabled={!formik.isValid || formik.isSubmitting}
                                    >
                                        <FinIcon name="save" className="ts-1p5 me-3" />
                                        Update {goal ? "Goal" : "Item"}
                                    </button>
                                </div>


                            </div>




                        </form>

                    </div>

                </div>
            </div>
        </>
    );
};

export default PlanningGoalsEdit;
