import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import usePostDebt from "../hooks/debt/post-debt";
import { FinIcon } from '../components/Common';
import useGetLenders from "../hooks/debt/get-lenders";
import * as yup from "yup";
import useEditDbt from "../hooks/debt/edit-debt";

const DebtEdit = () => {
    const { data: getLendersData, isLoading: getLendersIsLoading } = useGetLenders();
    const location = useLocation();
    const item = location.state?.item;
    const navigate = useNavigate();

    const { mutate: postDebt } = usePostDebt();
    const { mutate } = useEditDbt();

    const [other, setOther] = useState(!!item?.otherLender);
    const [otherLenderValue, setOtherLenderValue] = useState(item?.otherLender || '');
    const [interestRate, setInterestRate] = useState(item?.interestRate || '');

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const editDebtSchema = yup.object().shape({
        loanType: yup.string().required('Please select loan type.'),
        lender: yup.string().required('Please select lender.'),
        amount: yup.number().required('Please enter integer value.'),
        emi: yup.number().required('Please enter integer value.'),
        interestRate: yup.number()
            .required('Please enter integer value.')
            .min(0, 'Interest rate must be greater than or equal to 0.')
            .max(30, 'Interest rate is abnormally high, please review it.'),
        loanStartDate: yup.date().required('Please select loan start date.'),
        dueDate: yup.date().required('Please select due date.'),
        principalOutstanding: yup.number().required('Please enter integer value.'),
        tenureInMonths: yup.number().required('Please select tenure.'),
    });

    const formik = useFormik({
        initialValues: {
            id: item?.id || "",
            loanType: item?.loanType || "",
            lender: item?.lender?.name || ("Other"),
            amount: item?.amount || "",
            emi: item?.emi || "",
            otherLender: item?.otherLender || '',
            interestRate: item?.interestRate || "",
            loanStartDate: formatDate(item?.loanStartDate),
            dueDate: formatDate(item?.dueDate),
            principalOutstanding: item?.principalOutstanding || "",
            tenureInMonths: item?.tenureInMonths || "",
        },
        validationSchema: editDebtSchema,
        onSubmit: (values) => {
            const payload = values.lender === 'Other' ? {
                loanType: values.loanType,
                otherLender: otherLenderValue,
                amount: Number(values.amount),
                emi: Number(values.emi),
                interestRate: Number(values.interestRate),
                loanStartDate: values.loanStartDate,
                dueDate: values.dueDate,
                principalOutstanding: Number(values.principalOutstanding),
                tenureInMonths: Number(values.tenureInMonths),
            } : {
                loanType: values.loanType,
                lenderId: getLendersData?.data?.result?.find(l => l.name === values.lender)?.id,
                amount: Number(values.amount),
                emi: Number(values.emi),
                interestRate: Number(values.interestRate),
                loanStartDate: values.loanStartDate,
                dueDate: values.dueDate,
                principalOutstanding: Number(values.principalOutstanding),
                tenureInMonths: Number(values.tenureInMonths),
            };

            const dataToSend = {
                payload,
                loanId: values.id,
            };

            mutate(dataToSend, {
                onSuccess: (data) => {
                    formik.resetForm();
                    navigate('/debt');
                },
                onError: (error) => {
                    console.error('Submission error:', error.response?.data || error.message);
                },
            });
        },
    });

    const handleLenderChange = (e) => {
        const value = e.target.value;
        formik.setFieldValue('lender', value);
        setOther(value === 'Other');
    };

    const handleOtherLenderChange = (e) => {
        const value = e.target.value;
        setOtherLenderValue(value);
        formik.setFieldValue('otherLender', value);
    };

    const calculateInterestRate = () => {
        const P = Number(formik.values.amount);
        const EMI = Number(formik.values.emi);
        const n = Number(formik.values.tenureInMonths);
    
        if (P && EMI && n) {
            let low = 0.0001;   
            let high = 1;
            let r = (low + high) / 2;
    
            while (high - low > 0.000001) { 
                const calcEMI = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
                if (calcEMI < EMI) {
                    low = r;
                } else {
                    high = r;
                }
                r = (low + high) / 2;
            }
    
            // Calculate the annual interest rate in percentage
            const annualRate = (r * 12 * 100).toFixed(2);
    
            // Set the interest rate in percentage
            setInterestRate(annualRate);
            formik.setFieldValue('interestRate', annualRate);
        }
    };
    
    useEffect(() => {
        if (formik.values.amount && formik.values.emi && formik.values.tenureInMonths) {
            calculateInterestRate();
        } else {
            setInterestRate(item?.interestRate || '');
        }
    }, [formik.values.amount, formik.values.emi, formik.values.tenureInMonths, item?.interestRate]);

    return (
        <>
            <div className="row row-inner">
                <div className="col align-self-center">
                    <h3 className="inter-700">Edit Debt</h3>
                    <p className="text-b200 small">Edit debt basic details.</p>
                </div>
                <div className="col-auto">
                    <Link to={'/debt'} type="button" className="btn btn-normal btn-light">
                        <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                    </Link>
                </div>

                <div className="col-12 mt-1 mt-xl-2 mb-4">
                    <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">
                            {/* Loan Type Field */}
                            <div className="col">
                                <label htmlFor="loanType" className="small inter-500 text-b200 mb-2">Loan Type</label>
                                <input
                                    type="text"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="Loan Type"
                                    id="loanType"
                                    name="loanType"
                                    value={formik.values.loanType}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.loanType && formik.touched.loanType ? (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.loanType}</p>
                                ) : null}
                            </div>
                            
                            {/* Lender */}
                            <div className="col">
                                <label htmlFor="lender" className="small inter-500 text-b200 mb-2">Lender</label>
                                <select
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    id="lender"
                                    name="lender"
                                    value={formik.values.lender}
                                    onChange={handleLenderChange}
                                    onBlur={formik.handleBlur}
                                >
                                    {getLendersData?.data?.result?.map(lender => (
                                        <option key={lender.id} value={lender.name}>{lender.name}</option>
                                    ))}
                                    <option value="Other">Other</option>
                                </select>
                                {formik.errors.lender && formik.touched.lender ? (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.lender}</p>
                                ) : null}
                            </div>

                            {/* Other Lender Field */}
                            {other && (
                                <div className="col">
                                    <label htmlFor="otherLender" className="small inter-500 text-b200 mb-2">Other Lender</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                        placeholder="Other Lender"
                                        id="otherLender"
                                        name="otherLender"
                                        value={otherLenderValue}
                                        onChange={handleOtherLenderChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.otherLender && formik.touched.otherLender ? (
                                        <p className="fs-14px text-danger inter-500 my-1">{formik.errors.otherLender}</p>
                                    ) : null}
                                </div>
                            )}

                            {/* Amount */}
                            <div className="col">
                                <label htmlFor="amount" className="small inter-500 text-b200 mb-2">Amount</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="Amount"
                                    id="amount"
                                    name="amount"
                                    value={formik.values.amount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.amount && formik.touched.amount ? (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.amount}</p>
                                ) : null}
                            </div>

                            {/* EMI */}
                            <div className="col">
                                <label htmlFor="emi" className="small inter-500 text-b200 mb-2">EMI</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="EMI"
                                    id="emi"
                                    name="emi"
                                    value={formik.values.emi}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.emi && formik.touched.emi ? (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.emi}</p>
                                ) : null}
                            </div>

                            {/* Tenure */}
                            <div className="col">
                                <label htmlFor="tenureInMonths" className="small inter-500 text-b200 mb-2">Tenure (in months)</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="Tenure"
                                    id="tenureInMonths"
                                    name="tenureInMonths"
                                    value={formik.values.tenureInMonths}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.tenureInMonths && formik.touched.tenureInMonths ? (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.tenureInMonths}</p>
                                ) : null}
                            </div>

                            {/* Interest Rate */}
                            <div className="col">
                                <label htmlFor="interestRate" className="small inter-500 text-b200 mb-2">Interest Rate (%)</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="Interest Rate"
                                    id="interestRate"
                                    name="interestRate"
                                    value={interestRate || formik.values.interestRate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    readOnly
                                />
                                {formik.errors.interestRate && formik.touched.interestRate ? (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.interestRate}</p>
                                ) : null}
                            </div>

                            {/* Loan Start Date */}
                            <div className="col">
                                <label htmlFor="loanStartDate" className="small inter-500 text-b200 mb-2">Loan Start Date</label>
                                <input
                                    type="date"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="Loan Start Date"
                                    id="loanStartDate"
                                    name="loanStartDate"
                                    value={formik.values.loanStartDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.loanStartDate && formik.touched.loanStartDate ? (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.loanStartDate}</p>
                                ) : null}
                            </div>

                            {/* Due Date */}
                            <div className="col">
                                <label htmlFor="dueDate" className="small inter-500 text-b200 mb-2">Due Date</label>
                                <input
                                    type="date"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="Due Date"
                                    id="dueDate"
                                    name="dueDate"
                                    value={formik.values.dueDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.dueDate && formik.touched.dueDate ? (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.dueDate}</p>
                                ) : null}
                            </div>

                            {/* Principal Outstanding */}
                            <div className="col">
                                <label htmlFor="principalOutstanding" className="small inter-500 text-b200 mb-2">Principal Outstanding</label>
                                <input
                                    type="number"
                                    className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                    placeholder="Principal Outstanding"
                                    id="principalOutstanding"
                                    name="principalOutstanding"
                                    value={formik.values.principalOutstanding}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.principalOutstanding && formik.touched.principalOutstanding ? (
                                    <p className="fs-14px text-danger inter-500 my-1">{formik.errors.principalOutstanding}</p>
                                ) : null}
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="mt-4">
                            <button
                                type="button"
                                onClick={formik.handleSubmit}
                                className="btn btn-primary btn-lg"
                                disabled={!formik.isValid || formik.isSubmitting}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DebtEdit;
