import React from "react";
import { FinIcon, AddBankingBack } from '../components/Common';
import useUpdateBankDetails from "../hooks/bank-services/put-bank-details";
import { useFormik } from "formik";
import { editBankAccountsSchema } from "../utils/validationSchema";
import { useLocation,Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const BankDetailsEdit = () => {
    const navigate =useNavigate()
    const location = useLocation()
    const item = location.state?.item
    console.log(item)
    //api call
    const useUpdateBank = useUpdateBankDetails()

    // Formik Inputs
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id : item?.id || "",
            accountNumber: item?.accountNumber || "",
            ifscCode: item?.ifscCode || '',
            branch: item?.branch || '',
            bankName: item?.bankName || '',
            currentBalance: item?.currentBalance || '',
            accountType: item?.accountType || '',
            holderName: item?.holderName || ''
        },
        validationSchema: editBankAccountsSchema,

        onSubmit: values => {
            console.log(values)

            const payload = {
                accountNumber: values.accountNumber,
                ifscCode: values.ifscCode,
                branch: values.branch,
                bankName: values.bankName,
                currentBalance: Number(values?.currentBalance),
                accountType: values?.accountType,
                holderName: values?.holderName
            }

            const saveDate = {
                payload:payload , id:values.id
            }

            useUpdateBank.mutate(saveDate, {
                onSuccess  : () => {
                    formik.resetForm()
                    navigate('/banking')
                },
            })
        }
    })

    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Update Bank Details</h3>
                <p className="text-b200 small">
                    Enter the Bank Account Number, select Account Type and Holder Type, and enter IFSC
                </p>
            </div>
            <div className="col-auto">
            <Link to={'/banking'} className="btn btn-normal btn-light">
                <FinIcon name="arrowleft2" className="ts-1p4"/> Back
            </Link>
            </div>

            {/* --- */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="bdBankName" className="small inter-500 text-b200 mb-2">Account Number</label>
                            <input type="text"
                                value={formik?.values?.accountNumber}
                                onBlur={formik.handleBlur('accountNumber')}
                                onInput={formik.handleChange('accountNumber')}
                                id="bdBankName" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Account Number" />
                            {(formik.errors.accountNumber && formik.touched.accountNumber) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.accountNumber}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="bdBankName" className="small inter-500 text-b200 mb-2">Holder Name</label>
                            <input type="text"
                                value={formik?.values?.holderName}
                                onBlur={formik.handleBlur('holderName')}
                                onInput={formik.handleChange('holderName')}
                                id="bdBankName" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Holder Name" />
                            {(formik.errors.holderName && formik.touched.holderName) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.holderName}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="bdBankName" className="small inter-500 text-b200 mb-2">Bank Name</label>
                            <input type="text"
                                value={formik?.values?.bankName}
                                onBlur={formik.handleBlur('bankName')}
                                onInput={formik.handleChange('bankName')}
                                id="bdBankName" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Bank Name" />
                            {(formik.errors.bankName && formik.touched.bankName) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.bankName}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="bdAccountType" className="small inter-500 text-b200 mb-2">Account Type</label>
                            <select
                                id="bdAccountType"
                                name="accountType"
                                value={formik.values.accountType}  
                                onChange={(e) => formik.setFieldValue('accountType', e.target.value)}
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                            >
                                <option value="">Select Account Type</option>
                                <option value="SAVING">Saving</option>
                                <option value="CURRENT">Current</option>
                                <option value="OTHER">Other</option>
                            </select>

                            {formik.errors.accountType && formik.touched.accountType ? (
                                <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                    {formik.errors.accountType}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="bdBranch" className="small inter-500 text-b200 mb-2">Branch</label>
                            <input type="text"
                                value={formik?.values?.branch}
                                onBlur={formik.handleBlur('branch')}
                                onInput={formik.handleChange('branch')} id="bdBranch" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter Branch" />
                            {(formik.errors.branch && formik.touched.branch) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.branch}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="bdIFSC" className="small inter-500 text-b200 mb-2">IFSC Code</label>
                            <input type="text"
                                value={formik?.values?.ifscCode}
                                onBlur={formik.handleBlur('ifscCode')}
                                onInput={formik.handleChange('ifscCode')}
                                id="bdIFSC" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="Enter IFSC" />
                            {(formik.errors.ifscCode && formik.touched.ifscCode) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.ifscCode}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="bdBalance" className="small inter-500 text-b200 mb-2">Balance</label>
                            <input type="text"
                                value={formik?.values?.currentBalance}
                                onBlur={formik.handleBlur('currentBalance')}
                                onInput={formik.handleChange('currentBalance')}
                                id="bdBalance" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="₹" />
                            {(formik.errors.currentBalance && formik.touched.currentBalance) ? <p className="er-paragraph text-danger font-weight-semi-bold mb-4">
                                {formik.errors.currentBalance}
                            </p> : null}
                        </div>
                    </div>
                </div>

                <button type="button" 
                // disabled={useAddBankAccountsMutation?.isPending}
                 onClick={formik.handleSubmit} className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4">
                    <FinIcon name="save" className="ts-1p5 me-3" />
                    Update Bank Details 
                     {/* {useAddBankAccountsMutation?.isPending && <span className="spinner-border spinner-border-sm mx-lg-1" role="status" aria-hidden="true"></span>} */}
                </button>
            </div>
        </div>
    )
}
export default BankDetailsEdit;