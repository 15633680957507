import React from "react";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { FinIcon } from "../components/Common";
import useGetPlanningGoals from "../hooks/planning/get-planning-goals";
import { format } from "date-fns";
import useGetPlanningEstimates from "../hooks/planning/get-planning-estimate";
import { useNavigate } from "react-router-dom";
import ProgressOfGoalsBarChart from '../charts/ProgressOfGoalsBarChart';
import useGetPlanningExpenses from "../hooks/planning/get-planning-expenses";
import useGetPlanningSavings from "../hooks/planning/get-planning-savings";

const Planning = () => {
    function formatNumberWithCommas(number) {
        return new Intl.NumberFormat('en-IN')?.format(number)?.toString();
    }
    function getPreviousFinancialYear() {
        const today = new Date();
        let currentYear = today.getFullYear();
        let startYear, endYear;

        // If the current date is after March (i.e. from April onwards), previous financial year started last year
        if (today.getMonth() >= 3) {  // Month is 0-indexed (0 = January, 3 = April)
            startYear = currentYear - 1;
            endYear = currentYear;
        } else {  // Before April, previous financial year started two years ago
            startYear = currentYear - 2;
            endYear = currentYear - 1;
        }

        return `${startYear}-${endYear}`;
    }
    // init 
    const date = new Date();
    const cyear = format(date, 'yyyy');
    const colors = ['red', 'green', 'yellow'];

    // api
    const { data: planningGoalsData, isLoading: planningGoalsIsLoading, isFetching: planningGoalsIsFetching } = useGetPlanningGoals()
    const { data: planningEstimatesData, isLoading: planningEstimatesIsLoading, isFetching: planningEstimatesIsFetching } = useGetPlanningEstimates(cyear)
    const { data: planningExpensesData, isLoading: planningExpensesIsLoading, isFetching: planningExpensesIsFetching } = useGetPlanningExpenses(cyear)
    const { data: planningSavingsData, isLoading: planningSavingsIsLoading, isFetching: planningSavingsIsFetching } = useGetPlanningSavings(cyear)
    // console.log(planningSavingsData?.data?.result?.aggregates);

    function formatPriceNumber(number) {
        if (number >= 10000000) { // Check for crore
            return (number / 10000000)?.toFixed(1)?.replace(/\.0$/, '') + ' Cr';
        }
        if (number >= 100000) { // Check for lakh
            return (number / 100000)?.toFixed(1)?.replace(/\.0$/, '') + ' Lac';
        }
        if (number >= 1000) { // Check for thousand
            return (number / 1000)?.toFixed(1)?.replace(/\.0$/, '') + 'k';
        }
        return number?.toString(); // Return the original number as string
    }
    const navigate = useNavigate()
    const editGoalHandler = (item) => {
        navigate("/planning-goal-edit", { state: { item } })
    }



    return (
        <>
            <div className="row row-inner">
                <div className="col">
                    <div className="d-flex gap-3">
                        <h3 className="inter-700">Planning</h3>
                        <div className="vr"></div>
                        <span className="h3 inter-500 m-0">Annual Budget ( {getPreviousFinancialYear()} ) </span>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="d-flex gap-2 gap-xxl-3">
                        <Link to={'/retirement-planner'} className="btn btn-normal btn-primary">
                            <FinIcon name="eye" className="ts-1p4" />
                            See Retirement Planner
                        </Link>
                        <div className="vr bg-b75"></div>
                        <button type="button" className="btn btn-normal btn-light">
                            <FinIcon name="export" className="ts-1p4" />
                            Export
                        </button>
                        <div className="dropdown">
                            <button type="button" className="btn btn-normal btn-primary" data-bs-toggle="dropdown" aria-expanded="false">
                                <FinIcon name="addcirlce" className="ts-1p4" />
                                Add
                            </button>
                            <ul className="dropdown-menu shadow border-0">
                                <li>
                                    <Link to={'/planning-add'} className="dropdown-item dropdown-item-common" >
                                        <FinIcon name="wallet" className="ts-1p5" />
                                        Annual Budget
                                    </Link>
                                    {/* <button type="button" className="dropdown-item dropdown-item-common" data-bs-toggle="offcanvas" data-bs-target="#annualbudgetOffcanvas" aria-controls="annualbudgetOffcanvas">
                                    <FinIcon name="wallet" className="ts-1p5"/>
                                    Annual Budget
                                </button> */}
                                </li>
                                <li><hr className="dropdown-divider my-1 opacity-50" /></li>
                                <li>
                                    <Link to={'/planning-goal-add'} className="dropdown-item dropdown-item-common">
                                        <FinIcon name="moneyhike" className="ts-1p4" />
                                        Goals
                                    </Link>
                                    {/* <button type="button" className="dropdown-item dropdown-item-common" data-bs-toggle="offcanvas" data-bs-target="#goalsOffcanvas" aria-controls="goalsOffcanvas">
                                    <FinIcon name="moneyhike" className="ts-1p4"/>
                                    Goals
                                </button> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            {/* ... */}
            <div className="row row-inner">
                <div className="col-md-12 mt-3">
                    <div className="p-3 p-lg-4 rounded-3 border border-common">
                        <div className="annual-budgetbox">
                            <div className="annual-incomebox">
                                <small>Annual Income</small>
                                <h6 className="inter-700">₹{planningExpensesData?.data?.result?.CREDIT?.total}</h6>
                            </div>
                            <div className="ab-divider"></div>
                            <div className="sed-box">
                                <div className="savings-box">
                                    <div className="sed-lines">Savings</div>
                                    <div className="sed-badge inter-700 text-center">₹{formatPriceNumber(planningSavingsData?.data?.result?.total)}</div>
                                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4">
                                        {
                                            planningSavingsIsLoading || planningSavingsIsFetching ? <div className="justify-content-center align-content-center">
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div> : planningExpensesData?.data?.result?.aggregates &&
                                                Object.keys(planningExpensesData?.data?.result?.aggregates).length > 0 ? Object?.keys(planningSavingsData?.data?.result?.aggregates)?.map((key) => (

                                                    <div key={key} className="col">
                                                        <div key={key} className="ab-linebox ab-success">
                                                            <small>{key}</small>
                                                            <h6>₹{planningSavingsData?.data?.result?.aggregates[key]}</h6>
                                                        </div>
                                                    </div>
                                                )) : (
                                                <div className="col d-flex justify-content-center align-items-center">
                                                    <div>No data available</div>
                                                </div>)

                                        }
                                    </div>
                                </div>
                                <div className="expenses-box">
                                    <div className="sed-lines">Expenses</div>
                                    <div className="sed-badge inter-700 text-center">₹{formatPriceNumber(planningExpensesData?.data?.result?.DEBIT?.total)}</div>
                                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4">

                                        {planningExpensesIsLoading || planningExpensesIsFetching ? <div className="justify-content-center align-content-center">
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div> : planningExpensesData?.data?.result?.DEBIT?.aggregates &&
                                            Object.keys(planningExpensesData?.data?.result?.DEBIT?.aggregates).length > 0 ? (Object?.keys(planningExpensesData?.data?.result?.DEBIT?.aggregates)?.map((key) => (

                                                <div className="col">
                                                    <div key={key} className="ab-linebox ab-error">
                                                        <small>{key}</small>
                                                        <h6>₹{planningExpensesData?.data?.result?.DEBIT?.aggregates[key]}</h6>
                                                    </div>
                                                </div>
                                            ))) : (
                                            <div>No data available</div> // Fallback if no data is found
                                        )
                                        }
                                        {/* <div className="col w100-onlg text-end align-self-end">
                                            <a href="#" className="btn btn-outline-primary text-nowrap rounded-3 py-1">View All</a>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="debt-box">
                                    <div className="sed-lines">Debt Paydowns</div>
                                    <div className="sed-badge inter-700 text-center">₹{formatPriceNumber(planningExpensesData?.data?.result?.LOAN?.total)}</div>
                                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4">

                                        {planningExpensesIsLoading || planningExpensesIsFetching ? <div className="justify-content-center align-content-center">
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div> : planningExpensesData?.data?.result?.LOAN?.aggregates &&
                                            Object.keys(planningExpensesData?.data?.result?.LOAN?.aggregates).length > 0 ? Object?.keys(planningExpensesData?.data?.result?.LOAN?.aggregates)?.map((key) => (

                                                <div className="col">
                                                    <div key={key} className="ab-linebox ab-error">
                                                        <small>{key}</small>
                                                        <h6>₹{planningExpensesData?.data?.result?.LOAN?.aggregates[key]}</h6>
                                                    </div>
                                                </div>
                                            )) : (
                                            <div>No data available</div> // Fallback if no data is found
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* ... */}
            <div className="row row-inner gy-3 my-4">
                <div className="col-md-12 mt-3">
                    <div className="d-flex gap-3 justify-content-between align-items-end">
                        <h3 className="inter-700 m-0">Goals</h3>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table-common">
                            <thead>
                                <tr>
                                    <th>Goal&nbsp;Name</th>
                                    <th>For&nbsp;Whom</th>
                                    <th>By&nbsp;When</th>
                                    <th>Budget</th>
                                    <th width="140">Achived</th>
                                    <th width="140">Projection </th>
                                    <th>Progress of Goal</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {planningGoalsIsLoading || planningGoalsIsFetching ?
                                    <tr key="loading">
                                        <td colSpan="5" className="text-center">
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                    : <>
                                        {planningGoalsData?.data?.result && planningGoalsData?.data?.result.length > 0 ? (

                                            !!planningGoalsData?.data?.result && planningGoalsData?.data?.result?.map((item, index) => {
                                                //  const color = colors[index % colors.length];
                                                let color;
                                                const percentage = Math.round((item.achieved / item.amount) * 100);
                                                if (percentage > 70) {
                                                    color = 'green';
                                                } else if (percentage < 30) {
                                                    color = 'red';
                                                } else {
                                                    color = 'yellow';
                                                }
                                                console.log({ color });
                                                return (
                                                    <tr key={index?.toString()}>
                                                        <td><span className="inter-600">{item?.name}</span></td>
                                                        <td>{!!item?.purpose ? item?.purpose : 'N/A'}</td>
                                                        <td className="text-nowrap">{format(item?.goalDate, 'dd MMM yyyy')}</td>
                                                        <td>₹{formatNumberWithCommas(item?.amount)}</td>
                                                        <td>₹{formatNumberWithCommas(item?.achieved)}</td>
                                                        <td>{!!item?.projectedAchievedBy ? format(item?.projectedAchievedBy, 'dd MMM yyyy') : 'N/A'}</td>

                                                        <td>
                                                            {color === 'red' ? <div className="d-block goals-progressbar">
                                                                <div className="progress progress-expenses" role="progressbar" aria-label="Success example" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
                                                                    <div className="progress-bar" style={{ "width": `${percentage}%` }}>{percentage}%</div>
                                                                </div>
                                                                {/* <h6 className="mt-1 small">Savings</h6> */}
                                                            </div> : color === 'green' ? <div className="d-block goals-progressbar">
                                                                <div className="progress progress-savings" role="progressbar" aria-label="Success example" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
                                                                    <div className="progress-bar" style={{ "width": `${percentage}%` }}>{percentage}%</div>
                                                                </div>
                                                                {/* <h6 className="mt-1 small">Savings</h6> */}
                                                            </div> : <div className="d-block goals-progressbar">
                                                                <div className="progress progress-debt" role="progressbar" aria-label="Success example" aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
                                                                    <div className="progress-bar" style={{ "width": `${percentage}%` }}>{percentage}%</div>
                                                                </div>

                                                                {/* <h6 className="mt-1 small">Savings</h6> */}
                                                            </div>}
                                                            <h6 className="mt-2 small">{item?.name}<small className="text-primary ms-2"> {((item.achieved / item.amount) * 100).toFixed(2)} % of {item.amount}</small></h6>

                                                        </td>
                                                        <td><button type="button" onClick={() => editGoalHandler(item)}
                                                            className="btn btn-primary">
                                                            <FaRegEdit />
                                                        </button></td>
                                                    </tr>
                                                )
                                            })) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">No data found</td>
                                            </tr>
                                        )

                                        }
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="col-md-6 mt-3">
                    <div className="d-flex gap-3 justify-content-between align-items-end">
                        <h3 className="inter-700 m-0">Progress of Goals</h3>
                    </div>
                    <div className="mt-3 p-3 minh-200px bg-light rounded-10px border border-common">
                        <ProgressOfGoalsBarChart height="224" />
                        <div className="goals-progressbar">
                            <div className="d-block">
                                <div className="progress progress-savings" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    <div className="progress-bar" style={{"width":"80%"}}>80%</div>
                                </div>
                                <h6 className="mt-1 small">Savings</h6>
                            </div>
                            <div className="d-block">
                                <div className="progress progress-expenses" role="progressbar" aria-label="Info example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                    <div className="progress-bar" style={{"width":"63%"}}>63%</div>
                                </div>
                                <h6 className="mt-1 small">Expenses</h6>
                            </div>
                            <div className="d-block">
                                <div className="progress progress-debt" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                    <div className="progress-bar" style={{"width": "45%"}}>45%</div>
                                </div>
                                <h6 className="mt-1 mb-0 small">Debt Paydowns</h6>
                            </div>                          
                        </div>
                    </div>
                </div> */}
            </div>


            {/* Annual Budget offcanvas */}
            <div className="offcanvas offcanvas-end d-flex1 show1" tabIndex="-1" id="annualbudgetOffcanvas" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">Add Annual Budgeting Details</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="nav nav-pills nav-pills-tabs mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="savingsBoxTab" data-bs-toggle="pill" data-bs-target="#savingsBox" type="button" role="tab" aria-controls="savingsBox" aria-selected="true">Savings</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="expensesBoxTab" data-bs-toggle="pill" data-bs-target="#expensesBox" type="button" role="tab" aria-controls="expensesBox" aria-selected="false">Expenses</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="debpayBoxTab" data-bs-toggle="pill" data-bs-target="#debpayBox" type="button" role="tab" aria-controls="debpayBox" aria-selected="false">Debt Paydown</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="savingsBox" role="tabpanel" aria-labelledby="savingsBoxTab" tabindex="0">
                            <div className="py-2">
                                <label htmlFor="bankAccount" className="small inter-500 text-b200 mb-2">Bank Account</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="bankAccount"
                                    name="bankAccount"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="fixedIncome" className="small inter-500 text-b200 mb-2">Bonds</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="fixedIncome"
                                    name="fixedIncome"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="Insurance" className="small inter-500 text-b200 mb-2">Insurance</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="Insurance"
                                    name="Insurance"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="sip" className="small inter-500 text-b200 mb-2">SIP</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="sip"
                                    name="sip"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="sip" className="small inter-500 text-b200 mb-2">Mutual Funds</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="sip"
                                    name="sip"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="sip" className="small inter-500 text-b200 mb-2">Stocks</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="sip"
                                    name="sip"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="goldbox" className="small inter-500 text-b200 mb-2">Gold</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="goldbox"
                                    name="goldbox"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2 text-end">
                                <button className="btn btn-outline-primary px-4">+ Add Manual</button>
                            </div>
                            <div className="py-3">
                                <button className="btn btn- btn-primary btn-fin px-4 w-100">Add Savings</button>
                            </div>
                        </div>

                        {/* ... */}
                        <div className="tab-pane fade" id="expensesBox" role="tabpanel" aria-labelledby="expensesBoxTab" tabindex="0">
                            <div className="py-2">
                                <label htmlFor="insuprembox" className="small inter-500 text-b200 mb-2">Insurance Premium</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="insuprembox"
                                    name="insuprembox"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="utilitiesbox" className="small inter-500 text-b200 mb-2">Utilities</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="utilitiesbox"
                                    name="utilitiesbox"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="internetbox" className="small inter-500 text-b200 mb-2">Internet</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="internetbox"
                                    name="internetbox"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="groceriesbox" className="small inter-500 text-b200 mb-2">Groceries</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="groceriesbox"
                                    name="groceriesbox"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="entertainmentbox" className="small inter-500 text-b200 mb-2">Entertainment</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="entertainmentbox"
                                    name="entertainmentbox"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="educationbox" className="small inter-500 text-b200 mb-2">Education</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="educationbox"
                                    name="educationbox"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="healthcarebox" className="small inter-500 text-b200 mb-2">Healthcare</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="healthcarebox"
                                    name="healthcarebox"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="clothingbox" className="small inter-500 text-b200 mb-2">Clothing</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="clothingbox"
                                    name="clothingbox"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2 text-end">
                                <button type="button" className="btn btn-outline-primary px-4">+ Add Manual</button>
                            </div>
                            <div className="py-3">
                                <button type="button" className="btn btn- btn-primary btn-fin px-4 w-100">Add Expenses</button>
                            </div>
                        </div>

                        {/* ... */}
                        <div className="tab-pane fade" id="debpayBox" role="tabpanel" aria-labelledby="debpayBoxTab" tabIndex="0">
                            <div className="py-2">
                                <label htmlFor="bankAccount" className="small inter-500 text-b200 mb-2">Home Loan EMI</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="bankAccount"
                                    name="bankAccount"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="fixedIncome" className="small inter-500 text-b200 mb-2">Vehicle Laon EMI</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="fixedIncome"
                                    name="fixedIncome"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2">
                                <label htmlFor="fixedIncome" className="small inter-500 text-b200 mb-2">Personal Loan</label>
                                <input type="text" className="form-control form-control-fin bg-grey px-3"
                                    placeholder="Enter amount"
                                    id="fixedIncome"
                                    name="fixedIncome"
                                    defaultValue=""
                                />
                            </div>
                            <div className="py-2 text-end">
                                <button type="button" className="btn btn-outline-primary px-4">+ Add Manual</button>
                            </div>
                            <div className="py-3">
                                <button type="button" className="btn btn- btn-primary btn-fin px-4 w-100">Add Debt Payment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Goals offcanvas */}
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="goalsOffcanvas" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header border-bottom">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">Add Goals</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="py-2">
                        <label htmlFor="fixedIncome" className="small inter-500 text-b200 mb-2">Personal Loan</label>
                        <select className="form-select form-control-fin">
                            <option>Other</option>
                        </select>

                        <input type="text" className="form-control form-control-fin bg-grey px-3 mt-3"
                            placeholder="Enter goal name"
                            id="fixedIncome"
                            name="fixedIncome"
                            defaultValue=""
                        />
                    </div>
                    <div className="py-2">
                        <label htmlFor="fixedIncome" className="small inter-500 text-b200 mb-2">For Whom</label>
                        <select className="form-select form-control-fin">
                            <option>Select</option>
                        </select>
                    </div>
                    <div className="py-2">
                        <label htmlFor="fixedIncome" className="small inter-500 text-b200 mb-2">For When</label>
                        <select className="form-select form-control-fin">
                            <option>Select Year</option>
                        </select>
                    </div>
                    <div className="py-2">
                        <label htmlFor="fixedIncome" className="small inter-500 text-b200 mb-2">Budget</label>
                        <input type="text" className="form-control form-control-fin bg-grey px-3"
                            placeholder="Enter budget"
                            id="fixedIncome"
                            name="fixedIncome"
                            defaultValue=""
                        />
                    </div>
                    <div className="py-3">
                        <button className="btn btn- btn-primary btn-fin px-4 w-100">Add Goal</button>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Planning;
