import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";

import { FinIcon } from '../components/Common';

import { addPlanningEstimatesSchema } from "../utils/validationSchema";
import useSavePlanningEstimates from "../hooks/planning/save-planning-estimates";

const PlanningAdd = (props) => {

    //api call
    const useSavePlanningEstimatesMutation = useSavePlanningEstimates()


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            fiscalYear: "",
            savings: "",
            expense: "",
            income: "",
            debt: "",


        },
        validationSchema: addPlanningEstimatesSchema,
        onSubmit: values => {
            console.log(values);
            const payload = {
                fiscalYear: Number(formik?.values?.fiscalYear),
                savings: Number(formik?.values?.savings),
                expense: Number(formik?.values?.expense),
                income: Number(formik?.values?.income),
                debt: Number(formik?.values?.debt),
            }
            console.log(payload);
            useSavePlanningEstimatesMutation.mutate(payload,{
                onSuccess:  (data) => {
                    formik.resetForm()
                  },
            })

        },
    });

    return (<>
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Add New Planning</h3>
                <p className="text-b200 small">
                    Add New Planning basic details.
                </p>
            </div>
            <div className="col-auto">
                <Link to={'/planning'} type="button" className="btn btn-normal btn-light">
                    <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                </Link>
            </div>

            {/* --- */}
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="fiscalYear" className="small inter-500 text-b200 mb-2">Finacial Year</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="YYYY"
                                min="0"
                                id="fiscalYear"
                                name="fiscalYear"
                                onInput={formik.handleChange}
                                value={formik.values.fiscalYear}
                                onBlur={formik.handleBlur('fiscalYear')}
                            />
                            {(formik.errors.fiscalYear && formik.touched.fiscalYear) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.fiscalYear}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="savings" className="small inter-500 text-b200 mb-2">Savings (₹)</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                                min="0"
                                id="savings"
                                name="savings"
                                onInput={formik.handleChange}
                                value={formik.values.savings}
                                onBlur={formik.handleBlur('savings')}
                            />
                            {(formik.errors.savings && formik.touched.savings) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.savings}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="expense" className="small inter-500 text-b200 mb-2">Expense (₹)</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                                min="0"
                                id="expense"
                                name="expense"
                                onInput={formik.handleChange}
                                value={formik.values.expense}
                                onBlur={formik.handleBlur('expense')}
                            />
                            {(formik.errors.expense && formik.touched.expense) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.expense}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="income" className="small inter-500 text-b200 mb-2">Income (₹)</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                                min="0"
                                id="income"
                                name="income"
                                onInput={formik.handleChange}
                                value={formik.values.income}
                                onBlur={formik.handleBlur('income')}
                            />
                            {(formik.errors.income && formik.touched.income) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.income}
                            </p> : null}
                        </div>
                        <div className="col">
                            <label htmlFor="debt" className="small inter-500 text-b200 mb-2">Debt (₹)</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm" placeholder="00"
                                min="0"
                                id="debt"
                                name="debt"
                                onInput={formik.handleChange}
                                value={formik.values.debt}
                                onBlur={formik.handleBlur('debt')}
                            />
                            {(formik.errors.debt && formik.touched.debt) ? <p className="fs-14px text-danger inter-500 my-1">
                                {formik.errors.debt}
                            </p> : null}
                        </div>


                    </div>
                </div>
                <button type="button" className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4" disabled={useSavePlanningEstimatesMutation?.isPending} onClick={formik.handleSubmit}>
                    <FinIcon name="save" className="ts-1p5 me-3" />
                    Save New Planning
                    {useSavePlanningEstimatesMutation?.isPending && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                </button>
            </div>
        </div>
    </>)
}
export default PlanningAdd;