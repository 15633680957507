import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const FinIcon = (props) => {
    return(
        <svg className={`icon ${props.className !== undefined? props.className: ''}`} aria-hidden="true">
            <use href={`#icon_${props.name}`}></use>
        </svg>
    )
};

export const FinLoader = ({...props}) => {
    return(
        <div className={`spinner-border ${props.sm !== undefined? 'spinner-border-sm':''}`} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
};

export const FinClipboard = ({...props}) => {
    const copyingClip = (e, txt) => {
        navigator.clipboard.writeText(txt);
        e.target.setAttribute('aria-label', 'Copied'); 
        setTimeout(()=>{
            e.target.setAttribute('aria-label', 'Copy Bank Details');
        }, 2500);
    }
    return (
        <button type="button" className="btn btn-sm css-tooltip" aria-label="Copy Bank Details" onClick={(e) => copyingClip(e, props.copydata)}>
            <FinIcon name="copy" className="ts-1p6 pe-none" />
        </button>
    )
}

export const PasswordView = (props) => {
    const [passview, setPassview] = useState(true);
    const passShowHide = (para) => {
        if(passview){
            document.getElementById(para).setAttribute('type','text');
            setPassview(false);
        }else{
            document.getElementById(para).setAttribute('type','password');
            setPassview(true)
        }
    }
    return (
        <button type="button" className="control-onoffpassword" onClick={()=>passShowHide(props.passingid)}>
            {
                passview ? <FinIcon name="eyeoff"/>:<FinIcon name="eye"/>
            }
        </button>
    )
}

export const BalanceView = ({...props}) => {
    const [blcview, setBlcview] = useState(false);
    return (
        <div className="d-flex align-items-center gap-1">
            <div className="d-flex minw-75px">
                {
                    blcview?<>{props.prefix}{props.balance}</> : 'XXXXXXXX'
                }
            </div>
            <button type="button" className="btn btn-sm" title={blcview?'Hide Balance':'Show Balance'} onClick={()=>setBlcview(blcview => !blcview)}>
                <FinIcon name={blcview?'eyeoff':'eye'} className="ts-1p2 opacity-75"/>
            </button>
        </div>
    )
}

export const AddBankingBack = ({...props}) => {
    const navigator = useNavigate();
    const investType = (e) => {
        switch(e.target.value) {
            case 'BANK_DETAILS':
                navigator('/bank-details-add');
                break;
            case 'CREDIT_CARD':
                navigator('/credit-card-add');
                break;
            default:
                navigator('/fixed-deposit-add');
        }
    }
    return (
        <div className="d-flex gap-2">
            <select className="form-select" onChange={(e)=>investType(e)} defaultValue={props.value}>
                <option value='BANK_DETAILS'>Add Bank Details</option>
                <option value='CREDIT_CARD'>Add Credit Card</option>
                <option value='FIXED_DEPOSIT'>Add Fixed Deposit</option>
            </select>
            <Link to={'/banking'} className="btn btn-normal btn-light">
                <FinIcon name="arrowleft2" className="ts-1p4"/> Back
            </Link>
        </div>
    )
}

export const EditInvestmentBack = ({...props}) => {
    const navigator = useNavigate();
    const investType = (e) => {
        switch(e.target.value) {
            case 'MUTUAL_FUNDS':
                navigator('/mutual-fund-edit');
                break;
            case 'BONDS':
                navigator('/fixed-income-edit');
                break;
            case 'ALTERNATIVE':
                navigator('/alternate-edit');
                break;
            default:
                navigator('/stock-edit');
        }
    }
    return (
        <div className="d-flex  gap-2">
            <select className="form-select" onChange={(e)=>investType(e)} defaultValue={props.value}>
                <option value='STOCKS'>Edit Stock</option>
                <option value='MUTUAL_FUNDS'>Edit Mutual Fund</option>
                <option value='BONDS'>Edit Bonds</option>
                <option value='ALTERNATIVE'>Edit Alternate</option>
            </select>
            <Link to={'/investment'} className="btn btn-normal btn-light">
                <FinIcon name="arrowleft2" className="ts-1p4"/> Back
            </Link>
        </div>
    )
}

export const AddInvestmentBack = ({...props}) => {
    const navigator = useNavigate();
    const investType = (e) => {
        switch(e.target.value) {
            case 'MUTUAL_FUNDS':
                navigator('/mutual-fund-add');
                break;
            case 'FIXEDINCOME':
                navigator('/fixed-income-add');
                break;
            case 'ALTERNATIVE':
                navigator('/alternate-add');
                break;
            default:
                navigator('/stock-add');
        }
    }
    return (
        <div className="d-flex  gap-2">
            <select className="form-select" onChange={(e)=>investType(e)} defaultValue={props.value}>
                <option value='STOCKS'>Add Stock</option>
                <option value='MUTUAL_FUNDS'>Add Mutual Fund</option>
                <option value='FIXEDINCOME'>Add Fixed Income</option>
                <option value='ALTERNATIVE'>Add Alternate</option>
            </select>
            <Link to={'/investment'} className="btn btn-normal btn-light">
                <FinIcon name="arrowleft2" className="ts-1p4"/> Back
            </Link>
        </div>
    )
}

export const MeterChart = ({...props}) => {
    /*stroke-dasharray -> 100% = 707
        pr 1% = (707/100) = 7.07 
        da 30 = 707
        1 = 707/30 => 23.566666
    */
    return (<>
        <div className="meter-wrapper" style={{color: props.color}}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 350 350" xmlSpace="preserve">
                <circle className="meter-circle meter-outline" cx="50%" cy="50%" r="150" strokeWidth="50" strokeDasharray="707, 943" fill="none"></circle>
                <circle className="meter-circle meter-range"  cx="50%" cy="50%" r="150" strokeWidth="50" 
                    strokeDasharray={`${props.score*(7.07)}, 943`} 
                    stroke={props.color} fill="none"></circle>
            </svg>
            <div className="meter-niddledot" style={{transform: `rotate(${(props.score * 2.60) + 230}deg)`}}>
                <span className="meter-niddle"></span>
            </div>
            <span className="meter-min">0</span>
            <span className="meter-max">100</span>
            <div className="meter-score">{props.score} Score</div>
        </div>
    </>)
}