import React from "react";
import Chart from 'react-apexcharts';

const TotalInvestmentsChart = ({...props}) => {
    const state = {
        series: [{
            name: "Stocks",
            type: "area",
            data: [
                2000.85,
                3000.0,
                5122.9,
                7000.5,
                4000.7,
                8423.7,
                8423.5,
                9000.3,
                8481.85,
                7487.7,
                8506.9,
                9626.2,
                7668.95,
                6000.3,
                8607.55,
                7512.9,
                6000.25,
                8600.65,
                8881.1,
                9340.85
            ],
        }, {
            name: "Mutual Funds",
            type: "area",
            data: [
                1000.85,
                1500.0,
                4122.9,
                3000.5,
                7000.7,
                5423.7,
                8423.5,
                4000.3,
                5481.85,
                3487.7,
                2506.9,
                4626.2,
                9668.95,
                3000.3,
                4607.55,
                2512.9,
                9000.25,
                5600.65,
                6881.1,
                8340.85
            ],
        }, {
            name: "Bonds",
            type: "area",
            data: [
                200.85,
                3000.0,
                4122.9,
                6000.5,
                5000.7,
                6423.7,
                7423.5,
                7000.3,
                4481.85,
                6487.7,
                5506.9,
                8626.2,
                6668.95,
                5000.3,
                4607.55,
                9512.9,
                8000.25,
                6600.65,
                7881.1,
                8340.85
            ],
        }, {
            name: "Alternate",
            type: "area",
            data: [
                3500.85,
                4000.0,
                5122.9,
                7000.5,
                6000.7,
                5423.7,
                6423.5,
                8000.3,
                5481.85,
                4487.7,
                6506.9,
                8626.2,
                4668.95,
                3000.3,
                4607.55,
                3512.9,
                2000.25,
                5600.65,
                7881.1,
                7340.85
            ],
        }],          
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [3, 3, 3, 3],
                curve: 'smooth'
            },
            labels: [
                "13 Nov 2017",
                "14 Nov 2017",
                "15 Nov 2017",
                "16 Nov 2017",
                "17 Nov 2017",
                "20 Nov 2017",
                "21 Nov 2017",
                "22 Nov 2017",
                "23 Nov 2017",
                "24 Nov 2017",
                "27 Nov 2017",
                "28 Nov 2017",
                "29 Nov 2017",
                "30 Nov 2017",
                "01 Dec 2017",
                "04 Dec 2017",
                "05 Dec 2017",
                "06 Dec 2017",
                "07 Dec 2017",
                "08 Dec 2017"
            ],
            xaxis: {
                type: 'datetime',
            },
            legend: { show: false },
            colors:['#f4c678', '#6ca1ef', '#8dede2', '#ff9bcd'],
            fill: {
                type: 'gradient',
                gradient: {
                  shade: 'dark',
                  type: "vertical",
                  shadeIntensity: 0,
                  gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 0.25
                }
            }
        }
    }

    return (
        <Chart options={state.options} series={state.series} height={props.height} />
    )    
}

export default TotalInvestmentsChart;