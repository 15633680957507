import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  emailmobile: yup.string()
    .required("Email/Phone Number is required")
    .test('test-name', 'Enter Valid Phone/Email', function (value) {
      const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
      const isValidEmail = emailRegex.test(value);
      const isValidPhone = phoneRegex.test(value);
      return isValidEmail || isValidPhone;
    }),
});

export const addFixedDepositSchema = yup.object().shape({
  bankname: yup.string().required("Bank name is required"),
  amount: yup.number().required("Amount is required"),
  rateofinterest: yup.number().required("Rate of interest name is required"),
  tenure: yup.number().required("Tenure name is required"),
  category: yup.string().required("Category name is required"),
  // insights: yup.string().required("Insights name is required"),

});

export const FixedDepositSchemaEdit = yup.object().shape({
  bankname: yup.string().required("Bank name is required"),
  amount: yup.number().required("Amount is required"),
  rateofinterest: yup.number().required("Rate of interest name is required"),
  tenure: yup.number().required("Tenure name is required"),
  category: yup.string().required("Category name is required"),
  // insights: yup.string().required("Insights name is required"),

});

export const addBankAccountsSchema = yup.object().shape({
  accountNumber: yup.string().required("Account Number is required"),
  ifscCode: yup.string().required("IFSC code  is required"),
  branch: yup.string().required("Branch name is required"),
  bankName: yup.string().required("Bank name name is required"),
  currentBalance: yup.number().required("Current Balance name is required"),
  // currency: yup.string().required("Currency is required"),
  accountType: yup.string().required("Account Type is required"),
  holderName: yup.string().required("Holder Name is required"),
  // accountHoldertype: yup.string().required("Account Holder type is required"),
});
export const editBankAccountsSchema = yup.object().shape({
  accountNumber: yup.string().required("Account Number is required"),
  ifscCode: yup.string().required("IFSC code  is required"),
  branch: yup.string().required("Branch name is required"),
  bankName: yup.string().required("Bank name name is required"),
  currentBalance: yup.number().required("Current Balance name is required"),
  // currency: yup.string().required("Currency is required"),
  accountType: yup.string().required("Account Type is required"),
  holderName: yup.string().required("Holder Name is required"),
  // accountHoldertype: yup.string().required("Account Holder type is required"),
});

export const addCreditCardSchema = yup.object().shape({
  issuerName: yup.string().required("Issuer Name is required"),
  cardNumber: yup.string().required("Card Number is required"),
  cardType: yup.string().required("Card Type is required"),
  creditLimit: yup.number().required("Credit Limit is required"),
  usedCreditLimit: yup.number().required("Used Credit Limit is required"),
  interestRate: yup.number().required("Interest Rate is required"),
  dueDate: yup.date().required("Due Date is required"),
  minimumDue: yup.number().required("Minimum Due is required"),
  lastAmountDue: yup.number().required("Last Amount Due is required"),

});
export const CreditCardSchemaEdit = yup.object().shape({
  issuerName: yup.string().required("Issuer Name is required"),
  cardNumber: yup.string().required("Card Number is required"),
  cardType: yup.string().required("Card Type is required"),
  creditLimit: yup.number().required("Credit Limit is required"),
  usedCreditLimit: yup.number().required("Used Credit Limit is required"),
  interestRate: yup.number().required("Interest Rate is required"),
  dueDate: yup.date().required("Due Date is required"),
  minimumDue: yup.number().required("Minimum Due is required"),
  lastAmountDue: yup.number().required("Last Amount Due is required"),

});

export const addDebtSchema = yup.object().shape({
  loanType: yup.string().required('Please select loan type.'),
  lender: yup.string().required('Please select lender.'),
  amount: yup.number().required('Please enter integer value.'),
  emi: yup.number().required('Please enter integer value.'),
  interestRate: yup.number()
  .required('Please enter integer value.')
  .min(0, 'Interest rate must be greater than or equal to 0.')
  .max(30, 'Interest rate is abnormally high, please review it.'),
  loanStartDate: yup.date().required('Please select loan start date.'),
  dueDate: yup.date().required('Please select due date.'),
  principalOutstanding: yup.number().required('Please enter integer value.'),
  tenureInMonths: yup.number().required('Please select tenure.'),
  // remainingTenure: Yup.number().required('Please select remaining tenure.'),
  // insights: Yup.string().required('Please enter insights.'),
});



export const addInsuranceSchema = yup.object().shape({
  policyName: yup.string().required("Policy Name is required"),
  sumAssured: yup.number().required("Sum Assured is required"),
  premiumAmount: yup.number().required("Premium is required"),
  premiumFrequency: yup.string().oneOf(['Monthly', 'Quarterly', 'Semi-Annually', 'Annually'], 'Invalid premium frequency').required('Premium frequency is required'),
  premiumDate: yup.date().required("Premium Date is required"),
  maturityDate: yup.date().required("Maturity Date is required"),
  ownership: yup.string().required("Ownership / Nominee is required"),
  insuranceFiType: yup.string().required("Investment Link is required"),
  riderType: yup.string().required("Rider is required"),

});

export const editInsuranceSchema = yup.object().shape({
  policyName: yup.string().required("Policy Name is required"),
  sumAssured: yup.number().required("Sum Assured is required"),
  premiumAmount: yup.number().required("Premium is required"),
  premiumFrequency: yup.string().oneOf(['Monthly', 'Quarterly', 'Semi-Annually', 'Annually'], 'Invalid premium frequency').required('Premium frequency is required'),
  premiumDate: yup.date().required("Premium Date is required"),
  maturityDate: yup.date().required("Maturity Date is required"),
  ownership: yup.string().required("Ownership is required"),
  insuranceFiType: yup.string().required("insuranceFiType Due is required"),
  riderType: yup.string().required("Rider is required"),

});


export const addMutualFundsSchema = yup.object().shape({
  mutualFundId: yup.string().required("Mutual Fund Id is required"),
  quantity: yup.number().required("Quantity is required"),
  mode: yup.string().required("Mode is required"),
  buyingPrice: yup.number().required("Buying Price Date is required"),
  purchaseDate: yup.date().required("Purchase Date is required"),
  nav : yup.number().required("Nav is required")
});



export const addAlternateInvestmentsSchema = yup.object().shape({
  investmentName: yup.string().required("Investment Name is required"),
  currentPrice: yup.number().required("Current Price is required"),
  buyingPrice: yup.number().required("Buying Price  is required"),
  purchaseDate: yup.date().required("Purchase Date is required"),

});

export const editAlternateInvestmentsSchema = yup.object().shape({
  investmentName: yup.string().required("Investment Name is required"),
  currentPrice: yup.number().required("Current Price is required"),
  buyingPrice: yup.number().required("Buying Price  is required"),
  purchaseDate: yup.date().required("Purchase Date is required"),

});

export const addPlanningEstimatesSchema = yup.object().shape({
  fiscalYear: yup.number().required("Finacial Year is required"),
  savings: yup.number().required("Savings is required"),
  expense: yup.number().required("Expense is required"),
  income: yup.number().required("Income is required"),
  debt: yup.number().required("Debt is required"),

});

export const addPlanningGoalSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  amount: yup.number().required("Amount is required"),
  goalDate: yup.date().required("Goal Date is required"),
  forwhom : yup.string().optional(),

});

export const linkGoalInvesmentSchema = yup.object().shape({
  sharePercentage: yup.number().required("Share Percentage is required"),
  // goalId: yup.string().required("Goal is required")
 

});