import React, { useEffect, useState } from "react";
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import { fetcher } from "../utils/fetcher";

const PLStatus = ({ ...props }) => {
    return <>
        {
            (Math.sign(props.value) === 0) && <h6 className="text-b300 inter-700 mt-1">{props.value}%</h6>
        }
        {
            (Math.sign(props.value) === 1) && <h6 className="text-success inter-700 mt-1">{props.value}%</h6>
        }
        {
            (Math.sign(props.value) === -1) && <h6 className="text-error300 inter-700 mt-1">{props.value}%</h6>
        }
    </> 
}

const MyPortfolioChart = ({ fromDate, toDate, ...props }) => {


    var records = {
        // series: [{
        //     name: "My Portfolio",
        //     data: [10, 15, 11, 18, 12, 26, 30, 40, 30, 45, 55, 60, 55, 70, 80, 85]
        // },
        // {
        //     name: "Nifty",
        //     data: [15, 20, 15, 25, 13, 35, 25, 37, 36, 51, 32, 55, 40, 60, 65, 75]
        // }],
        options: {
            chart: {
                id: 'nifty_Line',
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [3, 3],
                curve: 'smooth',
                dashArray: [0, 0]
            },
            title: {
                text: '',
                align: 'left'
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - <strong>' + opts?.w.globals?.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
                }
            },
            colors: ['#F59E0C', '#0065FF', '#00E396'],
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                type: 'datetime',
                categories: [],
            },
            tooltip: {
                y: [
                    {
                        title: {
                            formatter: function (val) {
                                return val + " ="
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val + " ="
                            }
                        }
                    }]
            },
            grid: {
                borderColor: '#f1f1f1',
            }
        }
    };


    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [checker, setChecker] = useState(false);
    const [checkers, setCheckers] = useState(false);
    const [checker3, setChecker3] = useState(false);

    const [datanf50, setDatanf50] = useState({
        nifty50: '0.00',
        nifty50_90days: '0.00',
        nifty50_series: [],
    });
    const [datanf500, setDatanf500] = useState({
        nifty500: '0.00',
        nifty500_90days: '0.00',
        nifty500_series: [],
    });

    const [datayr10gsec, setDatayr10gsec] = useState({
        nifty10yrgsec: '0.00',
        nifty10yrgsec_90days: '0.00',
        nifty10yrgsec_series: [],
    });

    console.log('datanf50', datanf50)
    console.log('datanf500', datanf500)
    console.log('datayr10gsec', datayr10gsec)
    useEffect(() => {
        const fetchData = async () => {
            setLoading1(true);
            try {
                const { data: response } = await fetcher({
                    url: `/market-data/nifty50?from=${fromDate}&to=${toDate}`,
                    method: 'GET',
                });

                const historicalDataWithLocalTime = response.result.historicalData.map(item => {
                    return {
                        ...item,
                        timestamp: new Date(item.timestamp).toLocaleDateString('en-CA'),
                        closeVal: item.closeVal
                    }
                });
                setDatanf50(prevState => {
                    const nextState = {
                        nifty50: (((response.result.currCloseVal - response.result.prevCloseVal) / response.result.prevCloseVal) * 100).toFixed(2),
                        nifty50_90days: (((response.result.currCloseVal - response.result.closeVal90DaysAgo) / response.result.closeVal90DaysAgo) * 100).toFixed(2),
                        nifty50_series: historicalDataWithLocalTime
                    };
                    return { ...nextState };
                });

                setChecker(true);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
            setLoading1(false);
        };
        fetchData();
    }, [fromDate, toDate]);

    const updateChart = (chartId, categories, seriesData) => {
        console.log('Updating chart:', chartId, { xaxis: { categories }, series: seriesData });
        ApexCharts.exec(chartId, 'updateOptions', {
            xaxis: { categories },
            series: seriesData,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading2(true);
            try {
                const { data: response } = await fetcher({
                    url: `/market-data/nifty500?from=${fromDate}&to=${toDate}`,
                    method: 'GET',
                });

                const historicalDataWithLocalTime = response.result.historicalData.map(item => {
                    return {
                        ...item,
                        timestamp: new Date(item.timestamp).toLocaleDateString('en-CA'),
                        closeVal: item.closeVal
                    }
                });
                setDatanf500(prevState => {
                    const nextState = {
                        nifty500: (((response.result.currCloseVal - response.result.prevCloseVal) / response.result.prevCloseVal) * 100).toFixed(2),
                        nifty500_90days: (((response.result.currCloseVal - response.result.closeVal90DaysAgo) / response.result.closeVal90DaysAgo) * 100).toFixed(2),
                        nifty500_series: historicalDataWithLocalTime
                    }
                    return { ...nextState }
                });
                setCheckers(true);

            } catch (error) {
                console.error(error.message);
            }
            setLoading2(false);
        }
        fetchData();
    }, [fromDate, toDate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading3(true);
            try {
                const { data: response } = await fetcher({
                    url: `/market-data/nifty10yrgsec?from=${fromDate}&to=${toDate}`,
                    method: 'GET',
                });

                const historicalDataWithLocalTime = response.result.historicalData.map(item => {
                    return {
                        ...item,
                        timestamp: new Date(item.timestamp).toLocaleDateString('en-CA'),
                        closeVal: item.closeVal
                    }
                });
                setDatayr10gsec(prevState => {
                    const nextState = {
                        nifty10yrgsec: (((response.result.currCloseVal - response.result.prevCloseVal) / response.result.prevCloseVal) * 100).toFixed(2),
                        nifty10yrgsec_90days: (((response.result.currCloseVal - response.result.closeVal90DaysAgo) / response.result.closeVal90DaysAgo) * 100).toFixed(2),
                        nifty10yrgsec_series: historicalDataWithLocalTime
                    };
                    return { ...nextState };
                });

                setChecker3(true);

            } catch (error) {
                console.error(error.message);
            }
            setLoading3(false);
        }
        fetchData();
    }, [fromDate, toDate]);
    console.log(checker, checkers, checker3)

    useEffect(() => {
        if (checker) {
            ApexCharts.exec('nifty_Line', 'updateOptions', {
                xaxis: {
                    categories: datanf50.nifty50_series.map((itm) => itm.timestamp)
                },
                series: [{
                    name: "My Portfolio",
                    data: [100, 1010, 1510, 1101, 1180, 1200, 2600, 3001, 4010, 300, 4501, 5510, 600, 550, 1700, 800, 8510, 100, 1150, 1110, 180, 1200, 2600, 3010, 1400, 1300, 450, 550, 600, 550, 700, 800, 850, 1010, 1510, 1110, 1810, 1200, 26010, 3010, 400, 3100, 4501, 5501, 6001, 5510, 1700, 8100, 8150].slice(0, datanf50.nifty50_series.length)
                },
                {
                    name: 'Nifty 50',
                    data: datanf50.nifty50_series.map(itm => itm.closeVal)
                }]
            });
        }
    }, [checker, datanf50]);



    useEffect(() => {
        if (checkers) {
            ApexCharts.exec('nifty_Line', 'updateOptions', {
                xaxis: {
                    categories: datanf500.nifty500_series.map((itm) => itm.timestamp)
                },
                series: [{
                    name: "My Portfolio",
                    data: [100, 1010, 1510, 1101, 1180, 1200, 2600, 3001, 4010, 300, 4501, 5510, 600, 550, 1700, 800, 8510, 100, 1150, 1110, 180, 1200, 2600, 3010, 1400, 1300, 450, 550, 600, 550, 700, 800, 850, 1010, 1510, 1110, 1810, 1200, 26010, 3010, 400, 3100, 4501, 5501, 6001, 5510, 1700, 8100, 8150].slice(0, datanf500.nifty500_series.length)
                },
                {
                    name: 'Nifty 500',
                    data: datanf500.nifty500_series.map(itm => itm.closeVal)
                }]
            });
        }
    }, [checkers, datanf500]);

    useEffect(() => {
        if (checker3) {
            ApexCharts.exec('nifty_Line', 'updateOptions', {
                xaxis: {
                    categories: datayr10gsec.nifty10yrgsec_series.map((itm) => itm.timestamp)
                },
                series: [{
                    name: "My Portfolio",
                    data: [100, 1010, 1510, 1101, 1180, 1200, 2600, 3001, 4010, 300, 4501, 5510, 600, 550, 1700, 800, 8510, 100, 1150, 1110, 180, 1200, 2600, 3010, 1400, 1300, 450, 550, 600, 550, 700, 800, 850, 1010, 1510, 1110, 1810, 1200, 26010, 3010, 400, 3100, 4501, 5501, 6001, 5510, 1700, 8100, 8150].slice(0, datayr10gsec.nifty10yrgsec_series.length)
                },
                {
                    name: 'Nifty 500',
                    data: datayr10gsec.nifty10yrgsec_series.map(itm => itm.closeVal)
                }]
            });
        }


    }, [checker3, datayr10gsec]);


    const chartPloting = (event, categ) => {
        [...document.querySelectorAll('.col-indicator')].forEach((ele) => {
            ele.classList.remove('col-nifty');
        });
        event.currentTarget.classList.add('col-nifty');

        if (categ === 'nifty50') {
            ApexCharts.exec('nifty_Line', 'updateOptions', {
                colors: ['#F59E0C', '#0065FF'],
                xaxis: {
                    categories: datanf50.nifty50_series.map((itm) => itm.timestamp)
                },
                series: [
                    {
                        name: "My Portfolio",
                        data: [100, 1010, 1510, 1101, 1180, 1200, 2600, 3001, 4010, 300, 4501, 5510, 600, 550, 1700, 800, 8510, 100, 1150, 1110, 180, 1200, 2600, 3010, 1400, 1300, 450, 550, 600, 550, 700, 800, 850, 1010, 1510, 1110, 1810, 1200, 26010, 3010, 400, 3100, 4501, 5501, 6001, 5510, 1700, 8100, 8150].slice(0, datanf50.nifty50_series.length)
                    },
                    {
                        name: 'Nifty 50',
                        data: datanf50.nifty50_series.map(itm => itm.closeVal)
                    }
                ]
            });
        }
        if (categ === 'nifty500') {
            ApexCharts.exec('nifty_Line', 'updateOptions', {
                colors: ['#F59E0C', '#8250df'],
                xaxis: {
                    categories: datanf500.nifty500_series.map((itm) => itm.timestamp)
                },
                series: [
                    {
                        name: "My Portfolio",
                        data: [1010, 1510, 1101, 1180, 1200, 2600, 3001, 4010, 300, 4501, 5510, 600, 550, 1700, 800, 8510, 100, 1150, 1110, 180, 1200, 2600, 3010, 1400, 1300, 450, 550, 600, 550, 700, 800, 850, 1010, 1510, 1110, 1810, 1200, 26010, 3010, 400, 3100, 4501, 5501, 6001, 5510, 1700, 8100, 8150].slice(0, datanf500.nifty500_series.length)
                    },
                    {
                        name: 'Nifty 500',
                        data: datanf500.nifty500_series.map((itm) => itm.closeVal)
                    }
                ]
            });
        }

        if (categ === 'yr10gsec') {
            ApexCharts.exec('nifty_Line', 'updateOptions', {
                colors: ['#F59E0C', '#8250df'],
                xaxis: {
                    categories: datayr10gsec.nifty10yrgsec_series.map((itm) => itm.timestamp)
                },
                series: [
                    {
                        name: "My Portfolio",
                        data: [1010, 1510, 1101, 1180, 1200, 2600, 3001, 4010, 300, 4501, 5510, 600, 550, 1700, 800, 8510, 100, 1150, 1110, 180, 1200, 2600, 3010, 1400, 1300, 450, 550, 600, 550, 700, 800, 850, 1010, 1510, 1110, 1810, 1200, 26010, 3010, 400, 3100, 4501, 5501, 6001, 5510, 1700, 8100, 8150].slice(0, datayr10gsec.nifty10yrgsec_series.length)
                    },
                    {
                        name: '10 Year G-Sec',
                        data: datayr10gsec.nifty10yrgsec_series.map((itm) => itm.closeVal)
                    }
                ]
            });
        }
    };


    return (<>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 gy-3 mx-0 row-cols-border">
            <div className="col col-myporfolio p-3 p-xl-4">
                <h5 className="inter-600">My Portfolio</h5>
                <div className="d-flex gap-2 justify-content-between">
                    <div className="d-block">
                        <small className="fs-13px text-b100">90 Days</small>
                        <h6 className="text-success inter-700 mt-1">+1.77%</h6>
                    </div>
                    <div className="d-block text-end">
                        <small className="fs-13px text-b100">1 Days</small>
                        <h6 className="text-success inter-700 mt-1">+0.55%</h6>
                    </div>
                </div>
            </div>
            <div className="col col-nifty p-3 p-xl-4 col-indicator" onClick={(e) => chartPloting(e, 'nifty50')}>
                <h5 className="inter-600">Nifty 50</h5>
                <div className="d-flex gap-2 justify-content-between">
                    <div className="d-block">
                        <small className="fs-13px text-b100">90 Days</small>
                        <PLStatus value={datanf50.nifty50_90days} />
                    </div>
                    <div className="d-block text-end">
                        <small className="fs-13px text-b100">1 Days</small>
                        <PLStatus value={datanf50.nifty50} />
                    </div>
                </div>
            </div>
            <div className="col p-3 p-xl-4 col-indicator" onClick={(e) => chartPloting(e, 'nifty500')}>
                <h5 className="inter-600">Nifty 500</h5>
                <div className="d-flex gap-2 justify-content-between">
                    <div className="d-block">
                        <small className="fs-13px text-b100">90 Days</small>
                        <PLStatus value={datanf500.nifty500_90days} />
                    </div>
                    <div className="d-block text-end">
                        <small className="fs-13px text-b100">1 Days</small>
                        <PLStatus value={datanf500.nifty500} />
                    </div>
                </div>
            </div>
            <div className="col p-3 p-xl-4 col-indicator" onClick={(e) => chartPloting(e, 'yr10gsec')}>
                <h5 className="inter-600">10yr Gsec</h5>
                <div className="d-flex gap-2 justify-content-between">
                    <div className="d-block">
                        <small className="fs-13px text-b100">90 Days</small>
                        <PLStatus value={datayr10gsec.nifty500_90days} />
                    </div>
                    <div className="d-block text-end">
                        <small className="fs-13px text-b100">1 Days</small>
                        <PLStatus value={datayr10gsec.nifty10yrgsec} />

                        {/* <h6 className="text-success inter-700 mt-1">+0.39%</h6> */}
                    </div>
                </div>
            </div>
        </div>
        <Chart
            height={props.height}
            options={records.options}

            series={[
                {
                    name: "My Portfolio",
                    data: loading1 ? [] : [1010, 1510, 1101, 1180, 1200, 2600, 3001, 4010, 300, 4501, 5510, 600, 550, 1700, 800, 8510, 100, 1150, 1110, 180, 1200, 2600, 3010, 1400, 1300, 450, 550, 600, 550, 700, 800, 850, 1010, 1510, 1110, 1810, 1200, 26010, 3010, 400, 3100, 4501, 5501, 6001, 5510, 1700, 8100, 8150]
                },
                {
                    name: 'Nifty 50',
                    data: loading1 ? [] : datanf50.nifty50_series.map(itm => itm.closeVal)
                },
                {
                    name: 'Nifty 500',
                    data: loading2 ? [] : datanf500.nifty500_series.map(itm => itm.closeVal)
                },

                {
                    name: 'nifty 10yrgsec',
                    data: loading3 ? [] : datanf500.nifty500_series.map(itm => itm.closeVal)
                }
            ]}
        />
        {/* <Chart height={props.height}
            options={records.options}
            series={
                loading1 ? [] : datanf50.nifty50_series.map((itm) => itm.closeVal)
            }
        /> */}

    </>
    )
}

export default MyPortfolioChart;