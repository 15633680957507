import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FinIcon, FinLoader } from "../components/Common";
import { fetcher } from "../utils/fetcher";
import LoanJourneyChart from '../charts/LoanJourneyChart';
import useGetLoans from "../hooks/loan/get-loans";
import { format } from "date-fns";
import useGetLoanJourney from "../hooks/debt/get-loan-journey";
import exportFromJSON from "export-from-json";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const Debt = () => {

    // state
    const [selectedJourney, setSelectedJourney] = useState('');


    // api call
    const { data: getLoansData, isLoading: getLoansIsLoading, isFetching: getLoansIsFetching } = useGetLoans()
    const { data: getLoanJourneData, isLoading: getLoanJourneIsLoading, isFetching: getLoanJourneIsFetching } = useGetLoanJourney(!!selectedJourney ? selectedJourney : getLoansData?.data?.result?.listOfItems[0].id)
    console.log('getLoansData', getLoansData)

    const navigate = useNavigate()
    const editHandler = (item) => {
        navigate('/debt-edit', { state: { item } });
    }


    // const [debtloading, setDebtloading] = useState(true);
    // const [debt, setDebt] = useState([]);

    // useEffect(()=>{
    //     const fetchData = async () =>{
    //         setDebtloading(true);
    //         try {
    //             const {data: response} = await fetcher({
    //                 url: `/loans`,
    //                 method: 'GET',
    //             });
    //             setDebt(response);
    //             LoanJourney(response.result.listOfItems[0].id)
    //         } catch (error) {
    //             console.error(error.message);
    //         }
    //         setDebtloading(false);
    //     }
    //     fetchData();
    // }, []);

    // Function to format numbers with commas
    function formatNumberWithCommas(number) {
        return new Intl.NumberFormat('en-IN')?.format(number)?.toString();
    }


    const [journeyloading, setJourneyloading] = useState(true);
    const [journey, setJourney] = useState([]);
    const LoanJourney = (val) => {
        const fetchData = async () => {
            setJourneyloading(true);
            try {
                const { data: response } = await fetcher({
                    url: `/loans/${val}/journey`,
                    method: 'GET',
                });
                setJourney(response.result);
            } catch (error) {
                console.error(error.message);
            }
            setJourneyloading(false);
        }
        fetchData();
    }

    const onExportCSVFile = () => {
        const fileName = "DebtData";
        const data = getLoansData?.data?.result?.listOfItems?.map((item, index) => {
            return {
                loanType: item?.loanType,
                Lender: !!item?.lender ? item?.lender?.name : item?.otherLender,
                Amount: item?.amount,
                Emi: item?.emi,
                InterestRate: item?.interestRate,
                DueDate: `${format(item?.dueDate, 'dd/MM/yyyy')}`,
                PrincipalOutstanding: item?.principalOutstanding,
                TenureRemainingTenure: `${item?.tenureInMonths} / ${item.remainingTenure}`,
                Insights: `${!!item?.insights ? item?.insights : 'N/A'}`
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }


    return (<>
        <div className="row row-inner">
            <div className="col-12">
                <div className="row g-2 g-xxl-3">
                    <div className="col">
                        <h3 className="inter-700">Debt</h3>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex gap-2 gap-xxl-3">
                            <button onClick={() => onExportCSVFile()} type="button" className="btn btn-normal btn-light">
                                <FinIcon name="export" className="ts-1p4" />
                                Export
                            </button>
                            <Link to={'/debt-add'} className="btn btn-normal btn-primary">
                                <FinIcon name="addcirlce" className="ts-1p4" />
                                Add
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row row-inner mt-4">
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table-common">
                        <thead>
                            <tr>
                                <th>Loan <br />Type</th>
                                <th>Lender</th>
                                <th>Loan Value</th>
                                <th>EMI  (per month)</th>
                                <th>Interest Rate<br />(per annum)</th>
                                <th>Loan <br />Start Date</th>
                                <th>EMI Due Date</th>
                                <th width="120">Loan<br />Outstanding</th>
                                <th width="150">Tenure /<br />Remaining Tenure Month</th>
                                <th>Insights</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {
                                debtloading && <tr>
                                    <td colSpan="10" className="text-center text-primary py-4">
                                        <FinLoader sm/>
                                    </td>
                                </tr>
                            }
                            {
                                !debtloading && debt && debt!=='' && debt.result.listOfItems.map((item) => {
                                    return (
                                        <tr key={item?.id}>
                                            <td>
                                                <span className="inter-600">{item?.loanType}</span>
                                            </td>
                                            <td>{item?.lender}</td>
                                            <td>₹{item?.amount}</td>
                                            <td>₹{item?.emi}</td>
                                            <td>{item?.interestRate}%</td>                                            
                                            <td className="text-nowrap">{(item?.loanStartDate).split('T')[0]}</td>
                                            <td className="text-nowrap">{(item?.dueDate).split('T')[0]}</td>
                                            <td>₹{item?.principalOutstanding}</td>
                                            <td>{item?.tenureInMonths} / {item.remainingTenure}</td>
                                            <td>{item?.insights}</td>
                                        </tr>
                                    )
                                })
                            } */}
                            {getLoansIsLoading || getLoansIsFetching ?
                                <tr key="loading">
                                    <td colSpan="10" className="text-center">
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>

                                : <>
                                    {
                                        getLoansData?.data?.result?.listOfItems && getLoansData.data.result.listOfItems.length > 0 ? (
                                            !!getLoansData?.data?.result?.listOfItems && getLoansData?.data?.result?.listOfItems?.map((item, index) => {

                                                return <tr key={index?.toString()}>
                                                    <td>

                                                        <span className="inter-600">{item?.loanType}</span>
                                                    </td>
                                                    {!!item?.lender ? <td>{item?.lender?.name}</td> : <td>{item?.otherLender}</td>}
                                                    <td>₹{formatNumberWithCommas(item?.amount)}</td>
                                                    <td>₹{formatNumberWithCommas(item?.emi)}</td>
                                                    <td>{item?.interestRate}%</td>
                                                    <td className="text-nowrap">{format(item?.loanStartDate, 'dd MMM yyyy')}</td>
                                                    <td className="text-nowrap">{format(item?.dueDate, 'dd MMM yyyy')}</td>
                                                    <td>₹{formatNumberWithCommas(item?.principalOutstanding)}</td>
                                                    <td>{item?.tenureInMonths} / {item.remainingTenure}</td>
                                                    <td>{!!item?.insights ? item?.insights : 'N/A'}</td>
                                                    <td>
                                                        <button type="button" onClick={() => editHandler(item)}
                                                            className="btn btn-primary ">
                                                            <FaRegEdit />
                                                        </button>
                                                    </td>
                                                </tr>
                                            })
                                        ) : (
                                            <tr key="no-data">
                                                <td colSpan="11" className="text-center">
                                                    No data found
                                                </td>
                                            </tr>
                                        )

                                    }
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="row row-inner mt-4">
            <div className="col">
                <div className="d-flex flex-wrap align-items-center gap-3">
                    <h5 className="inter-600 m-0">Your Loan Journey</h5>
                    <select className="form-select w-auto" value={!!selectedJourney ? selectedJourney : getLoansData?.data?.result?.listOfItems[0].id} onChange={(e) => setSelectedJourney(e.target.value)}>
                        {/* {
                            debtloading && <option value=''>Loading....</option>
                        }
                        {
                            !debtloading && debt && debt!=='' && debt.result.listOfItems.map((item) => {
                                return (<option key={item?.id} value={item?.id}>{item?.loanType}</option>)
                            })
                        } */}
                        <option>--- Select ---</option>
                        {!!getLoansData?.data?.result?.listOfItems && getLoansData?.data?.result?.listOfItems?.map((item, index) => {
                            return <option key={item?.id} value={item?.id}>{item?.loanType} / {!!item?.lender ? item?.lender?.name : item?.otherLender}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="col-auto">
                <div className="d-flex gap-2">
                    <div className="btn btn-light rounded-pill px-3 pe-none"><span className="me-1 d-inline-block ts-1p5 text-pink">&bull;</span> Interest</div>
                    <div className="btn btn-light rounded-pill px-3 pe-none"><span className="me-1 d-inline-block ts-1p5 text-success300">&bull;</span> Capital</div>
                </div>
            </div>
            <div className="col-12 mt-3">
                {
                    (getLoanJourneIsLoading || getLoanJourneIsFetching) && <div className="text-center text-primary bg-light px-3 py-5 rounded-4">
                        <FinLoader sm />
                    </div>
                }
                {
                    !!getLoanJourneData && <LoanJourneyChart height="480" data={getLoanJourneData?.data?.result} />

                }
            </div>
        </div>
    </>)
}
export default Debt;
