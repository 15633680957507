    import React from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import usePostinvestment from "../hooks/investment/post-investment";
import { FinIcon, AddInvestmentBack } from '../components/Common';
import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const FixedSchema = Yup.object().shape({

    couponRate: Yup.number()
        .min(0, 'Coupon Rate must be at least 0.')
        .max(30, 'This value is not allowed. Please fill between 0 and 30.')
        .required('Please enter Coupon Rate.'),
    paymentFreq: Yup.string(),
    category: Yup.string().required('Category is required.'),
    nextInterestPaymentDate: Yup.date().required('Please enter Next Interest Payment Date.'),
    maturityDate: Yup.date().required('Please enter Maturity Date.'),
    isTaxable: Yup.boolean(),
    investmentType: Yup.string().required('Investment Type is required.'),
    buyingPrice: Yup.number().required('Please enter Buying Price.'),
    purchaseDate: Yup.date().required('Please enter Purchase Date.'),
});


const FixedIncomeEdit = () => {
    // const usePostInvestmentMutation = usePostinvestment();
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const location = useLocation()
    const item = location.state?.item
    console.log(item)
    
    const navigate = useNavigate()
    const handleBackToInveshment=()=>{
        navigate('/investment')
        setTimeout(() => {
            window.scrollTo({
              top: document.documentElement.scrollHeight,
              behavior: 'smooth',
            });
          }, 100);
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            instrumentName: item?.instrumentName || '',
            couponRate: item?.couponRate || '',
            category: item?.category || '',
            paymentFreq: item?.paymentFreq || '',
            nextInterestPaymentDate: formatDate(item?.nextInterestPaymentDate) || '',
            maturityDate: formatDate(item?.maturityDate) || '',
            isTaxable: item?.isTaxable || false,
            investmentType: item?.investmentType || 'BONDS',
            buyingPrice: item?.buyingPrice || '',
            purchaseDate: formatDate(item?.purchasedDate) || '',
        },
        validationSchema: FixedSchema,
        onSubmit: values => {
            console.log("data",values)
            values.isTaxable = values.isTaxable === "true";
            // usePostInvestmentMutation.mutate(values, {
            //     onSuccess: (data) => {
            //         formik.resetForm();
            //     },
            // });
        },
    });

    return (
        <div className="row row-inner">
            <div className="col align-self-center">
                <h3 className="inter-700">Update Fixed Income</h3>
                <p className="text-b200 small">Update fixed-income basic details.</p>
            </div>
            <div className="col-auto">
                <button onClick={handleBackToInveshment} className="btn btn-normal btn-light">
                    <FinIcon name="arrowleft2" className="ts-1p4" /> Back
                </button>
            </div>
            <div className="col-12 mt-1 mt-xl-2 mb-4">
                <div className="bg-gray rounded-20px p-3 p-sm-4 p-xxl-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-lg-4">
                        <div className="col">
                            <label htmlFor="instrumentName" className="small inter-500 text-b200 mb-2">Instrument Name</label>
                            <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="instrumentName"
                                name="instrumentName"
                                value={formik.values.instrumentName}
                                readOnly
                            />
                        </div>


                        <div className="col">
                            <label htmlFor="couponRate" className="small inter-500 text-b200 mb-2">Coupon Rate (%)</label>
                            <input type="text" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="couponRate"
                                name="couponRate"
                                onChange={formik.handleChange}
                                value={formik.values.couponRate}
                            />
                            {formik.errors.couponRate && formik.touched.couponRate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.couponRate}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="category" className="small inter-500 text-b200 mb-2">Category</label>
                            <select
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="category"
                                name="category"
                                onChange={formik.handleChange}
                                value={formik.values.category}
                            >
                                <option value="Fixed_Deposit">Fixed Deposit</option>
                                <option value="Corporate">Corporate</option>
                            </select>
                            {formik.errors.category && formik.touched.category ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.category}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="paymentFreq" className="small inter-500 text-b200 mb-2">Payment Frequency</label>
                            <select className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="paymentFreq"
                                name="paymentFreq"
                                onChange={formik.handleChange}
                                value={formik.values.paymentFreq}
                            >
                                <option value="">-- Select --</option>
                                <option value="Semi Annually">Semi Annually</option>
                                <option value="Annually">Annually</option>
                                <option value="Yearly">Yearly</option>
                                <option value="Quarterly">Quarterly</option>
                            </select>
                            {formik.errors.paymentFreq && formik.touched.paymentFreq ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.paymentFreq}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="nextInterestPaymentDate" className="small inter-500 text-b200 mb-2">Next Interest Payment Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="nextInterestPaymentDate"
                                name="nextInterestPaymentDate"
                                onChange={formik.handleChange}
                                value={formik.values.nextInterestPaymentDate}
                            />
                            {formik.errors.nextInterestPaymentDate && formik.touched.nextInterestPaymentDate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.nextInterestPaymentDate}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="maturityDate" className="small inter-500 text-b200 mb-2">Maturity Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="maturityDate"
                                name="maturityDate"
                                onChange={formik.handleChange}
                                value={formik.values.maturityDate}
                            />
                            {formik.errors.maturityDate && formik.touched.maturityDate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.maturityDate}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="isTaxable" className="small inter-500 text-b200 mb-2">Is Taxable</label>
                            <select
                                className="form-select form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="isTaxable"
                                name="isTaxable"
                                onChange={(e) => formik.setFieldValue('isTaxable', e.target.value === "true")} // Convert string to boolean
                                value={formik.values.isTaxable ? "true" : "false"} // Convert boolean to string for select
                            >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            {formik.errors.isTaxable && formik.touched.isTaxable ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.isTaxable}
                                </p>
                            ) : null}
                        </div>

                        <div className="col">
                            <label htmlFor="investmentType" className="small inter-500 text-b200 mb-2">Investment Type<strong className="text-danger">*</strong></label>
                            <input
                                className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="investmentType"
                                name="investmentType"
                                value={formik.values.investmentType}
                                readOnly
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="buyingPrice" className="small inter-500 text-b200 mb-2">Buying Price</label>
                            <input type="number" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="buyingPrice"
                                name="buyingPrice"
                                onChange={formik.handleChange}
                                value={formik.values.buyingPrice}
                            />
                            {formik.errors.buyingPrice && formik.touched.buyingPrice ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.buyingPrice}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="purchaseDate" className="small inter-500 text-b200 mb-2">Purchase Date</label>
                            <input type="date" className="form-control form-control-fin inter-500 bg-white px-3 shadow-sm"
                                id="purchaseDate"
                                name="purchaseDate"
                                onChange={formik.handleChange}
                                value={formik.values.purchaseDate}
                            />
                            {formik.errors.purchaseDate && formik.touched.purchaseDate ? (
                                <p className="fs-14px text-danger inter-500 my-1">
                                    {formik.errors.purchaseDate}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mt-2 mt-xl-4">
                        <div className="col">
                            <button
                                type="button"
                                className="btn btn-primary btn-fin px-4 mt-3 mt-xl-4"
                                // disabled={usePostInvestmentMutation?.isPending}
                                onClick={formik.handleSubmit}
                            >
                                <FinIcon name="save" className="ts-1p5 me-3" />
                                Update Fixed Income
                                {/* {usePostInvestmentMutation?.isPending && (
                            <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        )} */}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FixedIncomeEdit;
