import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import exportFromJSON from "export-from-json";
import { FaRegEdit } from "react-icons/fa";
import BankingDonutChart from '../charts/BankingDonutChart';
// import TotalInvestmentsChart from '../charts/TotalInvestmentsChart';
// import MyPortfolioChart from '../charts/MyPortfolioChart';

import { FinIcon, FinClipboard, BalanceView } from "../components/Common";
import useGetBankAccounts from "../hooks/bank-services/get-banking-accounts";
import useGetBankCreditCards from "../hooks/bank-services/get-bank-credit-cards";
import { format, parseISO } from "date-fns";
import useGetBankFixedDeposits from "../hooks/bank-services/get-bank-fixed-deposits";

const Banking = () => {
    function formatNumberWithCommas(number) {
        return new Intl.NumberFormat('en-IN')?.format(number)?.toString();
    }

    // init
    const limit = 8;

    // state
    const [addurl, setAddurl] = useState('/bank-details-add');
    const [tabtitle, setTabtitle] = useState('Bank Details');
    const [currentPage, setcurrentPage] = useState(0)

    // api call
    const { data: bankAccountsData, isLoading: bankAccountsIsLoading, isFetching: bankAccountsIsFetching } = useGetBankAccounts(currentPage)
    const { data: bankCreditCardsData, isLoading: bankCreditCardsIsLoading, isFetching: bankCreditCardsIsFetching } = useGetBankCreditCards(currentPage)
    const { data: bankFixedDeposits, isLoading: bankFixedDepositsIsLoading, isFetching: bankFixedDepositsIsFetching } = useGetBankFixedDeposits(currentPage)

    useEffect(() => {
        const triggerTabList = document.querySelectorAll('#bankigTabs button[data-bs-toggle]');
        triggerTabList.forEach(triggerEl => {
            triggerEl.addEventListener('show.bs.tab', function (event) {
                setAddurl(event.target.dataset.addurl);
                setTabtitle(event.target.innerText);
            });
        });
    }, []);

    const navigate = useNavigate()

    const editHandler = (item) => {
        navigate('/bank-details-edit', { state: { item } })
    }
    const editFixedDepositsHandler =(item)=>{
        navigate('/fixed-deposit-edit',{state:{item}})
    }
    const editCreditHandler = (item) => {
        navigate('/credit-card-edit', { state: { item } })
    }

    useEffect(() => {
        const fetch = async () => {
            await setcurrentPage(0)
        }
        fetch()
    }, [tabtitle])


    const nextPage = () => {
        if (limit > currentPage) {
            setcurrentPage(currentPage + 1)
        }
    }

    const prevPage = () => {
        if (currentPage > 0) {
            setcurrentPage(currentPage - 1)
        }
    }

    const itemPaginationRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return (
                <Link className={`page-link ${currentPage > 0 ? '' : 'disabled'}`} onClick={prevPage}>Prev</Link>
            );
        }
        if (type === 'next') {
            return (
                <Link className="page-link" onClick={nextPage}>Next</Link>
            );
        }
        return originalElement;
    };

    const onChangePage = (page, pageSize) => {
        setcurrentPage(page)
    }

    const onExportCSVFileBank = () => {
        const fileName = "BankDetailCSV";
        const data = bankAccountsData?.data?.result?.listOfItems?.map((item, index) => {
            return {
                BankName: item?.bankName,
                AccountType: item?.accountType,
                Branch: item?.branch,
                IFSCCode: item?.ifscCode,
                Balance: item?.currentBalance,
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSVFileCreditCard = () => {
        const fileName = "CreditCardCSV";
        const data = bankCreditCardsData?.data?.result?.listOfItems?.map((item, index) => {
            const parsedDate = parseISO(item?.dueDate);
            return {
                BankName: item?.issueName,
                CreditCardName: item?.cardType,
                CreditLimit: item?.creditLimit,
                UsedCreditLimit: item?.usedCreditLimit,
                InterestRate: item?.interestRate,
                DueDate: format(parsedDate, 'dd/MM/yyyy'),
                minimumDue: item?.minimumDue,
                LastDueAmount: item?.lastAmountDue
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSVFileFixedDeposits = () => {
        const fileName = "FixedDepositsCSV";
        const data = bankFixedDeposits?.data?.result?.listOfItems?.map((item, index) => {
            return {
                BankName: item?.institution,
                Amount: item?.amount,
                InterestRate: item?.interestRate,
                Tenure: item?.tenureInMonths,
                Category: item?.category,
                Insights: `${!!item?.insights ? item?.insights : 'N/A'}`
            }
        });
        const exportType = exportFromJSON?.types.csv;


        exportFromJSON({ data, fileName, exportType })
    }

    const onExportCSV = () => {
        if (tabtitle === 'Bank Details') {
            onExportCSVFileBank()
        } else if (tabtitle === 'Credit Cards') {
            onExportCSVFileCreditCard()
        } else if (tabtitle === 'Fixed Deposits') {
            onExportCSVFileFixedDeposits()
        }
    }

    return (<>
        <div className="row row-inner">
            <div className="col">
                <h3 className="inter-700">Banking</h3>
            </div>
            <div className="col-auto">
                <div className="d-flex gap-2 gap-xxl-3">
                    <button onClick={onExportCSV} type="button" className="btn btn-normal btn-light">
                        <FinIcon name="export" className="ts-1p4" />
                        Export
                    </button>
                    <Link to={addurl} className="btn btn-normal btn-primary">
                        <FinIcon name="addcirlce" className="ts-1p4" />
                        Add
                    </Link>
                </div>
            </div>
            <div className="col-12 py-2">
                <BankingDonutChart height="200" chartName={tabtitle} />
            </div>
        </div>

        <div className="row row-inner mt-3">
            <div className="col pt-1">
                <h3 className="inter-700">{tabtitle}</h3>
            </div>
            <div className="col-auto ms-md-auto mb-3">
                <ul className="nav nav-pills nav-pills-common" id="bankigTabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="bankdetails-tab" data-bs-toggle="pill" data-bs-target="#bankdetailsBox" type="button" role="tab" aria-controls="bankdetailsBox" aria-selected="true" data-addurl="/bank-details-add">Bank Details</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="creditcards-tab" data-bs-toggle="pill" data-bs-target="#creditcardsBox" type="button" role="tab" aria-controls="creditcardsBox" aria-selected="false" data-addurl="/credit-card-add">Credit Cards</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="fixeddeposits-tab" data-bs-toggle="pill" data-bs-target="#fixeddepositsBox" type="button" role="tab" aria-controls="fixeddepositsBox" aria-selected="false" data-addurl="/fixed-deposit-add">Fixed Deposits</button>
                    </li>
                </ul>
            </div>
            <div className="col-12">
                <div className="tab-content">
                    <div className="tab-pane show active" id="bankdetailsBox" role="tabpanel" aria-labelledby="bankdetails-tab" tabIndex="0">
                        <div className="table-responsive">
                            <table className="table-common">
                                <thead>
                                    <tr>
                                        <th>Bank Name</th>
                                        <th>Account Type</th>
                                        <th>Branch</th>
                                        <th>IFSC Code</th>
                                        <th>Balance</th>
                                        <th width="180">Transactions</th>
                                        <th width="110">Copy Bank<br />Details</th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bankAccountsIsLoading || bankAccountsIsFetching ?
                                        <tr key="loading">
                                            <td colSpan="7" className="text-center">
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>
                                        : <>
                                            {
                                                !!bankAccountsData?.data?.result?.listOfItems && bankAccountsData?.data?.result?.listOfItems?.map((item) => {
                                                    return (
                                                        <tr key={item?.id}>
                                                            <td>
                                                                <span className="inter-600">{item?.bankName}</span>
                                                            </td>
                                                            <td>{item?.accountType}</td>
                                                            <td>{item?.branch}</td>
                                                            <td>{item?.ifscCode}</td>
                                                            <td>
                                                                <BalanceView balance={formatNumberWithCommas(item.currentBalance)} prefix="₹" />
                                                            </td>
                                                            <td>
                                                                <Link to={`/all-transactions?accountId=${item?.id}`} className="text-nowrap inter-600 text-unof-3">See all transactions</Link>
                                                            </td>
                                                            <td>
                                                                <FinClipboard copydata={`Bank Name: ${item?.bankName}; Account Type: ${item?.accountType}; Branch: ${item?.branch}; IFSC Code: ${item?.ifscCode}`} />
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => editHandler(item)}
                                                                    className="btn btn-primary  ">
                                                                    <FaRegEdit />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane" id="creditcardsBox" role="tabpanel" aria-labelledby="creditcards-tab" tabIndex="0">
                        <div className="table-responsive">
                            <table className="table-common">
                                <thead>
                                    <tr>
                                        <th>Institution</th>
                                        <th>Types of <br />Card</th>
                                        <th>Due <br />Amt</th>
                                        <th>Minimum<br />Due</th>
                                        <th>Interest Rate<br />(per month)</th>
                                        <th>Due <br />Date</th>
                                        <th>CL</th>
                                        <th>UCC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bankCreditCardsIsLoading || bankCreditCardsIsFetching ?
                                        <tr key="loading">
                                            <td colSpan="8" className="text-center">
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>

                                        : <>
                                            {
                                                !!bankCreditCardsData?.data?.result?.listOfItems && bankCreditCardsData?.data?.result?.listOfItems?.map((item, index) => {
                                                    const parsedDate = parseISO(item?.dueDate);

                                                    return (
                                                        <tr key={index?.toString()}>
                                                            <td><span className="inter-600">{item?.issueName}</span></td>
                                                            <td>{item?.cardType}</td>
                                                            <td>₹{formatNumberWithCommas(item?.creditLimit)}</td>
                                                            <td>₹{formatNumberWithCommas(item?.usedCreditLimit)}</td>
                                                            <td>{item?.interestRate}%</td>
                                                            <td>{format(parsedDate, 'dd MMM yyyy')}</td>
                                                            <td>₹{formatNumberWithCommas(item?.minimumDue)}</td>
                                                            <td>₹{formatNumberWithCommas(item?.lastAmountDue)}</td>
                                                            <td><button type="button" onClick={() => editCreditHandler(item)}
                                                                className="btn btn-primary">
                                                                <FaRegEdit />
                                                            </button></td>
                                                        </tr>
                                                    )
                                                })

                                            }
                                        </>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tab-pane" id="fixeddepositsBox" role="tabpanel" aria-labelledby="fixeddeposits-tab" tabIndex="0">
                        <div className="table-responsive">
                            <table className="table-common">
                                <thead>
                                    <tr>
                                        <th>Bank Name</th>
                                        <th>Amount</th>
                                        <th>Rate of Interest <br />(per annum)</th>
                                        <th>Tenure</th>
                                        <th>Category</th>
                                        <th>Insights</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bankFixedDepositsIsLoading || bankFixedDepositsIsFetching ?
                                        <tr key="loading">
                                            <td colSpan="6" className="text-center">
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>

                                        : <>
                                            {
                                                !!bankFixedDeposits?.data?.result?.listOfItems && bankFixedDeposits?.data?.result?.listOfItems?.map((item, index) => {
                                                    return (
                                                        <tr key={index?.toString()}>
                                                            <td><span className="inter-600">{item?.institution}</span></td>
                                                            <td>₹{formatNumberWithCommas(item?.amount)}</td>
                                                            <td>{item?.interestRate}</td>
                                                            <td>{item?.tenureInMonths}</td>
                                                            <td>{item?.category}</td>
                                                            <td>{!!item?.insights ? item?.insights : 'N/A'}</td>
                                                            <td><button type="button" onClick={() => editFixedDepositsHandler(item)}
                                                                className="btn btn-primary">
                                                                <FaRegEdit />
                                                            </button></td>
                                                        </tr>
                                                    )
                                                })

                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* <nav className="py-3 py-sm-4 d-flex justify-content-center align-content-center">
                        <Pagination className="pagination" current={currentPage} align="center" total={limit * 1} pageSize={1} itemRender={itemPaginationRender} onChange={onChangePage} />
                    </nav> */}
                </div>
            </div>
        </div>
    </>)
}
export default Banking;
