import React from "react";
import Chart from 'react-apexcharts';
import useGetPlanningRetirmentTotalAsset from "../hooks/planning/get-planning-retirment-asset";
import useGetPlanningSimulatorGraph from "../hooks/planning/get-simulator-graph";
import { formatNumberWithCommas } from "../utils/common";

const SimulatorChart = ({ ...props }) => {

  // api 
  const { data: planningRetirmentTotalAssetData, isLoading: planningRetirmentTotalAssetIsLoading, isFetching: planningRetirmentTotalAssetIsFetching } = useGetPlanningRetirmentTotalAsset()
  const { data, isLoading, isFetching } = useGetPlanningSimulatorGraph({ age: props?.ageuser, simulate: true, r_age: props?.retirementage, le: props?.lifeexpectancy, incm: props?.currentincome, ivst: !!props?.currentinvestment ? props?.currentinvestment : 7200000, pre_roi: props?.preretirementroi, post_roi: props?.postretirementroi, ir: props?.inflationrate, est_re: !!props?.estretirementexpenses ? props?.estretirementexpenses : 700808 })

  const AGE_TO_SAVINGS_RATE_MAP = {
    20: 10.0,
    21: 10.7,
    22: 11.3,
    23: 12.0,
    24: 12.6,
    25: 13.3,
    26: 13.9,
    27: 14.6,
    28: 15.2,
    29: 15.9,
    30: 16.5,
    31: 17.2,
    32: 17.8,
    33: 18.5,
    34: 19.1,
    35: 19.75,
    36: 20.4,
    37: 21.1,
    38: 21.7,
    39: 22.4,
    40: 23.0,
    41: 23.7,
    42: 24.3,
    43: 25.0,
    44: 25.6,
    45: 26.3,
    46: 26.9,
    47: 27.6,
    48: 28.2,
    49: 28.9,
    50: 29.5,
    51: 30.2,
    52: 30.8,
    53: 31.5,
    54: 32.1,
    55: 32.8,
    56: 33.4,
    57: 34.1,
    58: 34.7,
    59: 35.4,
    60: 36.0,
    61: 36.7,
    62: 37.3,
    63: 38.0,
    64: 38.6,
    65: 39.3,
    66: 39.9,
  };

  const getSavingsRate = (age) => {
    if (age < 20) {
      return 10;
    } else if (age > 66) {
      return 40;
    }
    console.log(AGE_TO_SAVINGS_RATE_MAP[age]);
    return AGE_TO_SAVINGS_RATE_MAP[age];
  };

  const calculateRetirementPlan = ({
    inputAge = props?.ageuser,
    inputRetirementAge = props?.retirementage,
    inputLifeExpectancy = props?.lifeexpectancy,
    inputInflationRate = props?.inflationrate,
    inputRetirementIncomeReplacementRatio = 70,
    inputPostRetirementReturnOnCorpus = props?.postretirementroi,
    inputReturnOnInvestment = props?.preretirementroi,
    retirementAssest = !!props?.currentinvestment ? props?.currentinvestment : 7200000,
    currentIncome = props?.currentincome,
  }) => {
    let currAge = inputAge;
    let value2 = retirementAssest;
    let corpus = retirementAssest;
    const annualRetirementSavings = (currentIncome * getSavingsRate(inputAge)) / 100;
    const estimatedRetirementExpenses =
      ((inputRetirementIncomeReplacementRatio * currentIncome) / 100) *
      Math.pow(1 + inputInflationRate / 100, inputRetirementAge - inputAge);
    const dataList = [
      {
        age: currAge,
        // value1: value1,
        // value2: value2,
        corpus: corpus,
      },
    ];

    while (++currAge <= inputRetirementAge) {
      value2 =
        corpus +
        annualRetirementSavings * Math.pow(1 + inputInflationRate / 100, currAge - 1 - inputAge);
      corpus = value2 * (1 + inputReturnOnInvestment / 100);
      dataList.push({
        age: currAge,
        // value2: parseFloat(value2.toFixed(2)),
        corpus: parseFloat(corpus.toFixed(2)),
      });
    }
    currAge--;
    let postRetirementExpense = estimatedRetirementExpenses;
    while (++currAge <= inputLifeExpectancy) {
      corpus = (corpus - postRetirementExpense) * (1 + inputPostRetirementReturnOnCorpus / 100);
      dataList.push({
        age: currAge,
        // value2: parseFloat(value2.toFixed(2)),
        corpus: parseFloat(corpus.toFixed(2)),
        // postRetirementExpense: parseFloat(postRetirementExpense.toFixed(2)),
      });
      postRetirementExpense =
        estimatedRetirementExpenses *
        Math.pow(1 + inputInflationRate / 100, currAge + 1 - (inputRetirementAge + 1));
    }
    return dataList;
  };
  // const retirmentPlan = calculateRetirementPlan({});

  const state = {
    series: [
      {
        name: "Series 1",
        type: "area",
        data: data?.data?.result?.retirmentPlan?.map(x => x.corpus)
      }
    ],
    options: {
      chart: {
        height: 420,
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: 'dark',
          type: "vertical",
          shadeIntensity: 0,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.6,
          opacityTo: 0.3
        }
      },
      xaxis: {
        categories: data?.data?.result?.retirmentPlan?.map(x => x.age)
      },
 
      yaxis: {
        labels: {
            formatter: function (val) {
                // Format the value to remove decimals and add comma separators
                return formatNumberWithCommas(Math.round(val)); // Removes decimals and adds commas
            }
        }
    },
      stroke: {
        width: 2,
        curve: 'smooth'
      }
    }
  }
  return (
    <Chart options={state.options} series={state.series} height={props.height} />
  )
}

export default SimulatorChart;